import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Table, InputGroup, Card, Container, Row, Col, Alert, ButtonGroup, Badge } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import AuthService from "../../services/AuthService";
import PermissionService from "../../services/PermissionService";
import RoleService, { IPermission, IRole, IRoleType } from "../../services/RoleService";

function Roles() {
    const [tableData, setTableData] = useState({
        data: new Array<IRole>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const initFormData = {
        id: 0,
        name: "",
        permissions: new Array<IPermission>(),
        roleTypes: new Array<IRoleType>()
    } as IRole;

    const [message, setMessage] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [formData, setFormData] = useState(initFormData);
    const [permissions, setPermissions] = useState(new Array<IPermission>());
    const [roleTypes, setRoleTypes] = useState(new Array<IRoleType>());
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    });

    const keywordRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        const res = await RoleService.filter(pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }

        setCurrentPageIndex(pageIndex);
    }

    const GetPermissions = async () => {
        const res = await PermissionService.listAll();

        if (res?.isSuccess) {
            setPermissions(res.data.map((item: IPermission) => {
                return { ...item, checked: false }
            }));
        } else {
            console.log(res?.message);
        }
    }

    const GetUserTypes = async () => {
        const res = await AuthService.getAllUserTypes();

        if (res?.isSuccess) {
            setRoleTypes(res.data.map((name: string) => {
                return { name, checked: false }
            }));
        } else {
            console.log(res?.message);
        }
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);

        GetPermissions();
        GetUserTypes();
    }, []);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleAddNew = () => {
        setFormData(initFormData);

        handleShowModal();
    }

    const ClearCheckedPermissions = () => {
        setPermissions(permissions.map(p => {
            return { ...p, checked: false }
        }));
    }

    const ClearCheckedRoleTypes = () => {
        setRoleTypes(roleTypes.map(t => {
            return { ...t, checked: false }
        }));
    }

    const handleEdit = async (id: number) => {
        const res = await RoleService.get(id);

        if (res?.isSuccess) {
            setFormData({
                ...initFormData,
                id: res.data.id,
                name: res.data.name,
                permissions: res.data.permissions,
                roleTypes: res.data.roleTypes
            });

            setPermissions(permissions.map(permission => {
                let checked = (res.data.permissions as IPermission[]).some(p => { return p.id == permission.id });
                return { ...permission, checked: checked }
            }));

            setRoleTypes(roleTypes.map(type => {
                let checked = (res.data.roleTypes as IRoleType[]).some(t => { return t.name == type.name });
                return { ...type, checked: checked }
            }));

            handleShowModal();
        } else {
            console.log(res?.message);
        }
    }

    const handlePermissionsChange = (permissionId: number) => {
        setPermissions(permissions.map(permission => {
            if (permission.id == permissionId) {
                return { ...permission, checked: !permission.checked }
            } else {
                return permission;
            }
        }));
    }

    const handleRoleTypesChange = (name: string) => {
        setRoleTypes(roleTypes.map(type => {
            if (type.name == name) {
                return { ...type, checked: !type.checked }
            } else {
                return type;
            }
        }));
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            const data = {
                ...formData,
                permissions: permissions.filter(permission => {
                    return permission.checked == true;
                }),
                roleTypes: roleTypes.filter(type => {
                    return type.checked == true;
                })
            }

            if (formData.id > 0) {
                const res = await RoleService.update(formData.id, data);

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            } else {
                const res = await RoleService.create(data);

                if (res?.isSuccess) {
                    FilterFn(1);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        ClearCheckedPermissions();
        ClearCheckedRoleTypes();
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const modalTitle = formData.id > 0 ? "Edit Role" : "Add New Role";

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            name: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        const res = await RoleService.delete(deletedItem.id);

        if (res?.isSuccess) {
            FilterFn(currentPageIndex);
        }

        hideConfirmationModal();
        setMessage(res.message);
    }

    const PermissionCheckBoxes = () => {
        var list = new Array<any>();

        var arr = permissions.map((p) => {
            return p.name.split(".")[1];
        });

        var modules = arr.filter(function (item, pos) {
            return arr.indexOf(item) == pos;
        });

        modules.map(mod => {
            list.push(<div key={mod} className="font-weight-bold font-italic">{mod}</div>);

            let actions = permissions.filter(item => {
                return item.name.startsWith("Permissions." + mod + ".");
            });

            actions.forEach(permission => {
                list.push(<Form.Check
                    key={permission.id}
                    type="checkbox"
                    id={`permission-${permission.id}`}
                    label={permission.name.split(".")[2]}
                    checked={permission.checked}
                    onChange={() => handlePermissionsChange(permission.id)}
                />);
            });
        });

        return list;
    }

    const RoleTypeCheckBoxes = () => {
        var list = new Array<any>();

        roleTypes.forEach(type => {
            list.push(<Form.Check
                key={type.name}
                type="checkbox"
                id={type.name}
                label={type.name}
                checked={type.checked}
                onChange={() => handleRoleTypesChange(type.name)}
            />);
        });

        return list;
    }

    return (
        <>
            <ContentHeader title="Roles" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Button variant="info" size="sm" onClick={handleAddNew}>
                                        <i className="fas fa-plus"></i> Add new role
                                    </Button>

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Default User Types</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td>{
                                                            item.roleTypes.map((type, index) => {
                                                                return <Badge bg="light" key={index} className="mr-1">{type.name}</Badge>
                                                            })
                                                        }</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <ButtonGroup>
                                                                <Button variant="warning" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" name="name" required value={formData.name} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Please enter Name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Permissions</Form.Label>
                            <Card body style={{ maxHeight: "400px", overflow: "auto" }}>{PermissionCheckBoxes()}</Card>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Default User Types</Form.Label>
                            <Card body>{RoleTypeCheckBoxes()}</Card>
                        </Form.Group>
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Close</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />

        </>
    );
}

export default Roles;