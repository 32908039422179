import { createContext, Dispatch, useReducer } from "react";
import AppStateService, { AppStateType } from "../services/AppStateService";
import { ClientRequestsTotalActions, clientRequestsTotalReducer, CSLTActions, csltReducer } from "../store/reducers";

const initialAppState: AppStateType = AppStateService.get();

const AppContext = createContext<{
    state: AppStateType;
    dispatch: Dispatch<CSLTActions | ClientRequestsTotalActions>;
}>({
    state: initialAppState,
    dispatch: () => null
});

const appReducer = (
    { currentCSLTId, clientRequestsTotal }: AppStateType,
    action: CSLTActions | ClientRequestsTotalActions
) => ({
    currentCSLTId: csltReducer(currentCSLTId, action),
    clientRequestsTotal: clientRequestsTotalReducer(clientRequestsTotal, action)
});

function AppProvider({ children }: any) {
    const [state, dispatch] = useReducer(appReducer, initialAppState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };