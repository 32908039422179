import { ChartProps } from "./PieChart";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { BackgroundColors, BorderColors } from "../constants/ColorChart";

function LineChart(props: ChartProps) {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: props.datasets.length > 1 ? true : false,
                position: 'top' as const
            },
            title: {
                display: props.title ? true : false,
                text: props.title || ""
            }
        },
    };

    const data = {
        labels: props.labels,
        datasets: props.datasets.map(ds => {
            const backgroundColor = BackgroundColors.slice(0, ds.data.length);
            const borderColor = BorderColors.slice(0, ds.data.length);

            return { ...ds, backgroundColor: backgroundColor, borderColor: borderColor };
        }),
    };

    return (
        <Line options={options} data={data} className="chartjs-box" />
    )
}

export default LineChart;