import { Form } from "react-bootstrap";
import Utils from "../utils/Utils";

interface IDatePickerProps {
    name: string;
    value: string | null;
    required?: boolean;
    onChange: (e: any) => void;
}

function DatePicker(props: IDatePickerProps) {
    const value = Utils.dateToString(props.value, "YYYY-MM-DD");

    return (
        <Form.Control type="date" name={props.name} required={props.required} value={value} onChange={props.onChange} />
    )
}

export default DatePicker;