import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Accordion, Alert, Button, Card, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SelectInstance } from "react-select";
import AsyncSelect from "react-select/async";
import CategorySelect from "../../components/CategorySelect";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DatePicker from "../../components/DatePicker";
import Configs from "../../Configs";
import RoutePath from "../../constants/RoutePath";
import { SelectOptionType } from "../../constants/SelectOptionType";
import useAppContext from "../../hooks/useAppContext";
import TTLT_DatPhongService, { ITTLT_DatPhong, ITTLT_KhachNguoiLon, ITTLT_KhachTreEm } from "../../services/TTLT_DatPhongService";
import TTLT_DichVuService, { ITTLT_DichVu } from "../../services/TTLT_DichVuService";
import TTLT_HoaDonService, { ITTLT_HoaDon, ITTLT_TienDichVu, ITTLT_TienPhong } from "../../services/TTLT_HoaDonService";
import Utils from "../../utils/Utils";

function TraPhong() {
    const { state } = useAppContext();
    const navigate = useNavigate();
    const params = useParams();

    const initFormData: ITTLT_HoaDon = {
        id: null,
        coSoLuuTruId: state.currentCSLTId,
        datPhongId: params.id || "",
        ngayHoaDon: Utils.getCurrentDate(),
        tenKhachHang: "",
        tenDonVi: "",
        diaChi: "",
        soTaiKhoan: "",
        maSoThue: "",
        hinhThucThanhToanId: null,
        tienTeId: null,
        giamGiaPhanTram: null,
        giamGiaSoTien: null,
        ghiChu: "",
        tienTe: null,
        tienPhong: [],
        tienDichVu: [],
        tongTienPhong: null,
        tongTienDichVu: null,
        datPhong: null
    }

    const [datPhong, setDatPhong] = useState(null as ITTLT_DatPhong | null);
    const [formData, setFormData] = useState(initFormData);
    const [message, setMessage] = useState("");
    const formRef = useRef<HTMLFormElement>(null);
    const [validatedForm, setValidatedForm] = useState(false);

    const getDatPhong = async () => {
        if (state?.currentCSLTId && params.id) {
            var res = await TTLT_DatPhongService.getFullById(state.currentCSLTId, params.id);

            if (res?.isSuccess) {
                let khachDaiDien: ITTLT_KhachNguoiLon = res.data.khachNguoiLon.find((x: ITTLT_KhachNguoiLon) => x.daiDien == true);
                formData.tenKhachHang = formData.tenKhachHang || khachDaiDien?.hoVaTen || "";

                let paymentDays = TTLT_HoaDonService.getPaymentDays(res.data.thoiGianNhan, res.data.thoiGianTra);
                formData.tienPhong = [];
                paymentDays.forEach(item => {
                    formData.tienPhong.push({
                        dateKey: item.dateKey,
                        dayName: item.dayName,
                        dateName: item.dateName,
                        timePeriod: item.timePeriod,
                        soTien: res.data.giaThucTe,
                        ghiChu: ""
                    } as ITTLT_TienPhong);
                });

                const res2 = await TTLT_HoaDonService.getByDatPhongId(state.currentCSLTId, params.id);

                if (res2?.isSuccess && res2?.data) {
                    formData.id = res2.data.id;
                    formData.coSoLuuTruId = res2.data.coSoLuuTruId;
                    formData.datPhongId = res2.data.datPhongId;
                    formData.hinhThucThanhToanId = res2.data.hinhThucThanhToanId;
                    formData.tienTeId = res2.data.tienTeId;
                    formData.giamGiaPhanTram = res2.data.giamGiaPhanTram;
                    formData.giamGiaSoTien = res2.data.giamGiaSoTien;
                    formData.ghiChu = res2.data.ghiChu;
                    formData.tienTeId = res2.data.tienTeId;
                    formData.tienTe = res2.data.tienTe;

                    res2.data.tienPhong.forEach((item: ITTLT_TienPhong) => {
                        if (formData.tienPhong.find((x: ITTLT_TienPhong) => x.dateKey == item.dateKey)) {
                            formData.tienPhong = formData.tienPhong.map(x => x.dateKey == item.dateKey ? { ...x, soTien: item.soTien, ghiChu: item.ghiChu } : x);
                        }
                    });

                    formData.tienDichVu = [];
                    res2.data.tienDichVu.forEach((item: ITTLT_TienDichVu) => {
                        formData.tienDichVu.push({
                            dichVuId: item.dichVuId,
                            soLuong: item.soLuong,
                            donGia: item.donGia,
                            ghiChu: item.ghiChu,
                            dichVu: item.dichVu
                        } as ITTLT_TienDichVu);
                    });

                    setFormData(formData);
                } else {
                    setFormData(formData);
                }

                setDatPhong(res.data as ITTLT_DatPhong);
            } else {
                navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
            }
        } else {
            navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
        }
    }

    useEffect(() => {
        getDatPhong();
    }, [state.currentCSLTId]);

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        var name = e.target.name;
        var value = e.target.value;

        if (name == "hinhThucThanhToanId" || name == "giamGiaPhanTram" || name == "giamGiaSoTien") {
            setFormData({ ...formData, [name]: value ? parseInt(value) : null });
        } else if (name == "tienTeId") {
            let tienTeId = value ? parseInt(value) : 0;
            let ten = (e.target as HTMLSelectElement).selectedOptions[0].innerText;

            setFormData({ ...formData, [name]: tienTeId, tienTe: { ...formData.tienTe, id: tienTeId, ten: ten, loai: "TienTe", thuTu: null, suDung: true } });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleTableFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, dateKey: string) => {
        var name = e.target.name;
        var value = e.target.value;

        var item = formData.tienPhong.find(x => x.dateKey == dateKey);
        if (item) {
            if (name == "soTien") {
                setFormData({ ...formData, tienPhong: formData.tienPhong.map(x => x.dateKey == dateKey ? { ...x, [name]: value ? parseInt(value) : null } : x) });
            } else {
                setFormData({ ...formData, tienPhong: formData.tienPhong.map(x => x.dateKey == dateKey ? { ...x, [name]: value } : x) });
            }
        }
    }

    const handleChangePriceAllDay = (e: ChangeEvent<HTMLInputElement>) => {
        var value = e.target.value ? parseInt(e.target.value) : (datPhong?.giaThucTe || null);

        setFormData({ ...formData, tienPhong: formData.tienPhong.map(x => { return { ...x, soTien: value } }) });
    }

    const totalServiceCharge = () => formData.tienDichVu.map(x => (x.soLuong ? x.soLuong : 0) * (x.donGia ? x.donGia : 0)).reduce((accumulator, value) => {
        return accumulator + value;
    }, 0);

    const totalRoomCharge = () => formData.tienPhong.map(x => x.soTien ? x.soTien : 0).reduce((accumulator, value) => {
        return accumulator + value;
    }, 0);

    const totalRoomDiscount = () => {
        let total: number = totalRoomCharge();
        let discount: number = 0;

        if (formData.giamGiaPhanTram) {
            discount += (total * formData.giamGiaPhanTram) / 100;
        }

        if (formData.giamGiaSoTien) {
            discount += formData.giamGiaSoTien;
        }

        return discount;
    }

    const totalPaymentAmount = () => {
        let roomCharge: number = totalRoomCharge();
        let roomDiscount: number = totalRoomDiscount();
        let serviceCharge: number = totalServiceCharge();

        let total = roomCharge + serviceCharge - roomDiscount;

        return total;
    }

    const returnAmount = () => {
        if (tienKhachDua) {
            let total = totalPaymentAmount();
            return tienKhachDua - total;
        } else {
            return null;
        }
    }

    const [tienKhachDua, setTienKhachDua] = useState(null as number | null);

    const handleAmountReceivedChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setTienKhachDua(parseFloat(e.target.value));
        } else {
            setTienKhachDua(null);
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            if (formData.id) {
                const res = await TTLT_HoaDonService.update(formData.id, formData);

                if (res?.isSuccess) {
                    navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
                } else {
                    setMessage(res?.message);
                }
            } else {
                const res = await TTLT_HoaDonService.create(formData);

                if (res?.isSuccess) {
                    navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
                } else {
                    setMessage(res?.message);
                }
            }
        } else {
            setValidatedForm(true);
        }
    }

    //AsyncSelect
    const [selectedOption, setSelectedOption] = useState(null as SelectOptionType | null);
    const asyncRef = useRef<SelectInstance<SelectOptionType> | null>(null);

    const handleSelectChange = async (option: SelectOptionType | null) => {
        setSelectedOption(option);

        if (state.currentCSLTId && option?.value) {
            let value = option?.value as number;

            if (formData.tienDichVu.filter(x => x.dichVuId == value).length == 0) {
                var res = await TTLT_DichVuService.getByUsedId(state.currentCSLTId, value);

                if (res.isSuccess) {
                    let item: ITTLT_TienDichVu = {
                        dichVuId: res.data.id,
                        hoaDonId: null,
                        soLuong: 1,
                        donGia: res.data.donGia,
                        ghiChu: "",
                        dichVu: res.data
                    };

                    formData.tienDichVu.push(item);

                    setFormData(formData);

                    setSelectedOption(null);
                    asyncRef.current?.focus();
                }
            }
        }
    }

    const filterDichVu = async (inputValue: string) => {
        var options: SelectOptionType[] = [];

        if (state?.currentCSLTId) {
            var res = await TTLT_DichVuService.filter(state.currentCSLTId, inputValue);

            if (res.isSuccess && res.data) {
                options = res.data.map((item: ITTLT_DichVu) => {
                    return { value: item.id, label: item.ten }
                });
            }
        }

        return options;
    };

    const loadDichVuOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterDichVu(inputValue));
    });

    const handleTableDichVuFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, dichVuId: number | null) => {
        var name = e.target.name;
        var value = e.target.value;

        var item = formData.tienDichVu.find(x => x.dichVuId == dichVuId);
        if (item) {
            if (name == "soLuong" || name == "donGia") {
                setFormData({ ...formData, tienDichVu: formData.tienDichVu.map(x => x.dichVuId == dichVuId ? { ...x, [name]: value ? parseInt(value) : null } : x) });
            } else {
                setFormData({ ...formData, tienDichVu: formData.tienDichVu.map(x => x.dichVuId == dichVuId ? { ...x, [name]: value } : x) });
            }
        }
    }

    const handleRemoveDichVu = (dichVuId: number | null) => {
        setFormData({ ...formData, tienDichVu: formData.tienDichVu.filter(x => x.dichVuId != dichVuId) });
    }

    return (
        <>
            <ContentHeader title="Trả phòng" />
            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    {datPhong && <>
                                        <Accordion defaultActiveKey={["0"]} flush alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>THÔNG TIN ĐẶT PHÒNG</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Phòng</Form.Label>
                                                                <p>{datPhong.phong?.ten || ""}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Thời gian nhận phòng</Form.Label>
                                                                <p>{Utils.dateToString(datPhong.thoiGianNhan, Configs.DATE_TIME_FORMAT)}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Thời gian trả phòng</Form.Label>
                                                                <p>{Utils.dateToString(datPhong.thoiGianTra, Configs.DATE_TIME_FORMAT)}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Check type="checkbox" inline disabled checked={datPhong.thueTheoGio} label="Thuê theo giờ" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Giá phòng</Form.Label>
                                                                <p>{Utils.formatNumber(datPhong.giaThucTe)}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Lý do thay đổi giá</Form.Label>
                                                                <p>{datPhong.lyDoThayDoiGia}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Số giường thực tế</Form.Label>
                                                                <p>{datPhong.soGiuongThucTe || ""}</p>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={7} lg={9}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Ghi chú</Form.Label>
                                                                <p>{datPhong.ghiChu}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={5} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Mã đoàn</Form.Label>
                                                                <p>{datPhong.maDoan}</p>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            {datPhong.khachNguoiLon.length > 0 && <>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>KHÁCH NGƯỜI LỚN ({datPhong.khachNguoiLon.length})</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tên khách</th>
                                                                    <th>Ngày sinh</th>
                                                                    <th>Giới tính</th>
                                                                    <th>Quốc tịch</th>
                                                                    <th>Số giấy tờ</th>
                                                                    <th>Thời gian đến</th>
                                                                    <th>Đang ở</th>
                                                                    <th>Đại diện</th>
                                                                    <th>Nhận bảo lãnh</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    datPhong.khachNguoiLon.map((item: ITTLT_KhachNguoiLon) =>
                                                                        <tr key={item.id}>
                                                                            <td>{item.hoVaTen}</td>
                                                                            <td>{[item.ngaySinh, item.thangSinh, item.namSinh].filter(x => x != null).join("/")}</td>
                                                                            <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                                            <td>{item.quocTich?.ten}</td>
                                                                            <td>{item.loaiGiayTo?.ten}: {item.soGiayTo}</td>
                                                                            <td>{Utils.dateToString(item.thoiGianDen, "DD/MM/YYYY HH:mm")}</td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="checkbox" inline disabled checked={item.dangO} />
                                                                            </td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="checkbox" inline disabled checked={item.daiDien} />
                                                                            </td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="checkbox" inline disabled checked={item.nhanBaoLanh} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </>}

                                            {datPhong.khachTreEm.length > 0 && <>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>KHÁCH TRẺ EM ({datPhong.khachTreEm.length})</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tên trẻ em</th>
                                                                    <th>Ngày sinh</th>
                                                                    <th>Giới tính</th>
                                                                    <th>Quốc tịch</th>
                                                                    <th>Người lớn đi cùng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    datPhong.khachTreEm.map((item: ITTLT_KhachTreEm) =>
                                                                        <tr key={item.id}>
                                                                            <td>{item.hoVaTen}</td>
                                                                            <td>{Utils.dateToString(item.ngaySinh, "DD/MM/YYYY")}</td>
                                                                            <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                                            <td>{item.quocTich?.ten}</td>
                                                                            <td>{datPhong.khachNguoiLon.find(x => x.id == item.khachNguoiLonId)?.hoVaTen || ""}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </>}
                                        </Accordion>

                                        <Card>
                                            <Card.Body>
                                                <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                                    <Row>
                                                        <Col sm={12} md={7}>
                                                            <div className="mb-2" style={{ color: "var(--info)" }}>TIỀN PHÒNG</div>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control type="number" min={0} onChange={handleChangePriceAllDay} placeholder="Cập nhật giá cho tất cả các ngày" />
                                                                <InputGroup.Text><i className="fas fa-arrow-down text-muted"></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-3" controlId="soTien">
                                                                        <Form.Label>Giảm giá phòng (%)</Form.Label>
                                                                        <Form.Control type="number" min={0} max={100} name="giamGiaPhanTram" value={formData.giamGiaPhanTram || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-3" controlId="soTien">
                                                                        <Form.Label>Giảm giá phòng (Số tiền)</Form.Label>
                                                                        <Form.Control type="number" min={0} name="giamGiaSoTien" value={formData.giamGiaSoTien || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Table hover size="sm" className="table-noborder">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Ngày</th>
                                                                        <th style={{ width: "130px" }}>Số tiền</th>
                                                                        <th>Ghi chú</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {formData.tienPhong.map(item =>
                                                                        <tr key={item.dateKey}>
                                                                            <td>
                                                                                <b>{item.dayName}</b> ({item.dateName})
                                                                                <small className="text-muted d-block">{item.timePeriod}</small>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control type="number" min={0} name="soTien" value={item.soTien || ""} onChange={(e) => handleTableFormControlChange(e as any, item.dateKey)} />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control type="text" name="ghiChu" value={item.ghiChu || ""} onChange={(e) => handleTableFormControlChange(e as any, item.dateKey)} />
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>

                                                            <div className="mb-2" style={{ color: "var(--info)" }}>TIỀN DỊCH VỤ</div>
                                                            <AsyncSelect className="mb-3" isClearable value={selectedOption} loadOptions={loadDichVuOptions} onChange={handleSelectChange} ref={asyncRef} placeholder="Nhập tên dịch vụ để tìm kiếm." noOptionsMessage={({ inputValue }) => !inputValue ? "Nhập tên dịch vụ để tìm kiếm." : "Không tìm thấy dịch vụ nào!"} />

                                                            {formData.tienDichVu.length > 0 && <Table hover size="sm" className="table-noborder mb-3">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Tên dịch vụ</th>
                                                                        <th style={{ width: "80px" }}>Số lượng</th>
                                                                        <th style={{ width: "120px" }}>Đơn giá</th>
                                                                        <th>Ghi chú</th>
                                                                        <th style={{ width: "40px" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {formData.tienDichVu.map(item =>
                                                                        <tr key={item.dichVuId}>
                                                                            <td>
                                                                                <b>{item.dichVu?.ten || ""}</b>
                                                                                <small className="text-muted d-block">{item.dichVu?.donVi || ""}</small>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control type="number" min={1} required name="soLuong" value={item.soLuong || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control type="number" min={0} required name="donGia" value={item.donGia || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control type="text" name="ghiChu" value={item.ghiChu || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                                                            </td>
                                                                            <td>
                                                                                <Button variant="light" size="sm" onClick={() => handleRemoveDichVu(item.dichVuId)}><i className="fas fa-times"></i></Button>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>}
                                                        </Col>
                                                        <Col sm={12} md={5}>
                                                            <div className="mb-2" style={{ color: "var(--info)" }}>THÔNG TIN HÓA ĐƠN</div>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Form.Group className="mb-3" controlId="ngayHoaDon">
                                                                        <Form.Label>Ngày hóa đơn</Form.Label>
                                                                        <DatePicker name="ngayHoaDon" required={true} value={formData.ngayHoaDon} onChange={handleFormControlChange}></DatePicker>
                                                                        <Form.Control.Feedback type="invalid">Nhập Ngày hóa đơn.</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Form.Group className="mb-3" controlId="tenKhachHang">
                                                                        <Form.Label>Tên khách</Form.Label>
                                                                        <Form.Control type="text" name="tenKhachHang" required value={formData.tenKhachHang || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                        <Form.Control.Feedback type="invalid">Nhập Tên khách.</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Form.Group className="mb-3" controlId="tenDonVi">
                                                                        <Form.Label>Tên đơn vị</Form.Label>
                                                                        <Form.Control type="text" name="tenDonVi" value={formData.tenDonVi || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Form.Group className="mb-3" controlId="diaChi">
                                                                        <Form.Label>Địa chỉ</Form.Label>
                                                                        <Form.Control type="text" name="diaChi" value={formData.diaChi || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12} lg={6}>
                                                                    <Form.Group className="mb-3" controlId="soTaiKhoan">
                                                                        <Form.Label>Số tài khoản</Form.Label>
                                                                        <Form.Control type="text" name="soTaiKhoan" value={formData.soTaiKhoan || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={12} lg={6}>
                                                                    <Form.Group className="mb-3" controlId="maSoThue">
                                                                        <Form.Label>Mã số thuế</Form.Label>
                                                                        <Form.Control type="text" name="maSoThue" value={formData.maSoThue || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12} lg={8}>
                                                                    <CategorySelect dataType="HinhThucThanhToan" name="hinhThucThanhToanId" required={true} value={formData.hinhThucThanhToanId} onChange={handleFormControlChange}></CategorySelect>
                                                                </Col>
                                                                <Col md={12} lg={4}>
                                                                    <CategorySelect dataType="TienTe" name="tienTeId" required={true} value={formData.tienTeId} onChange={handleFormControlChange}></CategorySelect>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Form.Group className="mb-3" controlId="ghiChu">
                                                                        <Form.Label>Ghi chú</Form.Label>
                                                                        <Form.Control type="text" as="textarea" name="ghiChu" value={formData.ghiChu} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                            <Row className="text-right mb-2">
                                                                <Col sm={12} md={6}>
                                                                    <b className="text-muted">Tổng tiền phòng:</b>
                                                                </Col>
                                                                <Col sm={12} md={6}>
                                                                    <b>{Utils.formatNumber(totalRoomCharge())}</b> {formData.tienTe?.ten || ""}
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-right mb-2">
                                                                <Col sm={12} md={6}>
                                                                    <b className="text-muted">Tổng tiền dịch vụ:</b>
                                                                </Col>
                                                                <Col sm={12} md={6}>
                                                                    <b>{Utils.formatNumber(totalServiceCharge())}</b> {formData.tienTe?.ten || ""}
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-right mb-2">
                                                                <Col sm={12} md={6}>
                                                                    <b className="text-muted">Giảm giá phòng:</b>
                                                                </Col>
                                                                <Col sm={12} md={6}>
                                                                    <b>{Utils.formatNumber(totalRoomDiscount())}</b> {formData.tienTe?.ten || ""}
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-right mb-2">
                                                                <Col sm={12} md={6}>
                                                                    <b className="text-muted">Tổng cộng:</b>
                                                                </Col>
                                                                <Col sm={12} md={6}>
                                                                    <b>{Utils.formatNumber(totalPaymentAmount())}</b> {formData.tienTe?.ten || ""}
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-right mb-2">
                                                                <Col sm={12} md={6}>
                                                                    <b className="text-muted">Tiền khách đưa:</b>
                                                                </Col>
                                                                <Col sm={12} md={6}>
                                                                    <InputGroup size="sm">
                                                                        <Form.Control className="text-right" type="number" min={0} name="tienKhachDua" value={tienKhachDua || ""} onChange={(e) => handleAmountReceivedChange(e as any)} />
                                                                        <InputGroup.Text>{formData.tienTe?.ten || ""}</InputGroup.Text>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-right">
                                                                <Col sm={12} md={6}>
                                                                    <b className="text-muted">Phải trả khách:</b>
                                                                </Col>
                                                                <Col sm={12} md={6}>
                                                                    <b>{Utils.formatNumber(returnAmount())}</b> {formData.tienTe?.ten || ""}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </>}
                                    {message != "" && <Alert variant="warning" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="light" className="float-left" onClick={() => navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true })}><i className="fas fa-arrow-left"></i> Quay lại danh sách đặt phòng</Button>
                                    <Button variant="warning" className="float-right" onClick={handleSave}><i className="far fa-credit-card"></i> Thanh toán & Trả phòng</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default TraPhong;