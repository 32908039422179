import AppStateService from "../services/AppStateService";
import { ActionMap, ActionTypes } from "./actions";

// CSLT
type CSLTPayload = {
    [ActionTypes.CHANGE_CSLT]: number | null;
}

export type CSLTActions = ActionMap<CSLTPayload>[keyof ActionMap<CSLTPayload>];

export const csltReducer = (state: number | null, action: CSLTActions | ClientRequestsTotalActions) => {
    switch (action.type) {
        case ActionTypes.CHANGE_CSLT:
            var appState = AppStateService.get();
            appState.currentCSLTId = action.payload;
            AppStateService.save(appState);

            return state = action.payload;
        default:
            return state;
    }
}

// Client Requests Total
type ClientRequestsTotalPayload = {
    [ActionTypes.INCREASE_CLIENT_REQUESTS_TOTAL]: undefined;
    [ActionTypes.REDUCE_CLIENT_REQUESTS_TOTAL]: undefined;
}

export type ClientRequestsTotalActions = ActionMap<ClientRequestsTotalPayload>[keyof ActionMap<ClientRequestsTotalPayload>];

export const clientRequestsTotalReducer = (state: number, action: CSLTActions | ClientRequestsTotalActions) => {
    switch (action.type) {
        case ActionTypes.INCREASE_CLIENT_REQUESTS_TOTAL:
            return state + 1;
        case ActionTypes.REDUCE_CLIENT_REQUESTS_TOTAL:
            return state - 1;
        default:
            return state;
    }
}