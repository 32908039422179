import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import RoutePath from "../../constants/RoutePath";
import useAppContext from "../../hooks/useAppContext";
import { ITTLT_DonViKinhDoanh } from "../../services/TTLT_CoSoLuuTruService";
import TTLT_DonViKinhDoanhService from "../../services/TTLT_DonViKinhDoanhService";

function ThongTinDoanhNghiep() {
    const { state } = useAppContext();
    const navigate = useNavigate();

    const initFormData: ITTLT_DonViKinhDoanh = {
        id: 0,
        ten: "",
        nguoiDaiDien: "",
        maSoThue: "",
        diaChi: "",
        dienThoai: "",
        email: ""
    };

    const [formData, setFormData] = useState(initFormData);
    const [message, setMessage] = useState("");
    const formRef = useRef<HTMLFormElement>(null);
    const [validatedForm, setValidatedForm] = useState(false);

    useEffect(() => {
        if (state.currentCSLTId) {
            loadData(state.currentCSLTId);
        }
    }, [state.currentCSLTId]);

    const loadData = async (currentCSLTId: number) => {
        const res = await TTLT_DonViKinhDoanhService.getProfile(currentCSLTId);

        if (res?.isSuccess && res.data) {
            setFormData(res.data);
        } else {
            setFormData(initFormData);
            setMessage(res?.message);
            navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (formRef.current?.checkValidity()) {
            try {
                const res = await TTLT_DonViKinhDoanhService.updateProfile(formData.id, formData);
                setMessage(res?.message);
            } catch (error) {
                console.log(error);
            }
        } else {
            setValidatedForm(true);
        }
    }

    return (
        <>
            <ContentHeader title="Thông tin Doanh nghiệp / Hộ cá thể" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                        <Row>
                                            <Col xs={12} md={5}>
                                                <Form.Group className="mb-3" controlId="ten">
                                                    <Form.Label>Tên doanh nghiệp / hộ cá thể</Form.Label>
                                                    <Form.Control type="text" name="ten" required value={formData.ten} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Tên doanh nghiệp / hộ cá thể.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3" controlId="maSoThue">
                                                    <Form.Label>Mã số thuế</Form.Label>
                                                    <Form.Control type="text" name="maSoThue" required value={formData.maSoThue} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Mã số thuế.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="nguoiDaiDien">
                                                    <Form.Label>Người đại diện</Form.Label>
                                                    <Form.Control type="text" name="nguoiDaiDien" required value={formData.nguoiDaiDien} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Người đại diện.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={5}>
                                                <Form.Group className="mb-3" controlId="diaChi">
                                                    <Form.Label>Địa chỉ</Form.Label>
                                                    <Form.Control type="text" name="diaChi" required value={formData.diaChi} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Địa chỉ.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3" controlId="dienThoai">
                                                    <Form.Label>Điện thoại</Form.Label>
                                                    <Form.Control type="text" name="dienThoai" required value={formData.dienThoai} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Điện thoại.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" name="email" required value={formData.email} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Email.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Lưu thông tin</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default ThongTinDoanhNghiep;