import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ICategory } from "./CategoryService";

export interface ITTLT_CoSoLuuTru {
    id: number;
    ten: string;
    maSoThue: string;
    soLuongPhong: number | null;
    dienThoai: string;
    email: string;
    diaChi: string;
    tinhThanhId: number | null;
    quanHuyenId: number | null;
    phuongXaId: number | null;
    loaiHinhLuuTruId: number | null;
    hinhThucToChucId: number | null;
    loaiHinhLuuTru: ICategory | null;
    createdOn: string | null;
    dangHoatDong: boolean;
    quanLyID: number | null;
    isLockedOut: boolean;
}

export interface ITTLT_DonViKinhDoanh {
    id: number;
    ten: string;
    nguoiDaiDien: string;
    maSoThue: string;
    diaChi: string;
    dienThoai: string;
    email: string;
}

export interface ITTLT_User {
    userName: string;
    password: string;
    confirmPassword: string;
    email: string;
    fullName: string;
}

export interface ITTLT_CoSoLuuTruRegister {
    coSoLuuTru: ITTLT_CoSoLuuTru;
    donViKinhDoanh: ITTLT_DonViKinhDoanh;
    user: ITTLT_User;
}

export interface ITTLT_CoSoLuuTruUpdate {
    coSoLuuTru: ITTLT_CoSoLuuTru;
    donViKinhDoanh: ITTLT_DonViKinhDoanh;
}

export default class TTLT_CoSoLuuTruService {
    static async register(coSoLuuTruData: ITTLT_CoSoLuuTruRegister) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/CoSoLuuTru/Register", coSoLuuTruData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getByUser() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/CoSoLuuTru/GetByUser");

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getProfile(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CoSoLuuTru/GetProfile/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async updateProfile(id: number, itemData: ITTLT_CoSoLuuTru) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/CoSoLuuTru/UpdateProfile/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async search(keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CoSoLuuTru/Search?keyword=${keyword}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CoSoLuuTru?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CoSoLuuTru/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/CoSoLuuTru/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, itemData: ITTLT_CoSoLuuTruUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/CoSoLuuTru/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeIsActivedItem(id: number, dangHoatDong: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/CoSoLuuTru/ChangeIsActivedItem`, { id, dangHoatDong });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async lockOrUnlockManager(userId: number, isLockedOut: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/CoSoLuuTru/lockOrUnlockManager`, { userId, isLockedOut });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}