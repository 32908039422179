import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Table, InputGroup, Card, Container, Row, Col, Alert, ButtonGroup } from "react-bootstrap";
import AdministrativeDivisionSelect from "../../components/AdministrativeDivisionSelect";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import BorderGateService, { IBorderGate } from "../../services/BorderGateService";

function BorderGates() {
    const [tableData, setTableData] = useState({
        data: new Array<IBorderGate>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const initFormData = {
        id: 0,
        ten: "",
        cap: "",
        loai: "",
        tinhThanhId: null,
        quanHuyenId: null,
        phuongXaId: null,
        suDung: false
    } as IBorderGate;

    const [message, setMessage] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [formData, setFormData] = useState(initFormData);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });

    const keywordRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        const res = await BorderGateService.filter(pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, []);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (["tinhThanhId", "quanHuyenId", "phuongXaId"].includes(e.target.name)) {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }

    const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    }

    const handleAddNew = () => {
        setFormData(initFormData);

        handleShowModal();
    }

    const handleEdit = async (id: number) => {
        const res = await BorderGateService.get(id);

        if (res?.isSuccess) {
            setFormData({
                ...initFormData,
                id: res.data.id,
                ten: res.data.ten,
                cap: res.data.cap,
                loai: res.data.loai,
                tinhThanhId: res.data.tinhThanhId,
                quanHuyenId: res.data.quanHuyenId,
                phuongXaId: res.data.phuongXaId,
                suDung: res.data.suDung
            });

            handleShowModal();
        } else {
            console.log(res?.message);
        }
    }

    const handleSaveBorderGate = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            if (formData.id > 0) {
                const res = await BorderGateService.update(formData.id, formData);

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            } else {
                const res = await BorderGateService.create(formData);

                if (res?.isSuccess) {
                    FilterFn(1);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const modalTitle = (formData.id > 0 ? "Sửa" : "Thêm mới") + " Cửa khẩu";

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        const res = await BorderGateService.delete(deletedItem.id);

        if (res?.isSuccess) {
            FilterFn(currentPageIndex);
        }

        hideConfirmationModal();
        setMessage(res.message);
    }

    const handleSuDungSwitch = async (id: number, suDung: boolean) => {
        const res = await BorderGateService.changeIsUsedItem(id, suDung);

        if (res?.isSuccess) {
            FilterFn(currentPageIndex);
        } else {
            console.log(res?.message);
        }
    }

    return (
        <>
            <ContentHeader title="Cửa khẩu" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Button variant="info" size="sm" onClick={handleAddNew}>
                                        <i className="fas fa-plus"></i> Thêm mới
                                    </Button>

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Tên cửa khẩu</th>
                                                <th>Cấp</th>
                                                <th>Loại</th>
                                                <th>Tỉnh, Thành</th>
                                                <th>Huyện, Quận</th>
                                                <th>Phường, Xã</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Sử dụng</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.ten}</td>
                                                        <td>{item.cap}</td>
                                                        <td>{item.loai}</td>
                                                        <td>{item?.tinhThanh?.ten}</td>
                                                        <td>{item?.quanHuyen?.ten}</td>
                                                        <td>{item?.phuongXa?.ten}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" checked={item.suDung} onChange={(e) => handleSuDungSwitch(item.id, e.target.checked)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <ButtonGroup>
                                                                <Button variant="warning" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.ten)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="ten">
                            <Form.Label>Tên cửa khẩu</Form.Label>
                            <Form.Control type="text" name="ten" required value={formData.ten} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Tên cửa khẩu.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="cap">
                            <Form.Label>Cấp</Form.Label>
                            <Form.Control type="text" name="cap" value={formData.cap} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="loai">
                            <Form.Label>Loại</Form.Label>
                            <Form.Control type="text" name="loai" value={formData.loai} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <AdministrativeDivisionSelect tinhThanhId={formData.tinhThanhId} quanHuyenId={formData.quanHuyenId} phuongXaId={formData.phuongXaId} handleSelectOptionChange={(e) => handleFormControlChange(e as any)}></AdministrativeDivisionSelect>
                        <Form.Group className="mb-3" controlId="suDung">
                            <Form.Check type="checkbox" label="Sử dụng" name="suDung" checked={formData.suDung} onChange={(e) => handleCheckBoxChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSaveBorderGate}><i className="fas fa-save"></i> Lưu</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

        </>
    );
}

export default BorderGates;