import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface ICategory {
    id: number;
    ten: string;
    loai: string;
    thuTu: number | null;
    suDung: boolean;
}

export default class CategoryService {
    static async listAll(loai: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DanhMuc/${loai}/All`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filter(loai: string, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DanhMuc/${loai}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(loai: string, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DanhMuc/${loai}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(loai: string, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/DanhMuc/${loai}/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(loai: string, id: number, itemData: ICategory) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DanhMuc/${loai}/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(loai: string, itemData: ICategory) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/DanhMuc/${loai}/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeIsUsedItem(loai: string, id: number, suDung: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DanhMuc/${loai}/ChangeIsUsedItem`, { id, suDung });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}