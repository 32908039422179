import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface ITTLT_DoiTuongNguyHiem {
    id: number | null;
    ten: string;
    thongTin: string;
    anh: string;
}

export default class TTLT_DoiTuongNguyHiemService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DoiTuongNguyHiem?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DoiTuongNguyHiem/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/DoiTuongNguyHiem/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DoiTuongNguyHiem/${id}`, formData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/DoiTuongNguyHiem/Create`, formData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}