import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row, Tab, Table } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import useAppContext from "../../hooks/useAppContext";
import CSLT_ReportService, { ITTLT_DoanhThuTheoDichVu, ITTLT_DoanhThuTheoPhong } from "../../services/CSLT_ReportService";
import Utils from "../../utils/Utils";

function BaoCaoDoanhThu() {
    const { state } = useAppContext();

    const [doanhThuTheoPhongData, setDoanhThuTheoPhongData] = useState([] as Array<ITTLT_DoanhThuTheoPhong>);
    const [doanhThuTheoDichVuData, setDoanhThuTheoDichVuData] = useState([] as Array<ITTLT_DoanhThuTheoDichVu>);

    const month1Ref = useRef<HTMLInputElement>(null);
    const month2Ref = useRef<HTMLInputElement>(null);

    const getDoanhThuTheoPhong = async () => {
        if (state.currentCSLTId && month1Ref.current?.value) {
            let time = month1Ref.current?.value.split("-");
            let year = parseInt(time[0].trim());
            let month = parseInt(time[1].trim());

            const res = await CSLT_ReportService.getDoanhThuTheoPhong(state.currentCSLTId, year, month);

            if (res?.isSuccess) {
                setDoanhThuTheoPhongData(res.data);
            } else {
                console.log(res?.message);
            }
        }
    }

    const getDoanhThuTheoDichVu = async () => {
        if (state.currentCSLTId && month2Ref.current?.value) {
            let time = month2Ref.current?.value.split("-");
            let year = parseInt(time[0].trim());
            let month = parseInt(time[1].trim());

            const res = await CSLT_ReportService.getDoanhThuTheoDichVu(state.currentCSLTId, year, month);

            if (res?.isSuccess) {
                setDoanhThuTheoDichVuData(res.data);
            } else {
                console.log(res?.message);
            }
        }
    }

    useEffect(() => {
        getDoanhThuTheoPhong();
        getDoanhThuTheoDichVu();
    }, [state.currentCSLTId]);

    const handleReportDoanhThuTheoPhong = () => {
        getDoanhThuTheoPhong();
    }

    const handleReportDoanhThuTheoDichVu = () => {
        getDoanhThuTheoDichVu();
    }

    return (
        <>
            <ContentHeader title="Báo cáo doanh thu" />
            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    <Tab.Container defaultActiveKey="tab1">
                                        <Row>
                                            <Col sm={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="tab1"><i className="fas fa-table"></i> Doanh thu theo phòng</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="tab2"><i className="fas fa-table"></i> Doanh thu theo dịch vụ</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col sm={9}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="tab1">
                                                        <Card>
                                                            <Card.Header>
                                                                <Row className="d-flex align-items-end">
                                                                    <Col lg={4} md={6} sm={12}>
                                                                        <Form.Label>Chọn tháng</Form.Label>
                                                                        <Form.Control type="month" defaultValue={Utils.getCurrentMonth()} ref={month1Ref} />
                                                                    </Col>
                                                                    <Col lg={8} md={6} sm={12}>
                                                                        <Button variant="info" className="mt-3" onClick={handleReportDoanhThuTheoPhong}><i className="fas fa-search"></i> Xem báo cáo</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Table bordered hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>STT</th>
                                                                            <th>Phòng</th>
                                                                            <th style={{ textAlign: "right" }}>Tiền phòng</th>
                                                                            <th style={{ textAlign: "right" }}>Tiền dịch vụ</th>
                                                                            <th style={{ textAlign: "right" }}>Tộng cộng</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            doanhThuTheoPhongData.map((item: ITTLT_DoanhThuTheoPhong, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{item.tenPhong}</td>
                                                                                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.tongTienPhong)}</td>
                                                                                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.tongTienDichVu)}</td>
                                                                                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.tongTienPhong + item.tongTienDichVu)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <th style={{ textAlign: "right" }} colSpan={2}>Tổng cộng</th>
                                                                            <th style={{ textAlign: "right" }}>{
                                                                                Utils.formatNumber(doanhThuTheoPhongData.reduce((total, item) => {
                                                                                    return total + item.tongTienPhong
                                                                                }, 0))
                                                                            }</th>
                                                                            <th style={{ textAlign: "right" }}>{
                                                                                Utils.formatNumber(doanhThuTheoPhongData.reduce((total, item) => {
                                                                                    return total + item.tongTienDichVu
                                                                                }, 0))
                                                                            }</th>
                                                                            <th style={{ textAlign: "right" }}>{
                                                                                Utils.formatNumber(doanhThuTheoPhongData.reduce((total, item) => {
                                                                                    return total + item.tongTienPhong + item.tongTienDichVu
                                                                                }, 0))
                                                                            }</th>
                                                                        </tr>
                                                                    </tfoot>
                                                                </Table>
                                                            </Card.Body>
                                                        </Card>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="tab2">
                                                        <Card>
                                                            <Card.Header>
                                                                <Row className="d-flex align-items-end">
                                                                    <Col lg={4} md={6} sm={12}>
                                                                        <Form.Label>Chọn tháng</Form.Label>
                                                                        <Form.Control type="month" defaultValue={Utils.getCurrentMonth()} ref={month2Ref} />
                                                                    </Col>
                                                                    <Col lg={8} md={6} sm={12}>
                                                                        <Button variant="info" className="mt-3" onClick={handleReportDoanhThuTheoDichVu}><i className="fas fa-search"></i> Xem báo cáo</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Table bordered hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>STT</th>
                                                                            <th>Tên dịch vụ</th>
                                                                            <th>Đơn vị</th>
                                                                            <th style={{ textAlign: "right" }}>Số lượng</th>
                                                                            <th style={{ textAlign: "right" }}>Đơn giá</th>
                                                                            <th style={{ textAlign: "right" }}>Thành tiền</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            doanhThuTheoDichVuData.map((item: ITTLT_DoanhThuTheoDichVu, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{item.tenDichVu}</td>
                                                                                        <td>{item.donVi}</td>
                                                                                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.soLuong)}</td>
                                                                                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.donGia)}</td>
                                                                                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.soLuong * item.donGia)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <th style={{ textAlign: "right" }} colSpan={5}>Tổng cộng</th>
                                                                            <th style={{ textAlign: "right" }}>{
                                                                                Utils.formatNumber(doanhThuTheoDichVuData.reduce((total, item) => {
                                                                                    return total + item.soLuong * item.donGia
                                                                                }, 0))
                                                                            }</th>
                                                                        </tr>
                                                                    </tfoot>
                                                                </Table>
                                                            </Card.Body>
                                                        </Card>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default BaoCaoDoanhThu;