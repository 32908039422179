const RoutePath = {
    HOME: "/home",
    PAGE404: "/page-404",
    AUTH: {
        LOGIN: "/login",
        REGISTER: "/register",
        FORGOT_PASSWORD: "/forgot-password",
        RECOVER_PASSWORD: "/recover-password",
        PROFILE: "/profile",
        CHANGE_PASSWORD: "/change-password",
        UNAUTHORIZED: "/unauthorized"
    },
    ADMIN: {
        HOME: "/admin/home",
        USERS: "/admin/users",
        ROLES: "/admin/roles",
        CATEGORY: "/admin/category/",
        ADMINISTRATIVE_DIVISIONS: "/admin/administrative-divisions",
        BORDER_GATES: "/admin/border-gates",
    },
    CSLT: {
        DANG_KY: "/cslt/dang-ky",
        DANG_KY_THANH_CONG: "/cslt/dang-ky-thanh-cong",
        THONG_TIN_DOANH_NGHIEP: "/cslt/thong-tin-doanh-nghiep",
        THONG_TIN_CSLT: "/cslt/thong-tin-cslt",
        TANG: "/cslt/tang",
        LOAI_PHONG: "/cslt/loai-phong",
        PHONG: "/cslt/phong",
        DICH_VU: "/cslt/dich-vu",
        DAT_PHONG: "/cslt/dat-phong",
        TRA_PHONG: "/cslt/tra-phong",
        CHUYEN_PHONG: "/cslt/chuyen-phong",
        DANH_SACH_DAT_PHONG: "/cslt/danh-sach-dat-phong",
        SO_DO_PHONG: "/cslt/so-do-phong",
        MESSAGE_INBOX: "/cslt/message-inbox",
        NGUOI_DUNG: "/cslt/nguoi-dung",
        BAO_CAO_DOANH_THU: "/cslt/bao-cao-doanh-thu",
        THONG_KE_KHACH_LUU_TRU: "/cslt/thong-ke-khach-luu-tru",
        DANH_SACH_HOA_DON: "/cslt/danh-sach-hoa-don",
        HOA_DON: "/cslt/hoa-don",
        DANH_SACH_DOI_TUONG_NGUY_HIEM: "/cslt/danh-sach-doi-tuong-nguy-hiem",
        DANG_KY_LUU_TRU: "/cslt/dang-ky-luu-tru",
        DANH_SACH_DANG_KY_LUU_TRU: "/cslt/danh-sach-dang-ky-luu-tru",
    },
    CA: {
        MESSAGE_OUTBOX: "/ca/message-outbox",
        DANH_SACH_CO_SO_LUU_TRU: "/ca/danh-sach-co-so-luu-tru",
        CO_SO_LUU_TRU: "/ca/co-so-luu-tru",
        KHACH_LUU_TRU: "/ca/khach-luu-tru",
        DOI_TUONG_NGUY_HIEM: "/ca/doi-tuong-nguy-hiem",
        BAO_CAO: "/ca/bao-cao",
        TONG_QUAN: "/ca/tong-quan",
    },
    UBND: {
        
    },
    THUE: {
        
    }
}

export default RoutePath;