import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AdministrativeDivisionSelect from "../../components/AdministrativeDivisionSelect";
import CategorySelect from "../../components/CategorySelect";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import RoutePath from "../../constants/RoutePath";
import TTLT_CoSoLuuTruService, { ITTLT_CoSoLuuTruUpdate } from "../../services/TTLT_CoSoLuuTruService";

function CoSoLuuTru() {
    const navigate = useNavigate();
    const params = useParams();

    const initFormData: ITTLT_CoSoLuuTruUpdate = {
        coSoLuuTru: {
            id: 0,
            ten: "",
            maSoThue: "",
            soLuongPhong: null,
            dienThoai: "",
            email: "",
            diaChi: "",
            tinhThanhId: null,
            quanHuyenId: null,
            phuongXaId: null,
            loaiHinhLuuTruId: null,
            hinhThucToChucId: null,
            loaiHinhLuuTru: null,
            createdOn: null,
            dangHoatDong: false,
            quanLyID: null,
            isLockedOut: false
        },
        donViKinhDoanh: {
            id: 0,
            ten: "",
            nguoiDaiDien: "",
            maSoThue: "",
            diaChi: "",
            dienThoai: "",
            email: ""
        }
    };

    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState(initFormData);

    const formRef = useRef<HTMLFormElement>(null);
    const [validatedForm, setValidatedForm] = useState(false);

    const getCoSoLuuTru = async () => {
        if (params.id) {
            const res = await TTLT_CoSoLuuTruService.get(parseInt(params.id));

            if (res?.isSuccess) {
                setFormData({ ...initFormData, coSoLuuTru: res.data, donViKinhDoanh: res.data.donViKinhDoanh });
            } else {
                navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
            }
        }
    }

    useEffect(() => {
        getCoSoLuuTru();
    }, []);

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        var name = e.target.name;
        var value = e.target.value;

        if (name.startsWith("DVKD_")) {
            setFormData({ ...formData, donViKinhDoanh: { ...formData.donViKinhDoanh, [name.replace("DVKD_", "")]: value } });
        }

        if (name.startsWith("CSLT_")) {
            if (name == "CSLT_loaiHinhLuuTruId" || name == "CSLT_hinhThucToChucId" || name == "CSLT_soLuongPhong") {
                setFormData({ ...formData, coSoLuuTru: { ...formData.coSoLuuTru, [name.replace("CSLT_", "")]: value ? parseInt(value) : null } });
            } else {
                setFormData({ ...formData, coSoLuuTru: { ...formData.coSoLuuTru, [name.replace("CSLT_", "")]: value } });
            }
        }

        if (name == "tinhThanhId" || name == "quanHuyenId" || name == "phuongXaId") {
            setFormData({ ...formData, coSoLuuTru: { ...formData.coSoLuuTru, [name]: value ? parseInt(value) : null } });
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (formRef.current?.checkValidity()) {
            try {
                const res = await TTLT_CoSoLuuTruService.update(formData.coSoLuuTru.id, formData);

                if (res?.isSuccess) {
                    navigate(RoutePath.CA.DANH_SACH_CO_SO_LUU_TRU, { replace: true });
                } else {
                    setMessage(res?.message);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setValidatedForm(true);
        }
    }

    return (
        <>
            <ContentHeader title="Cơ sở lưu trú" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                        <Card>
                                            <Card.Header><h5 className="text-uppercase">Thông tin Doanh nghiệp / Hộ cá thể</h5></Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} md={5}>
                                                        <Form.Group className="mb-3" controlId="DVKD_ten">
                                                            <Form.Label>Tên doanh nghiệp / hộ cá thể</Form.Label>
                                                            <Form.Control type="text" name="DVKD_ten" required value={formData.donViKinhDoanh.ten} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Tên doanh nghiệp / hộ cá thể.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group className="mb-3" controlId="DVKD_maSoThue">
                                                            <Form.Label>Mã số thuế</Form.Label>
                                                            <Form.Control type="text" name="DVKD_maSoThue" required value={formData.donViKinhDoanh.maSoThue} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Mã số thuế.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group className="mb-3" controlId="DVKD_nguoiDaiDien">
                                                            <Form.Label>Người đại diện</Form.Label>
                                                            <Form.Control type="text" name="DVKD_nguoiDaiDien" required value={formData.donViKinhDoanh.nguoiDaiDien} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Người đại diện.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={5}>
                                                        <Form.Group className="mb-3" controlId="DVKD_diaChi">
                                                            <Form.Label>Địa chỉ</Form.Label>
                                                            <Form.Control type="text" name="DVKD_diaChi" required value={formData.donViKinhDoanh.diaChi} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Địa chỉ.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group className="mb-3" controlId="DVKD_dienThoai">
                                                            <Form.Label>Điện thoại</Form.Label>
                                                            <Form.Control type="text" name="DVKD_dienThoai" required value={formData.donViKinhDoanh.dienThoai} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Điện thoại.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group className="mb-3" controlId="DVKD_email">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" name="DVKD_email" required value={formData.donViKinhDoanh.email} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Email.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <Card>
                                            <Card.Header><h5 className="text-uppercase">Thông tin Cơ sở lưu trú</h5></Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} md={5}>
                                                        <Form.Group className="mb-3" controlId="CSLT_ten">
                                                            <Form.Label>Tên cơ sở lưu trú</Form.Label>
                                                            <Form.Control type="text" name="CSLT_ten" required value={formData.coSoLuuTru.ten} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Tên cơ sở lưu trú.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group className="mb-3" controlId="CSLT_maSoThue">
                                                            <Form.Label>Mã số thuế</Form.Label>
                                                            <Form.Control type="text" name="CSLT_maSoThue" required value={formData.coSoLuuTru.maSoThue} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Mã số thuế.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group className="mb-3" controlId="CSLT_soLuongPhong">
                                                            <Form.Label>Số lượng phòng</Form.Label>
                                                            <Form.Control type="number" min={0} name="CSLT_soLuongPhong" required value={formData.coSoLuuTru.soLuongPhong || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Số lượng phòng.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <CategorySelect dataType="LoaiHinhLuuTru" name="CSLT_loaiHinhLuuTruId" required={true} value={formData.coSoLuuTru.loaiHinhLuuTruId} onChange={handleFormControlChange}></CategorySelect>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <CategorySelect dataType="HinhThucToChuc" name="CSLT_hinhThucToChucId" required={true} value={formData.coSoLuuTru.hinhThucToChucId} onChange={handleFormControlChange}></CategorySelect>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group className="mb-3" controlId="CSLT_dienThoai">
                                                            <Form.Label>Điện thoại</Form.Label>
                                                            <Form.Control type="text" name="CSLT_dienThoai" required value={formData.coSoLuuTru.dienThoai} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Điện thoại.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group className="mb-3" controlId="CSLT_email">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" name="CSLT_email" required value={formData.coSoLuuTru.email} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Email.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={8}>
                                                        <Form.Group className="mb-3" controlId="CSLT_diaChi">
                                                            <Form.Label>Địa chỉ</Form.Label>
                                                            <Form.Control type="text" name="CSLT_diaChi" required value={formData.coSoLuuTru.diaChi} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Địa chỉ.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <AdministrativeDivisionSelect tinhThanhId={formData.coSoLuuTru.tinhThanhId} quanHuyenId={formData.coSoLuuTru.quanHuyenId} phuongXaId={formData.coSoLuuTru.phuongXaId} handleSelectOptionChange={(e) => handleFormControlChange(e as any)} column={true} required={true}></AdministrativeDivisionSelect>
                                            </Card.Body>
                                        </Card>
                                    </Form>

                                    {message != "" && <Alert variant="danger" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="light" className="float-left" onClick={() => navigate(RoutePath.CA.DANH_SACH_CO_SO_LUU_TRU, { replace: true })}><i className="fas fa-arrow-left"></i> Quay lại danh sách cở sở lưu trú</Button>
                                    <Button variant="info" className="float-right" onClick={handleSave}><i className="fas fa-calendar-check"></i> Lưu thông tin</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default CoSoLuuTru;