import { addMonths } from "date-fns/esm";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
// import CalendarPicker from "../../components/CalendarPicker";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DateRangePicker from "../../components/DateRangePicker";
import CA_ReportService, { ITTLT_CoSoLuuTruTheoLoaiHinh, ITTLT_KhachDangKyTheoCoSoLuuTru, ITTLT_KhachDangKyTheoNgay, ITTLT_PhanLoaiKhachTheoQuocTich } from "../../services/CA_ReportService";
import PieChart, { DatasetChart } from "../../components/PieChart";
import BarChart from "../../components/BarChart";
import LineChart from "../../components/LineChart";

function TongQuan() {
    // const [reportDate, setReportDate] = useState(new Date());
    const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
    const [endDate, setEndDate] = useState(new Date());

    const [coSoLuuTruTheoLoaiHinhData, setCoSoLuuTruTheoLoaiHinhData] = useState({
        title: "Cơ sở lưu trú theo loại hình",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    });

    const [khachDangKyTheoCoSoLuuTruData, setKhachDangKyTheoCoSoLuuTruData] = useState({
        title: "Khách đăng ký theo cơ sở lưu trú",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    });

    const [phanLoaiKhachTheoQuocTichData, setPhanLoaiKhachTheoQuocTichData] = useState({
        title: "Phân loại khách theo quốc tịch",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    });

    const [khachDangKyTheoNgayData, setKhachDangKyTheoNgayData] = useState({
        title: "Khách đăng ký theo ngày",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    });

    useEffect(() => {
        GetCoSoLuuTruTheoLoaiHinh();
    }, []);

    useEffect(() => {
        GetKhachDangKyTheoCoSoLuuTru(startDate, endDate);
        GetPhanLoaiKhachTheoQuocTich(startDate, endDate);
        GetKhachDangKyTheoNgay(startDate, endDate);
    }, [startDate, endDate]);

    const GetCoSoLuuTruTheoLoaiHinh = async () => {
        const res = await CA_ReportService.getCoSoLuuTruTheoLoaiHinh();

        if (res?.isSuccess) {
            const data = res.data as ITTLT_CoSoLuuTruTheoLoaiHinh[];

            const labels = [] as string[];
            const dataset = {
                label: "Số lượng",
                data: []
            } as DatasetChart;

            data.forEach(item => {
                labels.push(item.tenLoaiHinhLuuTru);
                dataset.data.push(item.soLuong);
            });

            setCoSoLuuTruTheoLoaiHinhData({ ...coSoLuuTruTheoLoaiHinhData, labels: labels, datasets: [dataset] });
        } else {
            // navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
            console.log(res.message);
        }
    }

    const GetKhachDangKyTheoCoSoLuuTru = async (start: Date, end: Date) => {
        const res = await CA_ReportService.getKhachDangKyTheoCoSoLuuTru(start, end);

        if (res?.isSuccess) {
            const data = res.data as ITTLT_KhachDangKyTheoCoSoLuuTru[];

            const labels = [] as string[];
            const dataset = {
                label: "Lượt khách",
                data: []
            } as DatasetChart;

            data.forEach(item => {
                labels.push(item.tenCoSoLuuTru);
                dataset.data.push(item.soLuong);
            });

            setKhachDangKyTheoCoSoLuuTruData({ ...khachDangKyTheoCoSoLuuTruData, labels: labels, datasets: [dataset] });
        } else {
            // navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
            console.log(res.message);
        }
    }

    const GetPhanLoaiKhachTheoQuocTich = async (start: Date, end: Date) => {
        const res = await CA_ReportService.getPhanLoaiKhachTheoQuocTich(start, end);

        if (res?.isSuccess) {
            const data = res.data as ITTLT_PhanLoaiKhachTheoQuocTich[];

            const labels = [] as string[];
            const dataset = {
                label: "Lượt khách",
                data: []
            } as DatasetChart;

            data.forEach(item => {
                labels.push(item.tenQuocTich);
                dataset.data.push(item.soLuong);
            });

            setPhanLoaiKhachTheoQuocTichData({ ...phanLoaiKhachTheoQuocTichData, labels: labels, datasets: [dataset] });
        } else {
            // navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
            console.log(res.message);
        }
    }

    const GetKhachDangKyTheoNgay = async (start: Date, end: Date) => {
        const res = await CA_ReportService.getKhachDangKyTheoNgay(start, end);

        if (res?.isSuccess) {
            const data = res.data as ITTLT_KhachDangKyTheoNgay[];

            const labels = [] as string[];
            const dataset = {
                label: "Lượt khách",
                data: []
            } as DatasetChart;

            data.forEach(item => {
                labels.push(item.ngay);
                dataset.data.push(item.soLuong);
            });

            setKhachDangKyTheoNgayData({ ...khachDangKyTheoNgayData, labels: labels, datasets: [dataset] });
        } else {
            // navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
            console.log(res.message);
        }
    }

    const handleChangeDate = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setStartDate(startDate);
            setEndDate(endDate);
        }
    }

    // const handleSelectDate = (date: Date) => {
    //     setReportDate(date);
    // }

    return (
        <>
            <ContentHeader title="Tổng quan" />
            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {/* <CalendarPicker selectedDate={reportDate} handleSelect={handleSelectDate} closeOnSelected={true}></CalendarPicker> */}
                                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleChangeDate} closeOnSelected={true}></DateRangePicker>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <BarChart title={coSoLuuTruTheoLoaiHinhData.title} labels={coSoLuuTruTheoLoaiHinhData.labels} datasets={coSoLuuTruTheoLoaiHinhData.datasets}></BarChart>
                                        </Col>
                                        <Col lg={6}>
                                            <BarChart title={khachDangKyTheoCoSoLuuTruData.title} labels={khachDangKyTheoCoSoLuuTruData.labels} datasets={khachDangKyTheoCoSoLuuTruData.datasets}></BarChart>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <PieChart title={phanLoaiKhachTheoQuocTichData.title} labels={phanLoaiKhachTheoQuocTichData.labels} datasets={phanLoaiKhachTheoQuocTichData.datasets}></PieChart>
                                        </Col>
                                        <Col lg={8}>
                                            <LineChart title={khachDangKyTheoNgayData.title} labels={khachDangKyTheoNgayData.labels} datasets={khachDangKyTheoNgayData.datasets}></LineChart>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default TongQuan;