import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Accordion, Alert, Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import Configs from "../../Configs";
import RoutePath from "../../constants/RoutePath";
import useAppContext from "../../hooks/useAppContext";
import TTLT_DatPhongService, { CheckinStatus, ITTLT_DatPhong, ITTLT_KhachNguoiLon, ITTLT_KhachTreEm } from "../../services/TTLT_DatPhongService";
import TTLT_PhongService, { ITTLT_Phong } from "../../services/TTLT_PhongService";
import Utils from "../../utils/Utils";

function ChuyenPhong() {
    const { state } = useAppContext();
    const navigate = useNavigate();
    const params = useParams();

    const [datPhong, setDatPhong] = useState(null as ITTLT_DatPhong | null);
    const [formData, setFormData] = useState(null as ITTLT_DatPhong | null);
    const [message, setMessage] = useState("");
    const formRef = useRef<HTMLFormElement>(null);
    const [validatedForm, setValidatedForm] = useState(false);
    const [phongData, setPhongData] = useState([] as ITTLT_Phong[]);

    const getPhong = async () => {
        if (state?.currentCSLTId) {
            const res = await TTLT_PhongService.listAll(state.currentCSLTId);

            if (res?.isSuccess && res.data) {
                setPhongData(res.data);
            } else {
                setPhongData([]);
            }
        } else {
            setPhongData([]);
        }
    }

    const getDatPhong = async () => {
        if (state?.currentCSLTId && params.id) {
            var res = await TTLT_DatPhongService.getFullById(state.currentCSLTId, params.id);

            if (res?.isSuccess) {
                setDatPhong(res.data);
                setFormData({
                    ...res.data,
                    phongId: null,
                    giaThucTe: null,
                    thoiGianNhan: Utils.getCurrentDate(),
                    ghiChu: "",
                    lyDoThayDoiGia: "",
                    soGiuongThucTe: null,
                    trangThai: CheckinStatus.CheckedIn.value,
                    khachNguoiLon: res.data.khachNguoiLon.map((x: ITTLT_KhachNguoiLon) => { return { ...x, chuyen: false, thoiGianDen: Utils.getCurrentDate(), thoiGianDi: null } }),
                    khachTreEm: res.data.khachTreEm.map((x: ITTLT_KhachTreEm) => { return { ...x, chuyen: false } }),
                    huyPhongCu: false
                });
            } else {
                navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
            }
        } else {
            navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
        }
    }

    useEffect(() => {
        getPhong();

        getDatPhong();
    }, [state.currentCSLTId]);

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        var name = e.target.name;
        var value = e.target.value;

        if (formData && name.startsWith("DP_")) {
            var key = name.replace("DP_", "");
            if (key == "phongId") {
                var phongId = value ? parseInt(value) : null;
                var giaDangKy: number | null = null;

                if (phongId) {
                    giaDangKy = phongData.find(x => x.id == parseInt(value))?.loaiPhong?.giaDangKy || null;
                }

                setFormData({ ...formData, phongId: phongId, giaThucTe: giaDangKy || null });
            } else if (key == "soGiuongThucTe") {
                setFormData({ ...formData, [key]: value ? parseInt(value) : null });
            } else if (key == "trangThai") {
                setFormData({ ...formData, [key]: value ? parseInt(value) : 1 });
            } else if (key == "giaThucTe") {
                setFormData({ ...formData, [key]: value ? parseFloat(value) : null });
            } else if (key == "thoiGianNhan") {
                setFormData({ ...formData, [key]: value, khachNguoiLon: formData.khachNguoiLon.map(x => { return { ...x, thoiGianDen: value } }) });
            } else if (key == "thoiGianTra") {
                setFormData({ ...formData, [key]: value, khachNguoiLon: formData.khachNguoiLon.map(x => { return { ...x, thoiGianDi: value } }) });
            } else {
                setFormData({ ...formData, [key]: value });
            }
        }
    }

    const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        var name = e.target.name;
        var checked = e.target.checked;

        if (formData && name.startsWith("DP_")) {
            var key = name.replace("DP_", "");
            setFormData({ ...formData, [key]: checked });
        }
    }

    const handleMoveSwitch = (type: string, id: string | null, chuyen: boolean) => {
        if (id && formData) {
            if (type == "NL") {
                let item = formData.khachNguoiLon.find(x => x.id == id);
                if (item) {
                    setFormData({ ...formData, khachNguoiLon: formData.khachNguoiLon.map(x => x.id == id ? { ...x, chuyen: chuyen } : x) });
                }
            }

            if (type == "TE") {
                let item = formData.khachTreEm.find(x => x.id == id);
                if (item) {
                    setFormData({ ...formData, khachTreEm: formData.khachTreEm.map(x => x.id == id ? { ...x, chuyen: chuyen } : x) });
                }
            }
        }
    }

    const handleMoveAllSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        var checked = e.target.checked;
        if (formData) {
            setFormData({
                ...formData,
                huyPhongCu: checked ? checked : formData.huyPhongCu,
                khachNguoiLon: formData.khachNguoiLon.map((x: ITTLT_KhachNguoiLon) => { return { ...x, chuyen: checked } }),
                khachTreEm: formData.khachTreEm.map((x: ITTLT_KhachTreEm) => { return { ...x, chuyen: checked } })
            });
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        setMessage("");
        if (formRef.current?.checkValidity()) {
            if (formData) {
                let message = checkValidFormData();

                if (message == "") {
                    const res = await TTLT_DatPhongService.move(formData);

                    if (res?.isSuccess) {
                        navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
                    } else {
                        setMessage(res?.message);
                    }
                } else {
                    setMessage(message);
                }
            }
        } else {
            setValidatedForm(true);
        }
    }

    const checkValidFormData = () => {
        if (formData && datPhong) {
            if (!Utils.isValidDateRange(formData.thoiGianNhan, formData.thoiGianTra)) {
                return "Thời gian nhận và trả phòng không hợp lệ.";
            }

            if (formData.phongId == datPhong.phongId) {
                return "Bạn phải chọn một phòng khác.";
            }

            if (formData.khachNguoiLon.filter(x => x.chuyen == true).length == 0) {
                return "Bạn phải chọn ít nhất một khách người lớn để chuyển.";
            }

            if (formData.khachNguoiLon.filter(x => x.chuyen == false).length == 0 && formData.khachTreEm.filter(x => x.chuyen == false).length > 0) {
                return "Bạn phải chuyển hết trẻ em.";
            }
        }

        return "";
    }

    return (
        <>
            <ContentHeader title="Chuyển phòng" />
            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    {datPhong && formData && <>
                                        <Accordion defaultActiveKey={["0", "1", "2", "3"]} flush alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>THÔNG TIN ĐẶT PHÒNG HIỆN TẠI</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Phòng</Form.Label>
                                                                <p>{datPhong.phong?.ten || ""}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Thời gian nhận phòng</Form.Label>
                                                                <p>{Utils.dateToString(datPhong.thoiGianNhan, Configs.DATE_TIME_FORMAT)}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Thời gian trả phòng</Form.Label>
                                                                <p>{Utils.dateToString(datPhong.thoiGianTra, Configs.DATE_TIME_FORMAT)}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Check type="checkbox" inline disabled checked={datPhong.thueTheoGio} label="Thuê theo giờ" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Giá phòng</Form.Label>
                                                                <p>{Utils.formatNumber(datPhong.giaThucTe)}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Lý do thay đổi giá</Form.Label>
                                                                <p>{datPhong.lyDoThayDoiGia}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Số giường thực tế</Form.Label>
                                                                <p>{datPhong.soGiuongThucTe || ""}</p>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={7} lg={9}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Ghi chú</Form.Label>
                                                                <p>{datPhong.ghiChu}</p>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={12} md={5} lg={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Mã đoàn</Form.Label>
                                                                <p>{datPhong.maDoan}</p>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>THÔNG TIN ĐẶT PHÒNG MỚI</Accordion.Header>
                                                <Accordion.Body>
                                                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                                        <Row>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_phongId">
                                                                    <Form.Label>Phòng</Form.Label>
                                                                    <Form.Select name="DP_phongId" required value={formData.phongId || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                                                        <option value=""></option>
                                                                        {phongData.map(item => {
                                                                            return <option value={item.id} key={item.id}>{item.ten}</option>
                                                                        })}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">Chọn Phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_thoiGianNhan">
                                                                    <Form.Label>Thời gian nhận phòng</Form.Label>
                                                                    <Form.Control type="datetime-local" name="DP_thoiGianNhan" required value={Utils.dateToString(formData.thoiGianNhan, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Thời gian nhận phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_thoiGianTra">
                                                                    <Form.Label>Thời gian trả phòng</Form.Label>
                                                                    <Form.Control type="datetime-local" name="DP_thoiGianTra" required value={Utils.dateToString(formData.thoiGianTra, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Thời gian trả phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3} className="text-center">
                                                                <Form.Group className="mb-3" id="DP_thueTheoGio">
                                                                    <Form.Label>Thuê theo giờ</Form.Label>
                                                                    <Form.Switch className="py-2" name="DP_thueTheoGio" checked={formData.thueTheoGio} onChange={(e) => handleCheckBoxChange(e as any)}></Form.Switch>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={3}>
                                                                <Form.Group className="mb-3" controlId="DP_giaThucTe">
                                                                    <Form.Label>Giá phòng</Form.Label>
                                                                    <Form.Control type="number" min={0} name="DP_giaThucTe" required value={formData.giaThucTe || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Giá phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3" controlId="DP_lyDoThayDoiGia">
                                                                    <Form.Label>Lý do thay đổi giá</Form.Label>
                                                                    <Form.Control type="text" name="DP_lyDoThayDoiGia" value={formData.lyDoThayDoiGia} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={3}>
                                                                <Form.Group className="mb-3" controlId="DP_soGiuongThucTe">
                                                                    <Form.Label>Số giường thực tế</Form.Label>
                                                                    <Form.Control type="number" min={1} name="DP_soGiuongThucTe" value={formData.soGiuongThucTe || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={12} lg={6}>
                                                                <Form.Group className="mb-3" controlId="DP_ghiChu">
                                                                    <Form.Label>Ghi chú</Form.Label>
                                                                    <Form.Control type="text" as="textarea" name="DP_ghiChu" value={formData.ghiChu} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_maDoan">
                                                                    <Form.Label>Mã đoàn</Form.Label>
                                                                    <Form.Control type="text" name="DP_maDoan" value={formData.maDoan} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Text>Sử dụng để checkin/checkout theo đoàn.</Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_trangThai">
                                                                    <Form.Label>Tình trạng</Form.Label>
                                                                    <Form.Select name="DP_trangThai" required value={formData.trangThai || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                                                        {Object.entries(CheckinStatus).map(([key, value]) => <option value={value.value} key={key}>{value.name}</option>)}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">Chọn Tình trạng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={12} lg={6}>
                                                                <Form.Switch label="Hủy phòng cũ" className="mb-3" id="DP_huyPhongCu" name="DP_huyPhongCu" checked={formData.huyPhongCu} onChange={(e) => handleCheckBoxChange(e as any)}></Form.Switch>
                                                                <Form.Text>Trả lại phòng cũ và không tính tiền phòng.</Form.Text>
                                                            </Col>
                                                            <Col sm={12} md={12} lg={6}>
                                                                <Form.Switch label="Chuyển tất cả khách" className="mb-3" id="moveAll" name="moveAll" onClick={(e) => handleMoveAllSwitch(e as any)}></Form.Switch>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            {formData.khachNguoiLon.length > 0 && <>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>KHÁCH NGƯỜI LỚN ({formData.khachNguoiLon.length})</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tên khách</th>
                                                                    <th>Ngày sinh</th>
                                                                    <th>Giới tính</th>
                                                                    <th>Quốc tịch</th>
                                                                    <th>Số giấy tờ</th>
                                                                    <th>Thời gian đến</th>
                                                                    <th>Đang ở</th>
                                                                    <th>Đại diện</th>
                                                                    <th>Nhận bảo lãnh</th>
                                                                    <th style={{ maxWidth: "80px" }}>Chuyển phòng mới</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    formData.khachNguoiLon.map((item: ITTLT_KhachNguoiLon) =>
                                                                        <tr key={item.id}>
                                                                            <td>{item.hoVaTen}</td>
                                                                            <td>{[item.ngaySinh, item.thangSinh, item.namSinh].filter(x => x != null).join("/")}</td>
                                                                            <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                                            <td>{item.quocTich?.ten}</td>
                                                                            <td>{item.loaiGiayTo?.ten}: {item.soGiayTo}</td>
                                                                            <td>{Utils.dateToString(item.thoiGianDen, "DD/MM/YYYY HH:mm")}</td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="checkbox" inline disabled checked={item.dangO} />
                                                                            </td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="checkbox" inline disabled checked={item.daiDien} />
                                                                            </td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="checkbox" inline disabled checked={item.nhanBaoLanh} />
                                                                            </td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="switch" checked={item.chuyen} onChange={(e) => handleMoveSwitch("NL", item.id, e.target.checked)} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </>}

                                            {formData.khachTreEm.length > 0 && <>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>KHÁCH TRẺ EM ({formData.khachTreEm.length})</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tên trẻ em</th>
                                                                    <th>Ngày sinh</th>
                                                                    <th>Giới tính</th>
                                                                    <th>Quốc tịch</th>
                                                                    <th>Người lớn đi cùng</th>
                                                                    <th style={{ maxWidth: "80px" }}>Chuyển phòng mới</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    formData.khachTreEm.map((item: ITTLT_KhachTreEm) =>
                                                                        <tr key={item.id}>
                                                                            <td>{item.hoVaTen}</td>
                                                                            <td>{Utils.dateToString(item.ngaySinh, "DD/MM/YYYY")}</td>
                                                                            <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                                            <td>{item.quocTich?.ten}</td>
                                                                            <td>{formData.khachNguoiLon.find(x => x.id == item.khachNguoiLonId)?.hoVaTen || ""}</td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Form.Check type="switch" checked={item.chuyen} onChange={(e) => handleMoveSwitch("TE", item.id, e.target.checked)} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </>}
                                        </Accordion>
                                    </>}
                                    {message != "" && <Alert variant="warning" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="light" className="float-left" onClick={() => navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true })}><i className="fas fa-arrow-left"></i> Quay lại danh sách đặt phòng</Button>
                                    <Button variant="warning" className="float-right" onClick={handleSave}><i className="fas fa-exchange-alt"></i> Chuyển phòng</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default ChuyenPhong;