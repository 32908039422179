import { SyntheticEvent, useEffect, useRef, useState } from "react";
import AccountService from "../services/AccountService";

function Profile() {
    const [formData, setFormData] = useState({
        email: "",
        fullname: ""
    });

    const [avatar, setAvatar] = useState("");
    const [message, setMessage] = useState("");

    const fullnameRef = useRef<HTMLInputElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        fullnameRef.current?.focus();
        loadProfile();
    }, []);

    useEffect(() => {
        setMessage('My profile');
    }, [formData]);

    const loadProfile = async () => {
        const res = await AccountService.getProfile();
        if (res?.isSuccess) {
            setFormData({
                email: res.data.email || "",
                fullname: res.data.fullName || "",
            });
            setAvatar(res.data.avatar || "");
        } else {
            setMessage(res?.message);
        }
    }

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            const res = await AccountService.updateProfile(formData.email, formData.fullname);

            if (res?.isSuccess) {
                setMessage(res.message);
            } else {
                setMessage(res?.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleImageChange = async (e: SyntheticEvent) => {
        if (fileRef.current?.files && fileRef.current.files[0]) {
            // var reader = new FileReader();

            // reader.onload = function () {
            //     setAvatar(reader.result?.toString() || "");

            //     console.log(reader.result?.toString());
            // }

            // reader.readAsDataURL(fileRef.current.files[0]);

            const formData = new FormData();
            formData.append("file", fileRef.current.files[0]);

            const res = await AccountService.updateAvatar(formData);

            if (res?.isSuccess) {
                setAvatar(res.data);
            } else {
                setMessage(res?.message);
            }
        }
    }

    return (
        <>
            <div className="avatar-wrapper">
                <img className="profile-pic" src={avatar} alt={formData.fullname} />
                <div className="upload-button" onClick={() => { fileRef.current?.click(); }}>
                    <i className="fas fa-cloud-upload-alt" aria-hidden="true"></i>
                </div>
                <input type="file" className="file-upload" accept="image/*" onChange={handleImageChange} ref={fileRef} />
            </div>
            <div className="register-box">
                <div className="card">
                    <div className="card-body register-card-body">
                        <p className="login-box-msg">{message}</p>
                        <form className="mb-4" onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Full name" autoComplete="off" ref={fullnameRef} value={formData.fullname} onChange={(e) => setFormData({ ...formData, fullname: e.target.value })} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="Email" autoComplete="off" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary btn-block">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile