import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface IAdministrativeDivisionUpdate {
    ten: string;
    ma: string;
    suDung: boolean;
    parentId: number | null;
}

export interface IAdministrativeDivision extends IAdministrativeDivisionUpdate {
    id: number;
    children: Array<IAdministrativeDivision> | null;
}

export default class AdministrativeDivisionService {
    static async getChildren(parentId: number | null) {
        try {
            let url = "/api/DonViHanhChinh/GetChildren";
            if (parentId != null) {
                url = `${url}?parentId=${parentId}`;
            }

            const { data } = await axiosClient.get<ApiResponse>(url);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getRoot(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DonViHanhChinh/GetRoot/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filter(parentId: number | null, pageIndex: number, pageSize: number, keyword: string) {
        try {
            let url = "/api/DonViHanhChinh";
            if (parentId != null) {
                url = `${url}?parentId=${parentId}&keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
            } else {
                url = `${url}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
            }

            const { data } = await axiosClient.get<ApiResponse>(url);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DonViHanhChinh/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/DonViHanhChinh/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, itemData: IAdministrativeDivisionUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DonViHanhChinh/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(itemData: IAdministrativeDivisionUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/DonViHanhChinh/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeIsUsedItem(id: number, suDung: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DonViHanhChinh/ChangeIsUsedItem`, { id, suDung });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}