import { Badge } from "react-bootstrap";
import { ApprovalStatus } from "../constants/ApprovalStatus";
import Utils from "./Utils";

class UIUtils {
    static getApprovalBadge(id: number | null, isApprover: boolean) {
        switch (id) {
            case ApprovalStatus.NONE:
                return <Badge bg="warning" text="dark">{Utils.getApprovalName(id, isApprover)}</Badge>;
            case ApprovalStatus.APPROVED:
                return <Badge bg="success">{Utils.getApprovalName(id, isApprover)}</Badge>;
            case ApprovalStatus.REJECTED:
                return <Badge bg="danger">{Utils.getApprovalName(id, isApprover)}</Badge>;
            default:
                return <Badge bg="secondary">{Utils.getApprovalName(id, isApprover)}</Badge>;
        }
    }
}

export default UIUtils;