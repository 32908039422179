import { Link } from "react-router-dom";
import RoutePath from "../constants/RoutePath";
import { UserTypes } from "../constants/UserTypes";
import useAuthContext from "../hooks/useAuthContext";
import logo from "../logo.png";

function Logo({ className = "navbar-brand" }) {
    const { user } = useAuthContext();

    const homeUrl = Object.values(UserTypes).includes(user?.userInfo.type || "") && user?.userInfo.type != UserTypes.GUEST ? RoutePath.ADMIN.HOME : RoutePath.HOME;

    return (
        <Link to={homeUrl} className={className} replace={true}>
            <img src={logo} alt="Auth" className="brand-image img-circle" style={{ opacity: '.8' }} />
            <span className="brand-text">H-RMS</span>
        </Link>
    )
}

export default Logo;