import { AppProvider } from './context/AppContext ';
import AppRoutes from './routes/AppRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    // <ErrorBoundary>
    <AppProvider>
      <AppRoutes />
    </AppProvider>
    // </ErrorBoundary>
  );
}

export default App;
