import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AccountService from "../services/AccountService";
import RoutePath from "../constants/RoutePath";

function ForgotPassword() {
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");

    const emailRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        emailRef.current?.focus();
    }, []);

    useEffect(() => {
        setMessage('You forgot your password? Here you can easily retrieve a new password.');
    }, [email]);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            const res = await AccountService.forgotPassword(email);

            if (res?.isSuccess) {
                setMessage(res.message);
            } else {
                setMessage(res?.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="login-box">
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">{message}</p>
                    <form className="mb-4" onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <input type="email" className="form-control" autoComplete="off" placeholder="Email" ref={emailRef} value={email} onChange={(e) => setEmail(e.target.value)} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block">Request new password</button>
                            </div>
                        </div>
                    </form>
                    <p className="mt-3 mb-1">
                        <Link to={RoutePath.AUTH.LOGIN}>Login</Link>
                    </p>
                    <p className="mb-0">
                        <Link to={RoutePath.AUTH.REGISTER} className="text-center">Register a new membership</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;