import { IAdministrativeDivision } from "./AdministrativeDivisionService";
import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ICategory } from "./CategoryService";
import { ITTLT_CoSoLuuTru } from "./TTLT_CoSoLuuTruService";
import { ITTLT_Phong } from "./TTLT_PhongService";

export interface ITTLT_DangKyLuuTru {
    id: number | null;
    khachId: string;
    soGiayTo: string;
    loaiGiayToId: number | null;
    hoVaTen: string;
    ngaySinh: number | null;
    thangSinh: number | null;
    namSinh: number | null;
    gioiTinh: number | null;
    quocTichId: number | null;
    diaChi: string;
    tinhThanhId: number | null;
    quanHuyenId: number | null;
    phuongXaId: number | null;
    thoiGianDen: string | null;
    thoiGianDi: string | null;
    lyDoLuuTruId: number | null;
    phongId: number | null;
    coSoLuuTruId: number | null;
    trangThai: number | null;
    ghiChu: string;

    loaiGiayTo: ICategory | null;
    quocTich: ICategory | null;
    lyDoLuuTru: ICategory | null;
    tinhThanh: IAdministrativeDivision | null;
    quanHuyen: IAdministrativeDivision | null;
    phuongXa: IAdministrativeDivision | null;
    phong: ITTLT_Phong | null;
    coSoLuuTru: ITTLT_CoSoLuuTru | null;

    chon: boolean;
}

export interface ITTLT_DuyetDangKyLuuTru {
    id: number;
    trangThai: number;
    ghiChu: string;
}

export default class TTLT_DangKyLuuTruService {

    static async filter(coSoLuuTruId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DangKyLuuTru/${coSoLuuTruId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DangKyLuuTru/${coSoLuuTruId}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getByDatPhongId(coSoLuuTruId: number, datPhongId: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DangKyLuuTru/GetByDatPhongId/${coSoLuuTruId}/${datPhongId}`);
            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/DangKyLuuTru/${coSoLuuTruId}/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, itemData: ITTLT_DangKyLuuTru) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DangKyLuuTru/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(coSoLuuTruId: number, itemData: Array<ITTLT_DangKyLuuTru>) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/DangKyLuuTru/Create/${coSoLuuTruId}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filterForApproval(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DangKyLuuTru/ListForApproval?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getForApproval(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DangKyLuuTru/GetByIdForApproval/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async approval(id: number, itemData: ITTLT_DuyetDangKyLuuTru) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DangKyLuuTru/Approval/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}