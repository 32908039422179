import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ICategory } from "./CategoryService";
import { ITTLT_Phong } from "./TTLT_PhongService";

export const CheckinStatus = {
    CheckedIn: {
        value: 1,
        name: "Nhận phòng"
    },
    None: {
        value: 2,
        name: "Chưa đến"
    },
    CheckedOut: {
        value: 3,
        name: "Đã trả phòng"
    }
};

export interface ITTLT_DatPhong {
    id: string | null;
    phongId: number | null;
    thoiGianNhan: string | null;
    thoiGianTra: string | null;
    giaThucTe: number | null;
    lyDoThayDoiGia: string;
    soGiuongThucTe: number | null;
    soKhachNguoiLon: number | null;
    soKhachTreEm: number | null;
    ghiChu: string;
    maDoan: string;
    thueTheoGio: boolean;
    khachNguoiLon: Array<ITTLT_KhachNguoiLon>;
    khachTreEm: Array<ITTLT_KhachTreEm>;
    coSoLuuTruId: number;
    phong: ITTLT_Phong | null;
    trangThai: number | null;
    huyPhongCu: boolean;
}

export interface ITTLT_KhachNguoiLon {
    id: string | null;
    loaiKhachId: number | null;
    soGiayTo: string;
    loaiGiayToId: number | null;
    hoVaTen: string;
    ngaySinh: number | null;
    thangSinh: number | null;
    namSinh: number | null;
    gioiTinh: number | null;
    quocTichId: number | null;
    dienThoai: string;
    email: string | null;
    diaChi: string;
    tinhThanhId: number | null;
    quanHuyenId: number | null;
    phuongXaId: number | null;
    thoiGianDen: string | null;
    thoiGianDi: string | null;
    lyDoLuuTruId: number | null;
    dangO: boolean;
    daiDien: boolean;
    nhanBaoLanh: boolean;
    quocTich: ICategory | null;
    loaiGiayTo: ICategory | null;
    lyDoLuuTru: ICategory | null;
    chuyen: boolean;
}

export interface ITTLT_KhachTreEm {
    id: string | null;
    loaiKhachId: number | null;
    hoVaTen: string;
    ngaySinh: string | null;
    gioiTinh: number | null;
    quocTichId: number | null;
    khachNguoiLonId: string | null;
    quocTich: ICategory | null;
    chuyen: boolean;
}

export default class TTLT_DatPhongService {

    static async filter(coSoLuuTruId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DatPhong/${coSoLuuTruId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(coSoLuuTruId: number, id: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DatPhong/${coSoLuuTruId}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getFullById(coSoLuuTruId: number, id: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DatPhong/GetFullById/${coSoLuuTruId}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(coSoLuuTruId: number, id: string) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/DatPhong/${coSoLuuTruId}/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: string, itemData: ITTLT_DatPhong) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DatPhong/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(itemData: ITTLT_DatPhong) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/DatPhong/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async move(itemData: ITTLT_DatPhong) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/DatPhong/Move`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeCheckedOut(coSoLuuTruId: number, id: string, daTraPhong: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DatPhong/ChangeCheckedOut/${coSoLuuTruId}`, { id, daTraPhong });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}