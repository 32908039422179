type Props = {
    children?: JSX.Element | JSX.Element[] | string
};

function ContentBody({ children }: Props) {
    return (
        <section className="content">
            {children}
        </section>
    )
}

export default ContentBody;