import { KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Offcanvas, Row, Table } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import useAppContext from "../../hooks/useAppContext";
import TTLT_ThongBaoService, { ITTLT_ThongBao } from "../../services/TTLT_ThongBaoService";
import Utils from "../../utils/Utils";

function MessageInbox() {
    const { state } = useAppContext();

    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_ThongBao>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const keywordRef = useRef<HTMLInputElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        if (state.currentCSLTId) {
            const res = await TTLT_ThongBaoService.filterReceivedMessages(state.currentCSLTId, pageIndex, pageSize, keyword);

            if (res?.isSuccess) {
                setTableData(res.data);
            } else {
                console.log(res?.message);
            }
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, [state.currentCSLTId]);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    // View Message
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    }
    const handleShowOffcanvas = () => {
        setShowOffcanvas(true);
    }

    const [thongBao, setThongBao] = useState({} as ITTLT_ThongBao);

    const handleView = async (id: number | null, e: SyntheticEvent) => {
        e.preventDefault();

        if (state.currentCSLTId && id) {
            var res = await TTLT_ThongBaoService.getReceivedMessageById(state.currentCSLTId, id);

            if (res.isSuccess) {
                setThongBao(res.data);
                FilterFn(currentPageIndex);

                handleShowOffcanvas();
            } else {
                console.log(res.message);
            }
        }
    }

    return (
        <>
            <ContentHeader title="Thông báo" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}></th>
                                                <th>Tiêu đề</th>
                                                <th>Nơi gửi</th>
                                                <th style={{ textAlign: "center", width: "150px" }}>Loại thông báo</th>
                                                <th style={{ textAlign: "center", width: "140px" }}>Thời gian gửi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id} onClick={(e) => handleView(item.id, e)}>
                                                        <td>{item.noiNhan.length > 0 ? item.noiNhan.map((nn, index) =>
                                                            nn.daDoc ? <i className="fas fa-envelope-open text-muted" key={index}></i> : <i className="fas fa-envelope text-warning" key={index}></i>)
                                                            : <i className="fas fa-envelope text-warning"></i>}
                                                        </td>
                                                        <td><a className="d-block">{item.tieuDe}</a></td>
                                                        <td>{TTLT_ThongBaoService.getTenNoiGui(item.noiGui)}</td>
                                                        <td>{item.thongBaoChung ? "Thông báo chung" : ""}</td>
                                                        <td>{Utils.dateToString(item.createdOn, Configs.DATE_TIME_FORMAT)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{thongBao.tieuDe}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p><i>{Utils.dateToString(thongBao.createdOn, Configs.DATE_TIME_FORMAT)}</i></p>
                    {Utils.splitNL2Array(thongBao.noiDung).map((str, index) => <p key={index}>{str}</p>)}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default MessageInbox;