import { Link } from "react-router-dom";
import RoutePath from "../constants/RoutePath";

export function Footer() {
    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                Version 1.0.2
            </div>
            Copyright &copy; 2022 <Link to={RoutePath.HOME}>H-RMS</Link>. All rights reserved.
        </footer>
    )
}