import { SyntheticEvent, useEffect, useRef, useState } from "react";
import AccountService from "../services/AccountService";

function ChangePassword() {
    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });

    const [message, setMessage] = useState("");

    const currentPasswordRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        currentPasswordRef.current?.focus();
    }, []);

    useEffect(() => {
        setMessage('Change password');
    }, [formData]);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            const res = await AccountService.changePassword(formData.currentPassword, formData.newPassword, formData.confirmNewPassword);

            if (res?.isSuccess) {
                setFormData({
                    currentPassword: "",
                    newPassword: "",
                    confirmNewPassword: ""
                });

                setMessage(res.message);
            } else {
                setMessage(res?.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="login-box">
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">{message}</p>
                    <form className="mt-4" onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Current password" autoComplete="off" ref={currentPasswordRef} value={formData.currentPassword} onChange={(e) => { setFormData({ ...formData, currentPassword: e.target.value }) }} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="New password" autoComplete="off" value={formData.newPassword} onChange={(e) => { setFormData({ ...formData, newPassword: e.target.value }) }} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Confirm new password" autoComplete="off" value={formData.confirmNewPassword} onChange={(e) => { setFormData({ ...formData, confirmNewPassword: e.target.value }) }} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block">Change password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;