import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Modal, Offcanvas, Row, Table } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import TTLT_DoiTuongNguyHiemService, { ITTLT_DoiTuongNguyHiem } from "../../services/TTLT_DoiTuongNguyHiemService";
import Utils from "../../utils/Utils";

function DanhSachDoiTuongNguyHiem() {
    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_DoiTuongNguyHiem>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const keywordRef = useRef<HTMLInputElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        const res = await TTLT_DoiTuongNguyHiemService.filter(pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, []);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    // View Message
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    }
    const handleShowOffcanvas = () => {
        setShowOffcanvas(true);
    }

    const [doiTuongNguyHiem, setDoiTuongNguyHiem] = useState({} as ITTLT_DoiTuongNguyHiem);

    const handleView = async (id: number | null, e: SyntheticEvent) => {
        e.preventDefault();

        if (id) {
            var res = await TTLT_DoiTuongNguyHiemService.get(id);

            if (res.isSuccess) {
                setDoiTuongNguyHiem(res.data);

                handleShowOffcanvas();
            } else {
                console.log(res.message);
            }
        }
    }

    return (
        <>
            <ContentHeader title="Đối tượng nguy hiểm" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "200px" }}>Ảnh</th>
                                                <th>Tên đối tượng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id} onClick={(e) => handleView(item.id, e)}>
                                                        <td>{item.anh && <img src={`${Configs.API_BASE_URL}${item.anh}`} style={{ width: "100%" }} />}</td>
                                                        <td>{item.ten}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{doiTuongNguyHiem.ten}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {doiTuongNguyHiem.anh && <img src={`${Configs.API_BASE_URL}${doiTuongNguyHiem.anh}`} style={{ maxWidth: "100%", margin: "auto" }} className="d-block mb-3" />}
                    {Utils.splitNL2Array(doiTuongNguyHiem.thongTin).map((str, index) => <p key={index}>{str}</p>)}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default DanhSachDoiTuongNguyHiem;