import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { CategoryTypes } from "../constants/CategoryTypes";
import CategoryService, { ICategory } from "../services/CategoryService";

interface ICategorySelectProps {
    dataType: string,
    name: string;
    value: string | number | null;
    required?: boolean;
    onChange: (e: any) => void;
}

function CategorySelect(props: ICategorySelectProps) {
    const [categoryOptions, setCategoryOptions] = useState([] as any[]);
    const [categoryName, setCategoryName] = useState("");

    const CategoryOptions = async () => {
        const res = await CategoryService.listAll(props.dataType);
        let options: any[] = [];

        if (res?.isSuccess) {
            options = res.data.map((item: ICategory) => <option key={item.id} value={item.id}>{item.ten}</option>);
        } else {
            console.log(res?.message);
        }

        return options;
    }

    useEffect(() => {
        CategoryOptions().then(options => {
            setCategoryOptions(options);
        });

        setCategoryName(CategoryTypes.find(x => x.type == props.dataType)?.descriptions || "");
    }, []);

    return (
        <Form.Group className="mb-3" controlId={props.name}>
            <Form.Label>{categoryName}</Form.Label>
            <Form.Select name={props.name} required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {categoryOptions}
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Chọn một {categoryName}.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default CategorySelect;