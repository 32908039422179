import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ITTLT_DonViKinhDoanh } from "./TTLT_CoSoLuuTruService";

export default class TTLT_DonViKinhDoanhService {
    static async getProfile(coSoLuuTruId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/DonViKinhDoanh/GetProfile/${coSoLuuTruId}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async updateProfile(id: number, itemData: ITTLT_DonViKinhDoanh) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/DonViKinhDoanh/UpdateProfile/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}