import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AdministrativeDivisionSelect from "../../components/AdministrativeDivisionSelect";
import CategorySelect from "../../components/CategorySelect";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DateInput from "../../components/DateInput";
import GenderSelect from "../../components/GenderSelect";
import Configs from "../../Configs";
import { ApprovalStatus } from "../../constants/ApprovalStatus";
import RoutePath from "../../constants/RoutePath";
import useAppContext from "../../hooks/useAppContext";
import { IAdministrativeDivision } from "../../services/AdministrativeDivisionService";
import { ICategory } from "../../services/CategoryService";
import TTLT_DangKyLuuTruService, { ITTLT_DangKyLuuTru } from "../../services/TTLT_DangKyLuuTruService";
import UIUtils from "../../utils/UIUtils";
import Utils from "../../utils/Utils";

function DangKyLuuTru() {
    const { state } = useAppContext();
    const params = useParams();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState(new Array<ITTLT_DangKyLuuTru>());
    const [message, setMessage] = useState("");

    const loadThongTinKhach = async () => {
        if (state.currentCSLTId && params.id) {
            const res = await TTLT_DangKyLuuTruService.getByDatPhongId(state.currentCSLTId, params.id);

            if (res?.isSuccess) {
                setTableData(res.data);
            } else {
                navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
            }
        } else {
            navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
        }
    }

    useEffect(() => {
        loadThongTinKhach();
    }, [state.currentCSLTId]);

    const validateRegItems = (regItems: Array<ITTLT_DangKyLuuTru>) => {
        var keys = ["khachId", "soGiayTo", "loaiGiayToId", "hoVaTen", "namSinh", "gioiTinh", "quocTichId", "diaChi", "tinhThanhId", "quanHuyenId", "phuongXaId", "thoiGianDen", "thoiGianDi", "lyDoLuuTruId", "phongId", "coSoLuuTruId"];

        var errMsgs = new Array<string>();
        regItems.forEach(regItem => {
            var invalidKeys = [];

            for (const [key, value] of Object.entries(regItem)) {
                if (keys.includes(key) && !value) {
                    invalidKeys.push(key);
                }
            }

            if (invalidKeys.length) {
                errMsgs.push(`Bạn phải cập nhật các thông tin sau cho "${regItem.hoVaTen}": ${invalidKeys.join("; ")}`);
            } else {
                if (!Utils.isValidDateRange(regItem.thoiGianDen, regItem.thoiGianDi)) {
                    errMsgs.push(`Thời gian đến và đi của "${regItem.hoVaTen}" không hợp lệ`);
                }
            }
        });

        return errMsgs.join('. ');
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (state.currentCSLTId) {
            var regItems = tableData.filter(x => x.chon == true);

            var errs = validateRegItems(regItems);
            if (!errs) {
                const res = await TTLT_DangKyLuuTruService.create(state.currentCSLTId, regItems);

                if (res?.isSuccess) {
                    navigate(RoutePath.CSLT.DANH_SACH_DANG_KY_LUU_TRU, { replace: true });
                } else {
                    setMessage(res?.message);
                }
            } else {
                setMessage(errs);
            }
        }
    }

    const formRef = useRef<HTMLFormElement>(null);
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [formData, setFormData] = useState({} as ITTLT_DangKyLuuTru);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const handleEdit = (khachId: string) => {
        var item = tableData.find(x => x.khachId == khachId);
        if (item) {
            setFormData(item);
            handleShowModal();
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        var key = e.target.name;
        var value = e.target.value;

        if (key == "gioiTinh" || key == "ngaySinh" || key == "thangSinh" || key == "namSinh") {
            setFormData({ ...formData, [key]: value ? parseInt(value) : null });
        } else {
            setFormData({ ...formData, [key]: value });
        }
    }

    const handleFormSelectControlChange = (e: ChangeEvent<HTMLSelectElement>) => {
        var key = e.target.name;
        var value = e.target.value ? parseInt(e.target.value) : null;
        var text = e.target.options[e.target.selectedIndex].text;

        if (key == "quocTichId") {
            setFormData({ ...formData, [key]: value, quocTich: { ...formData.quocTich, id: value, ten: text } as ICategory });
        } else if (key == "lyDoLuuTruId") {
            setFormData({ ...formData, [key]: value, lyDoLuuTru: { ...formData.lyDoLuuTru, id: value, ten: text } as ICategory });
        } else if (key == "loaiGiayToId") {
            setFormData({ ...formData, [key]: value, loaiGiayTo: { ...formData.loaiGiayTo, id: value, ten: text } as ICategory });
        } else if (key == "tinhThanhId") {
            setFormData({ ...formData, [key]: value, tinhThanh: { ...formData.tinhThanh, id: value, ten: text } as IAdministrativeDivision });
        } else if (key == "quanHuyenId") {
            setFormData({ ...formData, [key]: value, quanHuyen: { ...formData.quanHuyen, id: value, ten: text } as IAdministrativeDivision });
        } else if (key == "phuongXaId") {
            setFormData({ ...formData, [key]: value, phuongXa: { ...formData.phuongXa, id: value, ten: text } as IAdministrativeDivision });
        } else {
            setFormData({ ...formData, [key]: value });
        }
    }

    const handleChon = (khachId: string, chon: boolean) => {
        setTableData(tableData.map(x => x.khachId == khachId ? { ...x, chon: chon } : x));
    }

    const handleSaveInfo = (e: SyntheticEvent) => {
        e.preventDefault();
        if (formRef.current?.checkValidity()) {
            if (Utils.isValidDateRange(formData.thoiGianDen, formData.thoiGianDi)) {
                setTableData(tableData.map(x => x.khachId == formData.khachId ? formData : x));

                handleCloseModal();
            } else {
                setMessageFormModal("Thời gian đến và đi không hợp lệ.");
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    return (
        <>
            <ContentHeader title="Đăng ký lưu trú" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "40px" }}>Chọn</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Tình trạng</th>
                                                <th>Họ và tên</th>
                                                <th>Ngày sinh</th>
                                                <th>Giới tính</th>
                                                <th>Quốc tịch</th>
                                                <th>Số giấy tờ</th>
                                                <th>Địa chỉ</th>
                                                <th>Phòng</th>
                                                <th>Thời gian/Lý do lưu trú</th>
                                                <th style={{ textAlign: "center", width: "80px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.map(item =>
                                                    <tr key={item.khachId}>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.trangThai != ApprovalStatus.APPROVED && <Form.Check type="checkbox" checked={item.chon || false} onChange={(e) => handleChon(item.khachId, e.target.checked)} />}
                                                        </td>
                                                        <td>{UIUtils.getApprovalBadge(item.trangThai, false)}</td>
                                                        <td>{item.hoVaTen}</td>
                                                        <td>{Utils.combineBirthday(item.ngaySinh, item.thangSinh, item.namSinh)}</td>
                                                        <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                        <td>{item.quocTich?.ten || ""}</td>
                                                        <td>{item.loaiGiayTo?.ten || ""}: {item.soGiayTo}</td>
                                                        <td>{[item.diaChi, item.phuongXa?.ten, item.quanHuyen?.ten, item.tinhThanh?.ten].filter(x => x != null).join(", ")}</td>
                                                        <td>{item.phong?.ten || ""}</td>
                                                        <td>
                                                            {Utils.dateToString(item.thoiGianDen, Configs.DATE_TIME_FORMAT)} - {Utils.dateToString(item.thoiGianDi, Configs.DATE_TIME_FORMAT)}
                                                            {item.lyDoLuuTru?.ten && <p>{item.lyDoLuuTru.ten}</p>}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.trangThai != ApprovalStatus.APPROVED && <Button variant="warning" size="sm" onClick={() => handleEdit(item.khachId)}><i className="fas fa-pencil-alt"></i> Sửa</Button>}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="info" className="float-right" onClick={handleSave}><i className="fas fa-user-check"></i> Đăng ký</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{formData.hoVaTen}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(formData.trangThai || 0) > 0 && <Alert variant={formData.trangThai == ApprovalStatus.APPROVED ? "success" : "danger"}>
                        <Alert.Heading>{Utils.getApprovalName(formData.trangThai, false)}</Alert.Heading>
                        {Utils.splitNL2Array(formData.ghiChu).map((p, index) => <div key={index}>{p}</div>)}
                    </Alert>}
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="hoVaTen">
                            <Form.Label>Tên khách</Form.Label>
                            <Form.Control type="text" name="hoVaTen" required value={formData.hoVaTen} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Tên khách.</Form.Control.Feedback>
                        </Form.Group>
                        <DateInput nameOfDay="ngaySinh" valueOfDay={formData.ngaySinh} nameOfMonth="thangSinh" valueOfMonth={formData.thangSinh} nameOfYear="namSinh" valueOfYear={formData.namSinh} required={true} onChange={handleFormControlChange}></DateInput>
                        <Row>
                            <Col sm={12} md={6}>
                                <GenderSelect name="gioiTinh" value={formData.gioiTinh} required={true} onChange={handleFormSelectControlChange}></GenderSelect>
                            </Col>
                            <Col sm={12} md={6}>
                                <CategorySelect dataType="QuocTich" name="quocTichId" required={true} value={formData.quocTichId} onChange={handleFormSelectControlChange}></CategorySelect>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <CategorySelect dataType="LoaiGiayTo" name="loaiGiayToId" required={true} value={formData.loaiGiayToId} onChange={handleFormSelectControlChange}></CategorySelect>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="soGiayTo">
                                    <Form.Label>Số giấy tờ</Form.Label>
                                    <Form.Control type="text" name="soGiayTo" required value={formData.soGiayTo} onChange={(e) => handleFormControlChange(e as any)} />
                                    <Form.Control.Feedback type="invalid">Nhập Số giấy tờ.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <AdministrativeDivisionSelect tinhThanhId={formData.tinhThanhId} quanHuyenId={formData.quanHuyenId} phuongXaId={formData.phuongXaId} handleSelectOptionChange={(e) => handleFormSelectControlChange(e as any)} column={true} required={true}></AdministrativeDivisionSelect>
                        <Form.Group className="mb-3" controlId="diaChi">
                            <Form.Label>Địa chỉ</Form.Label>
                            <Form.Control type="text" name="diaChi" required value={formData.diaChi || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Địa chỉ.</Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="thoiGianDen">
                                    <Form.Label>Thời gian đến</Form.Label>
                                    <Form.Control type="datetime-local" name="thoiGianDen" required value={Utils.dateToString(formData.thoiGianDen, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                    <Form.Control.Feedback type="invalid">Nhập Thời gian đến.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="thoiGianDi">
                                    <Form.Label>Thời gian đi</Form.Label>
                                    <Form.Control type="datetime-local" name="thoiGianDi" required value={Utils.dateToString(formData.thoiGianDi, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <CategorySelect dataType="LyDoLuuTru" name="lyDoLuuTruId" required={true} value={formData.lyDoLuuTruId} onChange={handleFormSelectControlChange}></CategorySelect>
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSaveInfo}><i className="fas fa-save"></i> Lưu</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default DangKyLuuTru;