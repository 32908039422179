import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import Logo from "../components/Logo";
import UserNavbar from "../components/UserNavbar";
import { CategoryTypes } from "../constants/CategoryTypes";
import RoutePath from "../constants/RoutePath";
import useAppContext from "../hooks/useAppContext";
import AppStateService from "../services/AppStateService";
import TokenService from "../services/TokenService";
import TTLT_CoSoLuuTruService, { ITTLT_CoSoLuuTru } from "../services/TTLT_CoSoLuuTruService";
import { ActionTypes } from "../store/actions";

function SidebarLayout() {
    const { state, dispatch } = useAppContext();
    const navigate = useNavigate();

    const [csltItems, setCSLTItems] = useState([] as ITTLT_CoSoLuuTru[]);

    useEffect(() => {
        document.body.className = "hold-transition sidebar-mini layout-fixed";

        if (TokenService.isCSLT()) {
            GetCSLTItems();
        }
    }, []);

    const GetCSLTItems = async () => {
        const res = await TTLT_CoSoLuuTruService.getByUser();

        if (res?.isSuccess) {
            setCSLTItems(res.data);
            if (res.data.length > 0) {
                let currentCSLTId = AppStateService.get().currentCSLTId;
                if (res.data.find((item: ITTLT_CoSoLuuTru) => item.id == currentCSLTId) == undefined) {
                    handleCSLTChange((res.data[0] as ITTLT_CoSoLuuTru).id);
                }
            } else {
                AppStateService.remove();
                navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
            }
        } else {
            console.log(res?.message);
        }
    }

    const handleCSLTChange = (id: number | null) => {
        dispatch({
            type: ActionTypes.CHANGE_CSLT,
            payload: id
        });
    }

    const userSidebar = () => {
        if (TokenService.isAdministrator()) {
            return (
                <>
                    <li className="nav-header text-muted">DANH MỤC</li>
                    {
                        CategoryTypes.map(cate => {
                            return (
                                <li className="nav-item" key={cate.type}>
                                    <Link to={RoutePath.ADMIN.CATEGORY + cate.type} className="nav-link">
                                        <i className="nav-icon far fa-square text-muted"></i>
                                        <p>{cate.descriptions}</p>
                                    </Link>
                                </li>
                            );
                        })
                    }
                    <li className="nav-item">
                        <Link to={RoutePath.ADMIN.BORDER_GATES} className="nav-link">
                            <i className="nav-icon fas fa-torii-gate text-muted"></i>
                            <p>Cửa khẩu</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.ADMIN.ADMINISTRATIVE_DIVISIONS} className="nav-link">
                            <i className="nav-icon far fa-star text-muted"></i>
                            <p>Đơn vị hành chính</p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted">USERS & ROLES</li>
                    <li className="nav-item">
                        <Link to={RoutePath.ADMIN.USERS} className="nav-link">
                            <i className="nav-icon fas fa-user text-muted"></i>
                            <p>Users</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.ADMIN.ROLES} className="nav-link">
                            <i className="nav-icon fas fa-tasks text-muted"></i>
                            <p>Roles</p>
                        </Link>
                    </li>
                </>
            )
        }

        if (TokenService.isCSLT()) {
            return (
                <>
                    <li className="nav-header text-muted">QUẢN LÝ LƯU TRÚ</li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.SO_DO_PHONG} className="nav-link">
                            <i className="nav-icon fas fa-sitemap text-muted"></i>
                            <p>Sơ đồ phòng</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.DAT_PHONG} className="nav-link">
                            <i className="nav-icon fas fa-user-edit text-muted"></i>
                            <p>Đặt phòng</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.DANH_SACH_DAT_PHONG} className="nav-link">
                            <i className="nav-icon fas fa-user-plus text-muted"></i>
                            <p>Danh sách đặt phòng</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.DANH_SACH_DANG_KY_LUU_TRU} className="nav-link">
                            <i className="nav-icon fas fa-user-check text-muted"></i>
                            <p><small>Danh sách đăng ký lưu trú</small></p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.DANH_SACH_HOA_DON} className="nav-link">
                            <i className="nav-icon fas fa-file-invoice-dollar text-muted"></i>
                            <p>Hóa đơn phòng</p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted">THÓNG KÊ - BÁO CÁO</li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.BAO_CAO_DOANH_THU} className="nav-link">
                            <i className="nav-icon fas fa-chart-bar text-muted"></i>
                            <p>Báo cáo doanh thu</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.THONG_KE_KHACH_LUU_TRU} className="nav-link">
                            <i className="nav-icon fas fa-chart-bar text-muted"></i>
                            <p>Thống kê khách lưu trú</p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted">THÔNG BÁO - CẢNH BÁO</li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.MESSAGE_INBOX} className="nav-link">
                            <i className="nav-icon fas fa-bell text-muted"></i>
                            <p>Thông báo</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.DANH_SACH_DOI_TUONG_NGUY_HIEM} className="nav-link">
                            <i className="nav-icon fas fa-user-alt-slash text-muted"></i>
                            <p>Đối tượng nguy hiểm</p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted">DANH MỤC</li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.TANG} className="nav-link">
                            <i className="nav-icon far fa-circle text-muted"></i>
                            <p>Tầng</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.LOAI_PHONG} className="nav-link">
                            <i className="nav-icon far fa-circle text-muted"></i>
                            <p>Loại phòng</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.PHONG} className="nav-link">
                            <i className="nav-icon far fa-circle text-muted"></i>
                            <p>Phòng</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.DICH_VU} className="nav-link">
                            <i className="nav-icon far fa-circle text-muted"></i>
                            <p>Dịch vụ</p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted">THÔNG TIN CƠ SỞ</li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.THONG_TIN_DOANH_NGHIEP} className="nav-link">
                            <i className="nav-icon fas fa-briefcase text-muted"></i>
                            <p><small>Doanh nghiệp / hộ cá thể</small></p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.THONG_TIN_CSLT} className="nav-link">
                            <i className="nav-icon fas fa-hotel text-muted"></i>
                            <p>Cơ sở lưu trú</p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted"><hr /></li>
                    <li className="nav-item">
                        <Link to={RoutePath.CSLT.NGUOI_DUNG} className="nav-link">
                            <i className="nav-icon fas fa-user text-muted"></i>
                            <p>Quản lý người dùng</p>
                        </Link>
                    </li>
                </>
            )
        }

        if (TokenService.isCA()) {
            return (
                <>
                    <li className="nav-item">
                        <Link to={RoutePath.CA.TONG_QUAN} className="nav-link">
                            <i className="nav-icon fas fa-tachometer-alt text-muted"></i>
                            <p>Tổng quan</p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted"><hr /></li>
                    <li className="nav-item">
                        <Link to={RoutePath.CA.KHACH_LUU_TRU} className="nav-link">
                            <i className="nav-icon fas fa-user-check text-muted"></i>
                            <p><small>Danh sách khách lưu trú</small></p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CA.DANH_SACH_CO_SO_LUU_TRU} className="nav-link">
                            <i className="nav-icon fas fa-hotel text-muted"></i>
                            <p><small>Danh sách cơ sở lưu trú</small></p>
                        </Link>
                    </li>
                    <li className="nav-header text-muted"><hr /></li>
                    <li className="nav-item">
                        <Link to={RoutePath.CA.DOI_TUONG_NGUY_HIEM} className="nav-link">
                            <i className="nav-icon fas fa-user-alt-slash text-muted"></i>
                            <p>Đối tượng nguy hiểm</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.CA.MESSAGE_OUTBOX} className="nav-link">
                            <i className="nav-icon fas fa-bullhorn text-muted"></i>
                            <p>Thông báo</p>
                        </Link>
                    </li>
                    {/* <li className="nav-header text-muted"><hr /></li>
                    <li className="nav-item">
                        <Link to={RoutePath.CA.BAO_CAO} className="nav-link">
                            <i className="nav-icon fas fa-chart-bar text-muted"></i>
                            <p>Báo cáo</p>
                        </Link>
                    </li> */}
                </>
            )
        }

        if (TokenService.isUBND()) {
            return (
                <></>
            )
        }

        if (TokenService.isTHUE()) {
            return (
                <></>
            )
        }

        return <></>
    }

    return (
        <div className="wrapper">
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                    {csltItems.length > 0 && (
                        <Dropdown as={"li"}>
                            <Dropdown.Toggle variant="default">
                                {csltItems.find(item => item.id == state.currentCSLTId)?.ten || ""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {csltItems.map((item: ITTLT_CoSoLuuTru) => <Dropdown.Item onClick={() => handleCSLTChange(item.id)} key={item.id}>{item.ten}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </ul>

                <UserNavbar />
            </nav>

            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <Logo className="brand-link" />

                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {userSidebar()}
                        </ul>
                    </nav>
                </div>
            </aside>

            <div className="content-wrapper">
                <Outlet />
            </div>

            <Footer />
        </div>
    )
}

export default SidebarLayout