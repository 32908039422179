import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountService from "../services/AccountService";
import RoutePath from "../constants/RoutePath";

function Register() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        fullname: ""
    });

    const [message, setMessage] = useState("");

    const fullnameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        fullnameRef.current?.focus();
    }, []);

    useEffect(() => {
        setMessage('Register a new membership');
    }, [formData]);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            const res = await AccountService.register(formData.username, formData.password, formData.confirmPassword, formData.email, formData.fullname);

            if (res?.isSuccess) {
                setFormData({
                    username: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    fullname: ""
                });

                setMessage(res.message);

                setTimeout(() => {
                    navigate(RoutePath.AUTH.LOGIN, {replace: true});
                }, 1000);
            } else {
                setMessage(res?.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="register-box">
            <div className="card">
                <div className="card-body register-card-body">
                    <p className="login-box-msg">{message}</p>
                    <form className="mb-4" onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Full name" autoComplete="off" ref={fullnameRef} value={formData.fullname} onChange={(e) => setFormData({ ...formData, fullname: e.target.value })} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="email" className="form-control" placeholder="Email" autoComplete="off" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="User name" autoComplete="off" value={formData.username} onChange={(e) => setFormData({ ...formData, username: e.target.value })} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Password" autoComplete="off" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Retype password" autoComplete="off" value={formData.confirmPassword} onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-8">
                                <div className="icheck-primary">
                                    <input type="checkbox" id="agreeTerms" name="terms" defaultValue="agree" />
                                    <label htmlFor="agreeTerms">
                                        I agree to the <a href="#">terms</a>
                                    </label>
                                </div>
                            </div> */}
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block">Register</button>
                            </div>
                        </div>
                    </form>
                    <Link to={RoutePath.AUTH.LOGIN} className="text-center">I already have a membership</Link>
                </div>
            </div>
        </div>
    );
}

export default Register;