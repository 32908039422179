import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import useAppContext from "../../hooks/useAppContext";
import TTLT_LoaiPhongService, { ITTLT_LoaiPhong } from "../../services/TTLT_LoaiPhongService";
import DatePicker from "../../components/DatePicker";
import Utils from "../../utils/Utils";

function LoaiPhong() {
    const { state } = useAppContext();

    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_LoaiPhong>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const initFormData = {
        id: 0,
        ten: "",
        giaDangKy: null,
        sucChua: null,
        soCongVan: "",
        ngayCongVan: null,
        ghiChu: "",
        coSoLuuTruId: state.currentCSLTId || 0,
        suDung: false
    } as ITTLT_LoaiPhong;

    const [message, setMessage] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [formData, setFormData] = useState(initFormData);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });

    const keywordRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        if (state.currentCSLTId) {
            const res = await TTLT_LoaiPhongService.filter(state.currentCSLTId, pageIndex, pageSize, keyword);

            if (res?.isSuccess) {
                setTableData(res.data);
            } else {
                console.log(res?.message);
            }
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, [state.currentCSLTId]);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name == "sucChua") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null });
        } else if (e.target.name == "giaDangKy") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseFloat(e.target.value) : null });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }

    const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    }

    const handleAddNew = () => {
        setFormData(initFormData);

        handleShowModal();
    }

    const handleEdit = async (id: number) => {
        if (state.currentCSLTId) {
            const res = await TTLT_LoaiPhongService.get(state.currentCSLTId, id);

            if (res?.isSuccess) {
                setFormData({
                    ...initFormData,
                    id: res.data.id,
                    ten: res.data.ten,
                    sucChua: res.data.sucChua,
                    giaDangKy: res.data.giaDangKy,
                    soCongVan: res.data.soCongVan,
                    ngayCongVan: res.data.ngayCongVan,
                    ghiChu: res.data.ghiChu,
                    coSoLuuTruId: res.data.coSoLuuTruId,
                    suDung: res.data.suDung
                });

                handleShowModal();
            } else {
                console.log(res?.message);
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            if (formData.id > 0) {
                const res = await TTLT_LoaiPhongService.update(formData.id, formData);

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            } else {
                const res = await TTLT_LoaiPhongService.create(formData);

                if (res?.isSuccess) {
                    FilterFn(1);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const modalTitle = (formData.id > 0 ? "Sửa" : "Thêm mới") + " Loại phòng";

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        if (state.currentCSLTId) {
            const res = await TTLT_LoaiPhongService.delete(state.currentCSLTId, deletedItem.id);

            if (res?.isSuccess) {
                FilterFn(currentPageIndex);
            }

            hideConfirmationModal();
            setMessage(res.message);
        }
    }

    const handleSuDungSwitch = async (id: number, suDung: boolean) => {
        if (state.currentCSLTId) {
            const res = await TTLT_LoaiPhongService.changeIsUsedItem(state.currentCSLTId, id, suDung);

            if (res?.isSuccess) {
                FilterFn(currentPageIndex);
            } else {
                console.log(res?.message);
            }
        }
    }

    return (
        <>
            <ContentHeader title="Danh sách Loại phòng" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Button variant="info" size="sm" onClick={handleAddNew}>
                                        <i className="fas fa-plus"></i> Thêm mới
                                    </Button>

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Tên loại phòng</th>
                                                <th>Giá đăng ký</th>
                                                <th>Sức chứa</th>
                                                <th>Số công văn</th>
                                                <th>Ngày đăng ký</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Sử dụng</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.ten}</td>
                                                        <td>{Utils.formatNumber(item.giaDangKy)}</td>
                                                        <td>{item.sucChua}</td>
                                                        <td>{item.soCongVan}</td>
                                                        <td>{Utils.dateToString(item.ngayCongVan)}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" checked={item.suDung} onChange={(e) => handleSuDungSwitch(item.id, e.target.checked)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <ButtonGroup>
                                                                <Button variant="warning" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.ten)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="ten">
                            <Form.Label>Tên loại phòng</Form.Label>
                            <Form.Control type="text" name="ten" required value={formData.ten} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Tên loại phòng.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="sucChua">
                            <Form.Label>Sức chứa</Form.Label>
                            <Form.Control type="number" min={1} name="sucChua" required value={formData.sucChua || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Sức chứa.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="giaDangKy">
                            <Form.Label>Giá đăng ký</Form.Label>
                            <Form.Control type="number" min={0} name="giaDangKy" required value={formData.giaDangKy || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Giá đăng ký.</Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="soCongVan">
                                    <Form.Label>Số công văn</Form.Label>
                                    <Form.Control type="text" name="soCongVan" value={formData.soCongVan} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="ngayCongVan">
                                    <Form.Label>Ngày đăng ký</Form.Label>
                                    <DatePicker name="ngayCongVan" value={formData.ngayCongVan} onChange={handleFormControlChange}></DatePicker>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="ghiChu">
                            <Form.Label>Ghi chú</Form.Label>
                            <Form.Control type="text" as="textarea" name="ghiChu" value={formData.ghiChu} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="suDung">
                            <Form.Check type="checkbox" label="Sử dụng" name="suDung" checked={formData.suDung} onChange={(e) => handleCheckBoxChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Lưu</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

        </>
    )
}

export default LoaiPhong;