import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ITTLT_TienDichVu } from "./TTLT_HoaDonService";


export interface ITTLT_HoaDonDichVu {
    id: number | null;
    coSoLuuTruId: number | null;
    datPhongId: string;
    tienDichVu: ITTLT_TienDichVu[];
}

export default class TTLT_TienDichVuService {
    static async GetByDatPhongId(coSoLuuTruId: number, datPhongId: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TienDichVu/GetByDatPhongId/${coSoLuuTruId}/${datPhongId}`);
            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(hoaDonId: number, itemData: ITTLT_HoaDonDichVu) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/TienDichVu/${hoaDonId}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(itemData: ITTLT_HoaDonDichVu) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/TienDichVu/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}