import avatar from "./avatar.png";

namespace Configs {
    export const API_BASE_URL: string = "http://localhost:81";
    export const RECOVER_PASSWORD_URL: string = "http://localhost:3080/recover-password";
    export const DEFAULT_PAGE_SIZE: number = 10;
    export const DEFAULT_AVATAR: string = avatar;
    export const DATE_FORMAT: string = "DD/MM/YYYY";
    export const DATE_TIME_FORMAT: string = "DD/MM/YYYY HH:mm";
}

export default Configs;