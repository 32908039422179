export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
    }
    : {
        type: Key;
        payload: M[Key];
    }
};

export enum ActionTypes {
    CHANGE_CSLT = "CHANGE_CSLT",
    INCREASE_CLIENT_REQUESTS_TOTAL = "INCREASE_CLIENT_REQUESTS_TOTAL",
    REDUCE_CLIENT_REQUESTS_TOTAL = "REDUCE_CLIENT_REQUESTS_TOTAL",
}