import useAuthContext from "../hooks/useAuthContext";

function Home() {
    const { user } = useAuthContext();

    return (
        <div className="container text-center py-5">
            <h1><i className="far fa-hand-paper"></i> Xin chào <b>{user?.userInfo.fullname}</b>!</h1>
            <h5>Chúc mừng bạn đã đăng nhập thành công vào hệ thống.</h5>
        </div>
    );
}

export default Home;