import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import TTLT_DoiTuongNguyHiemService, { ITTLT_DoiTuongNguyHiem } from "../../services/TTLT_DoiTuongNguyHiemService";

function DoiTuongNguyHiem() {
    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_DoiTuongNguyHiem>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const initFormData = {
        id: null,
        ten: "",
        thongTin: "",
        anh: ""
    } as ITTLT_DoiTuongNguyHiem;

    const [file, setFile] = useState(null as any);
    const [formData, setFormData] = useState(initFormData);
    const [message, setMessage] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });

    const keywordRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        const res = await TTLT_DoiTuongNguyHiemService.filter(pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, []);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleFileControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setFile(e.target.files[0]);
        } else {
            setFile(null);
        }
    }

    const handleAddNew = () => {
        setFormData(initFormData);

        handleShowModal();
    }

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        if (id) {
            setDeletedItem({
                id: id,
                ten: name
            });

            setDisplayConfirmationModal(true);
            setMessage("");
        }
    }

    const handleDelete = async () => {
        const res = await TTLT_DoiTuongNguyHiemService.delete(deletedItem.id);

        if (res?.isSuccess) {
            FilterFn(currentPageIndex);
        }

        hideConfirmationModal();
        setMessage(res.message);
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const modalTitle = formData.id != null ? "Sửa đối tượng nguy hiểm" : "Thêm đối tượng nguy hiểm";

    const handleEdit = async (id: number | null) => {
        if (id) {
            const res = await TTLT_DoiTuongNguyHiemService.get(id);

            if (res?.isSuccess) {
                setFormData({
                    ...initFormData,
                    id: res.data.id,
                    ten: res.data.ten,
                    anh: res.data.anh,
                    thongTin: res.data.thongTin
                });

                handleShowModal();
            } else {
                console.log(res?.message);
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        var frmData = new FormData();
        if (file != null) {
            frmData.append("FormFile", file);
        }

        frmData.append("Ten", formData.ten);
        frmData.append("ThongTin", formData.thongTin);
        frmData.append("Anh", formData.anh);

        if (formRef.current?.checkValidity()) {
            if (formData.id) {
                const res = await TTLT_DoiTuongNguyHiemService.update(formData.id, frmData);

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            } else {
                const res = await TTLT_DoiTuongNguyHiemService.create(frmData);

                if (res?.isSuccess) {
                    FilterFn(1);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    return (
        <>
            <ContentHeader title="Đối tượng nguy hiểm" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Button variant="info" size="sm" onClick={handleAddNew}>
                                        <i className="fas fa-plus"></i> Thêm đối tượng nguy hiểm
                                    </Button>

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "200px" }}>Ảnh</th>
                                                <th>Tên đối tượng</th>
                                                <th style={{ textAlign: "center", width: "90px" }}>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.anh && <img src={`${Configs.API_BASE_URL}${item.anh}`} style={{ width: "100%" }} />}</td>
                                                        <td>{item.ten}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <ButtonGroup>
                                                                <Button variant="warning" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.ten)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Ảnh đối tượng</Form.Label>
                            <Card>
                                {formData.anh && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.anh}`} style={{ maxWidth: "300px", margin: "auto" }} />}
                                <Card.Body className="p-2">
                                    <Form.Control type="file" onChange={handleFileControlChange} />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ten">
                            <Form.Label>Tên đối tượng</Form.Label>
                            <Form.Control type="text" name="ten" required value={formData.ten} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập tên đối tượng.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="thongTin">
                            <Form.Label>Thông tin về đối tượng</Form.Label>
                            <Form.Control as="textarea" rows={7} name="thongTin" required value={formData.thongTin} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập thông tin về đối tượng.</Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Lưu thông tin</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DoiTuongNguyHiem;