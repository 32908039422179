import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Table, InputGroup, Card, Container, Row, Col, Alert, ButtonGroup, Breadcrumb } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import AdministrativeDivisionService, { IAdministrativeDivision } from "../../services/AdministrativeDivisionService";

function AdministrativeDivisions() {
    const [tableData, setTableData] = useState({
        data: new Array<IAdministrativeDivision>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const initFormData = {
        id: 0,
        ten: "",
        ma: "",
        parentId: null,
        suDung: false
    } as IAdministrativeDivision;

    const [parentId, setParentID] = useState(initFormData.parentId);
    const [message, setMessage] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [formData, setFormData] = useState(initFormData);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });

    const keywordRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const FilterFn = async (parentId: number | null, keyword: string, pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;

        const res = await AdministrativeDivisionService.filter(parentId, pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }

        setCurrentPageIndex(pageIndex);
        setParentID(parentId);
        setSearchInput(keyword);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(null, "", 1);
    }, []);

    const handleSearch = () => {
        FilterFn(null, keywordRef.current?.value || "", 1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(null, keywordRef.current?.value || "", 1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(parentId, keywordRef.current?.value || "", pageNumber);
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    }

    const handleAddNew = () => {
        setFormData({ ...initFormData, parentId: parentId });

        handleShowModal();
    }

    const handleEdit = async (id: number) => {
        const res = await AdministrativeDivisionService.get(id);

        if (res?.isSuccess) {
            setFormData({
                ...initFormData,
                id: res.data.id,
                ten: res.data.ten,
                ma: res.data.ma,
                parentId: res.data.parentId,
                suDung: res.data.suDung
            });

            handleShowModal();
        } else {
            console.log(res?.message);
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            if (formData.id > 0) {
                const res = await AdministrativeDivisionService.update(formData.id, formData);

                if (res?.isSuccess) {
                    FilterFn(parentId, keywordRef.current?.value || "", currentPageIndex);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            } else {
                const res = await AdministrativeDivisionService.create(formData);

                if (res?.isSuccess) {
                    FilterFn(parentId, keywordRef.current?.value || "", 1);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const modalTitle = (formData.id > 0 ? "Sửa" : "Thêm mới") + " Đơn vị hành chính";

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        const res = await AdministrativeDivisionService.delete(deletedItem.id);

        if (res?.isSuccess) {
            FilterFn(parentId, keywordRef.current?.value || "", currentPageIndex);
        }

        hideConfirmationModal();
        setMessage(res.message);
    }

    const handleSuDungSwitch = async (id: number, suDung: boolean) => {
        const res = await AdministrativeDivisionService.changeIsUsedItem(id, suDung);

        if (res?.isSuccess) {
            FilterFn(parentId, keywordRef.current?.value || "", currentPageIndex);
        } else {
            console.log(res?.message);
        }
    }

    const handleShowChildren = (parentId: number | null) => {
        FilterFn(parentId, "", 1);
    }

    const [breadcrumbItems, setBreadcrumbItems] = useState([] as Array<IAdministrativeDivision>);

    useEffect(() => {
        if (parentId == null) {
            setBreadcrumbItems([]);
        } else {
            GetBreadcrumbItems(parentId);
        }
    }, [parentId]);

    const GetBreadcrumbItems = async (parentId: number) => {
        const res = await AdministrativeDivisionService.getRoot(parentId);
        if (res?.isSuccess && res?.data) {
            let item = res.data;
            let items = new Array<IAdministrativeDivision>();

            items.push({ ...item, children: null });

            while (item.children?.length == 1) {
                item = item.children[0];

                items.push({ ...item, children: null });
            }

            setBreadcrumbItems(items);
        } else {
            console.log(res?.message);
            setBreadcrumbItems([]);
        }
    }

    return (
        <>
            <ContentHeader title="Đơn vị hành chính" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Button variant="info" size="sm" onClick={handleAddNew}>
                                        <i className="fas fa-plus"></i> Thêm mới
                                    </Button>

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                                onChange={e => setSearchInput(e.target.value)}
                                                value={searchInput}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}

                                    <Breadcrumb>
                                        <Breadcrumb.Item key={0} onClick={() => handleShowChildren(null)}><i className="fas fa-globe-asia"></i></Breadcrumb.Item>
                                        {breadcrumbItems.map(item => {
                                            return <Breadcrumb.Item key={item.id} onClick={() => handleShowChildren(item.id)}>{item.ten}</Breadcrumb.Item>
                                        })}
                                    </Breadcrumb>

                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "40px" }}></th>
                                                <th>Tên</th>
                                                <th>Mã</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Sử dụng</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            <Button variant="default" size="sm" onClick={() => handleShowChildren(item.id)}>
                                                                <i className="far fa-caret-square-right"></i>
                                                            </Button>
                                                        </td>
                                                        <td>{item.ten}</td>
                                                        <td>{item.ma}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" checked={item.suDung} onChange={(e) => handleSuDungSwitch(item.id, e.target.checked)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <ButtonGroup>
                                                                <Button variant="warning" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.ten)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Breadcrumb>
                        {breadcrumbItems.map(item => {
                            return <Breadcrumb.Item key={item.id} active>{item.ten}</Breadcrumb.Item>
                        })}
                    </Breadcrumb>

                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="ten">
                            <Form.Label>Tên đơn vị hành chính</Form.Label>
                            <Form.Control type="text" name="ten" required value={formData.ten} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Tên đơn vị hành chính.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ma">
                            <Form.Label>Mã đơn vị hành chính</Form.Label>
                            <Form.Control type="text" name="ma" value={formData.ma || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="suDung">
                            <Form.Check type="checkbox" label="Sử dụng" name="suDung" checked={formData.suDung} onChange={(e) => handleCheckBoxChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Lưu</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

        </>
    )
}

export default AdministrativeDivisions;