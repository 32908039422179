import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface IUser {
    accessFailedCount: number | null;
    avatar: string | null;
    createdBy: number;
    createdOn: Date;
    email: string;
    emailConfirmed: boolean;
    fullName: string;
    id: number;
    isDeleted: boolean;
    isLockedOut: boolean;
    lockoutEnabled: boolean;
    lockoutEnd: Date | null;
    roles: Array<IRole>;
    type: string;
    updatedBy: number | null;
    updatedOn: Date | null;
    userName: string;
    password: string;
    confirmPassword: string;
}

export interface IUserSetPassword {
    userId: number;
    newPassword: string;
    confirmNewPassword: string;
}

export interface IRole {
    id: number;
    name: string;
    checked: boolean;
}

export interface IUserUpdate {
    email: string;
    fullName: string;
    type: string;
    roles: Array<IRole>;
}

export interface IUserCreate extends IUserUpdate {
    userName: string;
    password: string;
    confirmPassword: string;
}

export default class UserService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Users?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Users/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, userData: IUserUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Users/${id}`, userData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(userData: IUserCreate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Users/Create`, userData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async setPassword(userData: IUserSetPassword) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Users/SetPassword`, userData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async lockOrUnlock(userId: number, isLockedOut: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Users/LockOrUnlock`, { userId, isLockedOut });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Users/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}