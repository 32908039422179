interface IPaginationProps {
    hasNext: boolean;
    hasPrevious: boolean;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    handlePageChange: (pageNumber: number) => void;
}

function Pagination(props: IPaginationProps) {
    let startPageIndex = props.pageIndex - 2;
    let endPageIndex = props.pageIndex + 2;

    startPageIndex = startPageIndex >= 1 ? startPageIndex : 1;
    endPageIndex = endPageIndex <= props.totalPages ? endPageIndex : props.totalPages;

    const pages = [];
    for (let i = startPageIndex; i <= endPageIndex; i++) {
        if (i == props.pageIndex) {
            pages.push(<li className="page-item active" key={i}><a className="page-link">{i}</a></li>);
        } else {
            pages.push(<li className="page-item" key={i} onClick={(e) => { e.preventDefault(); props.handlePageChange(i) }}><a className="page-link" href="#">{i}</a></li>);
        }
    }

    let fromIndex = Math.min((props.pageIndex - 1) * props.pageSize + 1, props.totalRecords);
    let toIndex = Math.min(props.pageIndex * props.pageSize, props.totalRecords);

    return (
        <>
            <small className="text-muted">Showing {fromIndex} to {toIndex} of {props.totalRecords} entries</small>
            {
                (props.totalPages > 1) && (
                    <ul className="pagination m-0 float-right">
                        {props.hasPrevious && (
                            <>
                                <li className="page-item" onClick={(e) => { e.preventDefault(); props.handlePageChange(1) }}><a className="page-link" href="#">«</a></li>
                                <li className="page-item" onClick={(e) => { e.preventDefault(); props.handlePageChange(props.pageIndex - 1) }}><a className="page-link" href="#">‹</a></li>
                            </>
                        )}

                        {pages}

                        {props.hasNext && (
                            <>
                                <li className="page-item" onClick={(e) => { e.preventDefault(); props.handlePageChange(props.pageIndex + 1) }}><a className="page-link" href="#">›</a></li>
                                <li className="page-item" onClick={(e) => { e.preventDefault(); props.handlePageChange(props.totalPages) }}><a className="page-link" href="#">»</a></li>
                            </>
                        )}
                    </ul>
                )
            }
        </>
    )
}

export default Pagination;