import { KeyboardEvent, RefObject, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import RoutePath from "../../constants/RoutePath";
import useAppContext from "../../hooks/useAppContext";
import TTLT_HoaDonService, { ITTLT_HoaDon } from "../../services/TTLT_HoaDonService";
import Utils from "../../utils/Utils";

function DanhSachHoaDon() {
    const { state } = useAppContext();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_HoaDon>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const [message, setMessage] = useState("");
    const [currentPageIndex, setCurrentPageIndex] = useState(1);

    const keywordRef = useRef<HTMLInputElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        if (state.currentCSLTId) {
            const res = await TTLT_HoaDonService.filter(state.currentCSLTId, pageIndex, pageSize, keyword);

            if (res?.isSuccess) {
                setTableData(res.data);
            } else {
                console.log(res?.message);
            }
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, [state.currentCSLTId]);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    //Delete
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        if (state.currentCSLTId) {
            const res = await TTLT_HoaDonService.delete(state.currentCSLTId, deletedItem.id);

            if (res?.isSuccess) {
                FilterFn(currentPageIndex);
            }

            hideConfirmationModal();
            setMessage(res.message);
        }
    }

    const handleEdit = async (id: number) => {
        navigate(`${RoutePath.CSLT.HOA_DON}/${id}`, { replace: true });
    }

    return (
        <>
            <ContentHeader title="Danh sách hóa đơn phòng" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Ngày</th>
                                                <th>Phòng</th>
                                                <th>Tên khách</th>
                                                <th>Tiền phòng</th>
                                                <th>Tiền dịch vụ</th>
                                                <th>Tổng tiền</th>
                                                <th>Tiền tệ</th>
                                                <th>Ghi chú</th>
                                                <th style={{ textAlign: "center", width: "90px" }}>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{Utils.dateToString(item.ngayHoaDon, "DD/MM/YYYY")}</td>
                                                        <td>{item.datPhong?.phong?.ten || ""}</td>
                                                        <td>{item.tenKhachHang}</td>
                                                        <td>{Utils.formatNumber(item.tongTienPhong)}</td>
                                                        <td>{Utils.formatNumber(item.tongTienDichVu)}</td>
                                                        <td>{Utils.formatNumber((item.tongTienPhong || 0) + (item.tongTienDichVu || 0))}</td>
                                                        <td>{item.tienTe?.ten}</td>
                                                        <td>{item.ghiChu}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <ButtonGroup>
                                                                <Button variant="warning" size="sm" onClick={() => handleEdit(item.id || 0)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id || 0, item.tenKhachHang)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />
        </>
    );
}

export default DanhSachHoaDon;