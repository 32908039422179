import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Accordion, Alert, Button, ButtonGroup, Card, CardGroup, Col, Container, Dropdown, Form, Modal, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SelectInstance } from "react-select";
import AsyncSelect from "react-select/async";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { IFormProps, IModalProps } from "../../constants/Common";
import RoutePath from "../../constants/RoutePath";
import { SelectOptionType } from "../../constants/SelectOptionType";
import useAppContext from "../../hooks/useAppContext";
import TTLT_DatPhongService, { CheckinStatus } from "../../services/TTLT_DatPhongService";
import TTLT_DichVuService, { ITTLT_DichVu } from "../../services/TTLT_DichVuService";
import { ITTLT_TienDichVu } from "../../services/TTLT_HoaDonService";
import TTLT_LoaiPhongService, { ITTLT_LoaiPhong } from "../../services/TTLT_LoaiPhongService";
import TTLT_TangService, { ITTLT_Tang } from "../../services/TTLT_TangService";
import TTLT_TienDichVuService, { ITTLT_HoaDonDichVu } from "../../services/TTLT_TienDichVuService";
import Utils from "../../utils/Utils";

function SoDoPhong() {
    const { state } = useAppContext();

    const [tangData, setTangData] = useState([] as Array<ITTLT_Tang>);
    const [loaiPhongData, setLoaiPhongData] = useState([] as Array<ITTLT_LoaiPhong>);
    const [filters, setFilters] = useState({
        loaiPhongId: null as number | null,
        tenLoaiPhong: ""
    });

    const loadLoaiPhong = async () => {
        if (state.currentCSLTId) {
            const res = await TTLT_LoaiPhongService.listAll(state.currentCSLTId);

            if (res?.isSuccess) {
                setLoaiPhongData(res.data);
            } else {
                console.log(res?.message);
            }
        }
    }

    const loadTang = async () => {
        if (state.currentCSLTId) {
            const res = await TTLT_TangService.sitemap(state.currentCSLTId);

            if (res?.isSuccess) {
                setTangData(res.data);
            } else {
                console.log(res?.message);
            }
        }
    }

    useEffect(() => {
        loadLoaiPhong();

        loadTang();
    }, [state.currentCSLTId]);

    const handleFilter = (loaiPhongId: number | null, tenLoaiPhong: string) => {
        setFilters({ loaiPhongId, tenLoaiPhong });
    }

    //Dich Vu
    const initFormData: ITTLT_HoaDonDichVu = {
        id: null,
        coSoLuuTruId: state.currentCSLTId,
        datPhongId: "",
        tienDichVu: []
    }

    const [formData, setFormData] = useState(initFormData);
    const [servicesModal, setServicesModal] = useState({
        title: "",
        show: false,
        onHide: () => {
            setServicesModal({ ...servicesModal, show: false });
        },
        onShow: async (datPhongId: string, tenPhong: string) => {
            if (state.currentCSLTId) {
                const res = await TTLT_TienDichVuService.GetByDatPhongId(state.currentCSLTId, datPhongId);

                formData.datPhongId = datPhongId;
                if (res?.isSuccess && res?.data) {
                    formData.id = res.data.id;
                    formData.coSoLuuTruId = res.data.coSoLuuTruId;
                    formData.datPhongId = res.data.datPhongId;

                    formData.tienDichVu = [];
                    res.data.tienDichVu.forEach((item: ITTLT_TienDichVu) => {
                        formData.tienDichVu.push({
                            dichVuId: item.dichVuId,
                            soLuong: item.soLuong,
                            donGia: item.donGia,
                            ghiChu: item.ghiChu,
                            dichVu: item.dichVu
                        } as ITTLT_TienDichVu);
                    });

                    setFormData(formData);
                }

                setServicesModal({ ...servicesModal, show: true, title: `Phòng ${tenPhong}` });
            }
        }
    } as IModalProps);

    const [servicesForm, setServicesForm] = useState({
        ref: useRef<HTMLFormElement>(null),
        validated: false,
        message: "",
        onHideValidated: () => {
            setServicesForm({ ...servicesForm, validated: false });
        },
        onShowValidated: () => {
            setServicesForm({ ...servicesForm, validated: true });
        },
        onHideMessage: () => {
            setServicesForm({ ...servicesForm, message: "" });
        },
        onShowMessage: (message: string) => {
            setServicesForm({ ...servicesForm, message: message });
        }
    } as IFormProps);

    //AsyncSelect
    const [selectedOption, setSelectedOption] = useState(null as SelectOptionType | null);
    const asyncRef = useRef<SelectInstance<SelectOptionType> | null>(null);

    const handleSelectChange = async (option: SelectOptionType | null) => {
        setSelectedOption(option);

        if (state.currentCSLTId && option?.value) {
            let value = option?.value as number;

            if (formData.tienDichVu.filter(x => x.dichVuId == value).length == 0) {
                var res = await TTLT_DichVuService.getByUsedId(state.currentCSLTId, value);

                if (res.isSuccess) {
                    let item: ITTLT_TienDichVu = {
                        dichVuId: res.data.id,
                        hoaDonId: null,
                        soLuong: 1,
                        donGia: res.data.donGia,
                        ghiChu: "",
                        dichVu: res.data
                    };

                    formData.tienDichVu.push(item);

                    setFormData(formData);

                    setSelectedOption(null);
                    asyncRef.current?.focus();
                }
            }
        }
    }

    const filterDichVu = async (inputValue: string) => {
        var options: SelectOptionType[] = [];

        if (state?.currentCSLTId) {
            var res = await TTLT_DichVuService.filter(state.currentCSLTId, inputValue);

            if (res.isSuccess && res.data) {
                options = res.data.map((item: ITTLT_DichVu) => {
                    return { value: item.id, label: item.ten }
                });
            }
        }

        return options;
    };

    const loadDichVuOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterDichVu(inputValue));
    });

    const handleTableDichVuFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, dichVuId: number | null) => {
        var name = e.target.name;
        var value = e.target.value;

        var item = formData.tienDichVu.find(x => x.dichVuId == dichVuId);
        if (item) {
            if (name == "soLuong" || name == "donGia") {
                setFormData({ ...formData, tienDichVu: formData.tienDichVu.map(x => x.dichVuId == dichVuId ? { ...x, [name]: value ? parseInt(value) : null } : x) });
            } else {
                setFormData({ ...formData, tienDichVu: formData.tienDichVu.map(x => x.dichVuId == dichVuId ? { ...x, [name]: value } : x) });
            }
        }
    }

    const handleRemoveDichVu = (dichVuId: number | null) => {
        setFormData({ ...formData, tienDichVu: formData.tienDichVu.filter(x => x.dichVuId != dichVuId) });
    }

    const handleSaveServices = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (servicesForm.ref.current?.checkValidity()) {
            if (formData.id) {
                const res = await TTLT_TienDichVuService.update(formData.id, formData);

                if (res?.isSuccess) {
                    servicesModal.onHide();
                } else {
                    servicesForm.onShowMessage(res?.message);
                }
            } else {
                const res = await TTLT_TienDichVuService.create(formData);

                if (res?.isSuccess) {
                    servicesModal.onHide();
                } else {
                    servicesForm.onShowMessage(res?.message);
                }
            }
        } else {
            servicesForm.onShowValidated();
        }
    }

    //Delete
    const [deletedItem, setDeletedItem] = useState({
        id: "",
        ten: ""
    });
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: "",
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: string, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        // setMessage("");
    }

    const handleDelete = async () => {
        if (state.currentCSLTId) {
            const res = await TTLT_DatPhongService.delete(state.currentCSLTId, deletedItem.id);

            if (res?.isSuccess) {
                loadTang();
            }

            hideConfirmationModal();
            // setMessage(res.message);
        }
    }

    return (
        <>
            <ContentHeader title="Sơ đồ phòng" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header className="text-right">
                                    <Dropdown as={ButtonGroup} size="sm" align="end">
                                        <Dropdown.Toggle variant="light"><i className="fas fa-filter"></i> Loại phòng {filters.loaiPhongId ? `(${filters.tenLoaiPhong})` : ""}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            <Dropdown.Item as={Button} className="btn-flat btn-secondary" onClick={() => handleFilter(null, "")}>Tất cả </Dropdown.Item>
                                            {loaiPhongData.map(loai => {
                                                return <Dropdown.Item as={Button} className="btn-flat btn-secondary" onClick={() => handleFilter(loai.id, loai.ten)} key={loai.id}>{loai.ten}</Dropdown.Item>
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    {tangData.map(tang => {
                                        return <Accordion defaultActiveKey={`${tang.id}`} key={tang.id}>
                                            <Accordion.Item eventKey={`${tang.id}`} style={{ borderRadius: "0px" }}>
                                                <Accordion.Header className="text-info"><i className="fas fa-layer-group mr-2"></i> {tang.ten.toUpperCase()}</Accordion.Header>
                                                <Accordion.Body>
                                                    <CardGroup>
                                                        {tang.phong?.map(phong => {
                                                            let bgColor = "light";
                                                            if (phong.trangThai == CheckinStatus.CheckedIn.value) {
                                                                bgColor = "info";
                                                            }
                                                            if (phong.trangThai == CheckinStatus.None.value) {
                                                                bgColor = "warning";
                                                            }

                                                            return (filters.loaiPhongId == null || filters.loaiPhongId == phong.loaiPhongId) && <Card bg={bgColor} key={phong.id} style={{ minWidth: "120px" }}>
                                                                <Card.Header as="h4" className="text-center p-1">{phong.ten}</Card.Header>
                                                                <Card.Body>
                                                                    <Card.Subtitle>{Utils.formatNumber(phong.loaiPhong?.giaDangKy || null)}</Card.Subtitle>
                                                                    <Card.Text>{phong.loaiPhong?.ten || ""}</Card.Text>
                                                                </Card.Body>
                                                                <Card.Footer className="p-0">
                                                                    <Dropdown as={ButtonGroup} size="sm" align="end" className="d-block">
                                                                        <Dropdown.Toggle className="text-muted" style={{ width: "100%", backgroundColor: "transparent", border: "none", textAlign: "right" }}>Thao tác</Dropdown.Toggle>
                                                                        <Dropdown.Menu variant="dark">
                                                                            {phong.trangThai == null &&
                                                                                <Dropdown.Item as={Link} to={`${RoutePath.CSLT.DAT_PHONG}?phongId=${phong.id}`}>
                                                                                    <i className="fas fa-plus text-muted"></i> Đặt phòng
                                                                                </Dropdown.Item>}
                                                                            {phong.trangThai == CheckinStatus.CheckedIn.value && <>
                                                                                <Dropdown.Item as={Button} className="btn-flat btn-secondary" onClick={() => servicesModal.onShow(phong.datPhongId || "", phong.ten || "")}>
                                                                                    <i className="fas fa-list-alt text-muted"></i> Chi tiết dịch vụ
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item as={Link} to={`${RoutePath.CSLT.TRA_PHONG}/${phong.datPhongId}`}>
                                                                                    <i className="fas fa-sign-out-alt text-muted"></i> Trả phòng
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item as={Link} to={`${RoutePath.CSLT.CHUYEN_PHONG}/${phong.datPhongId}`}>
                                                                                    <i className="fas fa-exchange-alt text-muted"></i> Chuyển phòng
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider />
                                                                            </>}
                                                                            {phong.trangThai != null && <>
                                                                                <Dropdown.Item as={Link} to={`${RoutePath.CSLT.DAT_PHONG}/${phong.datPhongId}`}>
                                                                                    <i className="fas fa-edit text-muted"></i> <small>Sửa thông tin đặt phòng</small>
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item as={Link} to={`${RoutePath.CSLT.DANG_KY_LUU_TRU}/${phong.datPhongId}`}>
                                                                                    <i className="fas fa-user-check text-muted"></i> <small>Đăng ký lưu trú</small>
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item as={Button} className="btn-flat btn-danger" onClick={() => showDeleteConfirmation(phong.datPhongId || "", phong?.ten || "")}>
                                                                                    <i className="fas fa-trash-alt text-muted"></i> Xóa đặt phòng
                                                                                </Dropdown.Item>
                                                                            </>}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </Card.Footer>
                                                            </Card>
                                                        })}
                                                    </CardGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    })}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

            <Modal size="lg" show={servicesModal.show} onHide={servicesModal.onHide} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{servicesModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AsyncSelect className="mb-3" isClearable value={selectedOption} loadOptions={loadDichVuOptions} onChange={handleSelectChange} ref={asyncRef} placeholder="Nhập tên dịch vụ để tìm kiếm." noOptionsMessage={({ inputValue }) => !inputValue ? "Nhập tên dịch vụ để tìm kiếm." : "Không tìm thấy dịch vụ nào!"} />
                    <Form noValidate validated={servicesForm.validated} ref={servicesForm.ref}>
                        <Table hover bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Tên dịch vụ</th>
                                    <th style={{ width: "100px" }}>Số lượng</th>
                                    <th style={{ width: "120px" }}>Đơn giá</th>
                                    <th>Ghi chú</th>
                                    <th style={{ width: "40px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.tienDichVu.map(item =>
                                    <tr key={item.dichVuId}>
                                        <td>
                                            <b>{item.dichVu?.ten || ""}</b>
                                            <small className="text-muted d-block">{item.dichVu?.donVi || ""}</small>
                                        </td>
                                        <td>
                                            <Form.Control type="number" min={1} required name="soLuong" value={item.soLuong || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                        </td>
                                        <td>
                                            <Form.Control type="number" min={0} required name="donGia" value={item.donGia || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                        </td>
                                        <td>
                                            <Form.Control type="text" name="ghiChu" value={item.ghiChu || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                        </td>
                                        <td>
                                            <Button variant="light" size="sm" onClick={() => handleRemoveDichVu(item.dichVuId)}><i className="fas fa-times"></i></Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Form>
                    {servicesForm.message != "" && <Alert variant="info" onClose={servicesForm.onHideMessage} dismissible>{servicesForm.message}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={servicesModal.onHide}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSaveServices}><i className="fas fa-save"></i> Lưu</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SoDoPhong;