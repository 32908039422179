import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface ITTLT_LoaiPhong {
    id: number;
    ten: string;
    giaDangKy: number | null;
    sucChua: number | null;
    soCongVan: string;
    ngayCongVan: string | null;
    ghiChu: string;
    coSoLuuTruId: number;
    suDung: boolean;
}

export default class TTLT_LoaiPhongService {
    static async listAll(coSoLuuTruId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/LoaiPhong/All/${coSoLuuTruId}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filter(coSoLuuTruId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/LoaiPhong/${coSoLuuTruId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/LoaiPhong/${coSoLuuTruId}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/LoaiPhong/${coSoLuuTruId}/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, itemData: ITTLT_LoaiPhong) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/LoaiPhong/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(itemData: ITTLT_LoaiPhong) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/LoaiPhong/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeIsUsedItem(coSoLuuTruId: number, id: number, suDung: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/LoaiPhong/ChangeIsUsedItem/${coSoLuuTruId}`, { id, suDung });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}