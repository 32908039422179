import { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

interface IDateInputProps {
    nameOfDay: string;
    valueOfDay: number | null;
    nameOfMonth: string;
    valueOfMonth: number | null;
    nameOfYear: string;
    valueOfYear: number | null;
    required?: boolean;
    onChange: (e: any) => void;
}

function DateInput(props: IDateInputProps) {

    const range = (start: number, end: number) => Array.from(Array(end - start + 1).keys()).map(x => x + start);

    const daysOfMonth = (month: number | null, year: number | null) => {
        let dayNum: number = 31;

        if (month) {
            if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
                dayNum = 31;
            } else if ([4, 6, 9, 11].includes(month)) {
                dayNum = 30;
            } else {
                if (year) {
                    const isLeap = new Date(year, 1, 29).getMonth() === 1;
                    dayNum = isLeap ? 29 : 28;
                } else {
                    dayNum = 28;
                }
            }
        }

        return range(1, dayNum);
    }

    const [dayOptions, setDayOptions] = useState([] as number[]);
    const dayRef = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        const days = daysOfMonth(props.valueOfMonth, props.valueOfYear);
        if (!days.includes(props.valueOfDay || 0)) {
            if (dayRef.current) {
                dayRef.current.value = "";
                dayRef.current.dispatchEvent(new Event("change", { bubbles: true }));
            }
        }

        setDayOptions(days);
    }, [props.valueOfMonth, props.valueOfYear]);

    return (
        <Form.Group className="mb-3">
            <Form.Label>Ngày sinh</Form.Label>
            <InputGroup>
                {/* <Form.Control type="number" min={1} max={31} name={props.nameOfDay} value={props.valueOfDay || ""} onChange={props.onChange} /> */}
                <Form.Select name={props.nameOfDay} value={props.valueOfDay || ""} onChange={props.onChange} ref={dayRef}>
                    <option>Ngày</option>
                    {dayOptions.map(day => {
                        return <option value={day} key={day}>{day}</option>
                    })}
                </Form.Select>
                <InputGroup.Text>/</InputGroup.Text>
                {/* <Form.Control type="number" min={1} max={12} name={props.nameOfMonth} value={props.valueOfMonth || ""} onChange={props.onChange} /> */}
                <Form.Select name={props.nameOfMonth} value={props.valueOfMonth || ""} onChange={props.onChange} >
                    <option>Tháng</option>
                    {range(1, 12).map(month => {
                        return <option value={month} key={month}>{month}</option>
                    })}
                </Form.Select>
                <InputGroup.Text>/</InputGroup.Text>
                <Form.Control type="number" placeholder="Năm" min={1900} max={new Date().getFullYear()} name={props.nameOfYear} value={props.valueOfYear || ""} required={props?.required} onChange={props.onChange}
                    style={{
                        borderTopRightRadius: ".375rem",
                        borderBottomRightRadius: ".375rem"
                    }}
                />
                {props.required && <Form.Control.Feedback type="invalid">Nhập năm sinh.</Form.Control.Feedback>}
            </InputGroup>
        </Form.Group>
    )
}

export default DateInput;