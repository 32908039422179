import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import AdministrativeDivisionSelect from "../../components/AdministrativeDivisionSelect";
import CategorySelect from "../../components/CategorySelect";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DateInput from "../../components/DateInput";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import GenderSelect from "../../components/GenderSelect";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import { ApprovalStatus } from "../../constants/ApprovalStatus";
import useAppContext from "../../hooks/useAppContext";
import { IAdministrativeDivision } from "../../services/AdministrativeDivisionService";
import { ICategory } from "../../services/CategoryService";
import TTLT_DangKyLuuTruService, { ITTLT_DangKyLuuTru } from "../../services/TTLT_DangKyLuuTruService";
import UIUtils from "../../utils/UIUtils";
import Utils from "../../utils/Utils";

function DanhSachDangKyLuuTru() {
    const { state } = useAppContext();

    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_DangKyLuuTru>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const [message, setMessage] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);

    const keywordRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        if (state.currentCSLTId) {
            const res = await TTLT_DangKyLuuTruService.filter(state.currentCSLTId, pageIndex, pageSize, keyword);

            if (res?.isSuccess) {
                setTableData(res.data);
            } else {
                console.log(res?.message);
            }
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, [state.currentCSLTId]);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const [formData, setFormData] = useState({} as ITTLT_DangKyLuuTru);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const handleEdit = async (id: number | null) => {
        if (state.currentCSLTId && id) {
            const res = await TTLT_DangKyLuuTruService.get(state.currentCSLTId, id);

            if (res?.isSuccess) {
                setFormData(res.data);

                handleShowModal();
            } else {
                console.log(res?.message);
            }
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        var key = e.target.name;
        var value = e.target.value;

        if (key == "gioiTinh" || key == "ngaySinh" || key == "thangSinh" || key == "namSinh") {
            setFormData({ ...formData, [key]: value ? parseInt(value) : null });
        } else {
            setFormData({ ...formData, [key]: value });
        }
    }

    const handleFormSelectControlChange = (e: ChangeEvent<HTMLSelectElement>) => {
        var key = e.target.name;
        var value = e.target.value ? parseInt(e.target.value) : null;
        var text = e.target.options[e.target.selectedIndex].text;

        if (key == "quocTichId") {
            setFormData({ ...formData, [key]: value, quocTich: { ...formData.quocTich, id: value, ten: text } as ICategory });
        } else if (key == "lyDoLuuTruId") {
            setFormData({ ...formData, [key]: value, lyDoLuuTru: { ...formData.lyDoLuuTru, id: value, ten: text } as ICategory });
        } else if (key == "loaiGiayToId") {
            setFormData({ ...formData, [key]: value, loaiGiayTo: { ...formData.loaiGiayTo, id: value, ten: text } as ICategory });
        } else if (key == "tinhThanhId") {
            setFormData({ ...formData, [key]: value, tinhThanh: { ...formData.tinhThanh, id: value, ten: text } as IAdministrativeDivision });
        } else if (key == "quanHuyenId") {
            setFormData({ ...formData, [key]: value, quanHuyen: { ...formData.quanHuyen, id: value, ten: text } as IAdministrativeDivision });
        } else if (key == "phuongXaId") {
            setFormData({ ...formData, [key]: value, phuongXa: { ...formData.phuongXa, id: value, ten: text } as IAdministrativeDivision });
        } else {
            setFormData({ ...formData, [key]: value });
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            if (formData.id) {
                const res = await TTLT_DangKyLuuTruService.update(formData.id, formData);

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex);
                    handleCloseModal();
                    setMessage(res.message);
                } else {
                    setMessageFormModal(res?.message);
                }
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        if (state.currentCSLTId && deletedItem.id) {
            const res = await TTLT_DangKyLuuTruService.delete(state.currentCSLTId, deletedItem.id);

            if (res?.isSuccess) {
                FilterFn(currentPageIndex);
            }

            hideConfirmationModal();
            setMessage(res.message);
        }
    }

    return (
        <>
            <ContentHeader title="Danh sách đăng ký lưu trú" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "100px" }}>Tình trạng</th>
                                                <th>Họ và tên</th>
                                                <th>Ngày sinh</th>
                                                <th>Giới tính</th>
                                                <th>Quốc tịch</th>
                                                <th>Số giấy tờ</th>
                                                <th>Địa chỉ</th>
                                                <th>Phòng</th>
                                                <th>Thời gian/Lý do lưu trú</th>
                                                <th style={{ textAlign: "center", width: "80px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{UIUtils.getApprovalBadge(item.trangThai, false)}</td>
                                                        <td>{item.hoVaTen}</td>
                                                        <td>{Utils.combineBirthday(item.ngaySinh, item.thangSinh, item.namSinh)}</td>
                                                        <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                        <td>{item.quocTich?.ten || ""}</td>
                                                        <td>{item.loaiGiayTo?.ten || ""}: {item.soGiayTo}</td>
                                                        <td>{[item.diaChi, item.phuongXa?.ten, item.quanHuyen?.ten, item.tinhThanh?.ten].filter(x => x != null).join(", ")}</td>
                                                        <td>{item.phong?.ten || ""}</td>
                                                        <td>
                                                            {Utils.dateToString(item.thoiGianDen, Configs.DATE_TIME_FORMAT)} - {Utils.dateToString(item.thoiGianDi, Configs.DATE_TIME_FORMAT)}
                                                            {item.lyDoLuuTru?.ten && <p>{item.lyDoLuuTru.ten}</p>}
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {item.trangThai != ApprovalStatus.APPROVED &&
                                                                <ButtonGroup>
                                                                    <Button variant="warning" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                    <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id || 0, item.hoVaTen)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                                </ButtonGroup>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{formData.hoVaTen}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(formData.trangThai || 0) > 0 && <Alert variant={formData.trangThai == ApprovalStatus.APPROVED ? "success" : "danger"}>
                        <Alert.Heading>{Utils.getApprovalName(formData.trangThai, false)}</Alert.Heading>
                        {Utils.splitNL2Array(formData.ghiChu).map((p, index) => <div key={index}>{p}</div>)}
                    </Alert>}
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="hoVaTen">
                            <Form.Label>Tên khách</Form.Label>
                            <Form.Control type="text" name="hoVaTen" required value={formData.hoVaTen} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Tên khách.</Form.Control.Feedback>
                        </Form.Group>
                        <DateInput nameOfDay="ngaySinh" valueOfDay={formData.ngaySinh} nameOfMonth="thangSinh" valueOfMonth={formData.thangSinh} nameOfYear="namSinh" valueOfYear={formData.namSinh} required={true} onChange={handleFormControlChange}></DateInput>
                        <Row>
                            <Col sm={12} md={6}>
                                <GenderSelect name="gioiTinh" value={formData.gioiTinh} required={true} onChange={handleFormSelectControlChange}></GenderSelect>
                            </Col>
                            <Col sm={12} md={6}>
                                <CategorySelect dataType="QuocTich" name="quocTichId" required={true} value={formData.quocTichId} onChange={handleFormSelectControlChange}></CategorySelect>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <CategorySelect dataType="LoaiGiayTo" name="loaiGiayToId" required={true} value={formData.loaiGiayToId} onChange={handleFormSelectControlChange}></CategorySelect>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="soGiayTo">
                                    <Form.Label>Số giấy tờ</Form.Label>
                                    <Form.Control type="text" name="soGiayTo" required value={formData.soGiayTo} onChange={(e) => handleFormControlChange(e as any)} />
                                    <Form.Control.Feedback type="invalid">Nhập Số giấy tờ.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <AdministrativeDivisionSelect tinhThanhId={formData.tinhThanhId} quanHuyenId={formData.quanHuyenId} phuongXaId={formData.phuongXaId} handleSelectOptionChange={(e) => handleFormSelectControlChange(e as any)} column={true} required={true}></AdministrativeDivisionSelect>
                        <Form.Group className="mb-3" controlId="diaChi">
                            <Form.Label>Địa chỉ</Form.Label>
                            <Form.Control type="text" name="diaChi" required value={formData.diaChi || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập Địa chỉ.</Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="thoiGianDen">
                                    <Form.Label>Thời gian đến</Form.Label>
                                    <Form.Control type="datetime-local" name="thoiGianDen" required value={Utils.dateToString(formData.thoiGianDen, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                    <Form.Control.Feedback type="invalid">Nhập Thời gian đến.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="thoiGianDi">
                                    <Form.Label>Thời gian đi</Form.Label>
                                    <Form.Control type="datetime-local" name="thoiGianDi" required value={Utils.dateToString(formData.thoiGianDi, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <CategorySelect dataType="LyDoLuuTru" name="lyDoLuuTruId" required={true} value={formData.lyDoLuuTruId} onChange={handleFormSelectControlChange}></CategorySelect>
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Lưu</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />
        </>
    );
}

export default DanhSachDangKyLuuTru;