import { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AdministrativeDivisionService, { IAdministrativeDivision } from "../services/AdministrativeDivisionService";

interface IAdministrativeDivisionSelectProps {
    tinhThanhId: number | null;
    quanHuyenId: number | null;
    phuongXaId: number | null;
    handleSelectOptionChange: (e: any) => void;
    column?: boolean;
    required?: boolean;
}

function AdministrativeDivisionSelect(props: IAdministrativeDivisionSelectProps) {
    const [tinhThanhOptions, setTinhThanhOptions] = useState([] as IAdministrativeDivision[]);
    const [quanHuyenOptions, setQuanHuyenOptions] = useState([] as IAdministrativeDivision[]);
    const [phuongXaOptions, setPhuongXaOptions] = useState([] as IAdministrativeDivision[]);

    const tinhThanhIdRef = useRef<HTMLSelectElement>(null);
    const quanHuyenIdRef = useRef<HTMLSelectElement>(null);
    const phuongXaIdRef = useRef<HTMLSelectElement>(null);

    // const isFirst = useIsFirstRender();
    // console.log("0", isFirst);

    useEffect(() => {
        // console.log("1", isFirst);
        GetOptions(null).then(options => {
            setTinhThanhOptions(options);
        });
    }, []);

    useEffect(() => {
        // console.log("2", isFirst);
        if (props.tinhThanhId) {
            GetOptions(props.tinhThanhId).then(options => {
                setQuanHuyenOptions(options);
            });
        } else {
            setQuanHuyenOptions([]);
        }

        setPhuongXaOptions([]);

        // if (!isFirst) {
        //     if (quanHuyenIdRef.current) {
        //         quanHuyenIdRef.current.value = "";
        //         quanHuyenIdRef.current.dispatchEvent(new Event("change", { bubbles: true }));
        //     }

        //     if (phuongXaIdRef.current) {
        //         phuongXaIdRef.current.value = "";
        //         phuongXaIdRef.current.dispatchEvent(new Event("change", { bubbles: true }));
        //     }
        // }
    }, [props.tinhThanhId]);

    useEffect(() => {
        // console.log("3", isFirst);
        if (props.quanHuyenId) {
            GetOptions(props.quanHuyenId).then(options => {
                setPhuongXaOptions(options);
            });
        } else {
            setPhuongXaOptions([]);
        }

        // if (!isFirst) {
        //     if (phuongXaIdRef.current) {
        //         phuongXaIdRef.current.value = "";
        //         phuongXaIdRef.current.dispatchEvent(new Event("change", { bubbles: true }));
        //     }
        // }
    }, [props.quanHuyenId]);

    const GetOptions = async (parentId: number | null) => {
        const res = await AdministrativeDivisionService.getChildren(parentId);
        let options: IAdministrativeDivision[] = [];

        if (res?.isSuccess) {
            options = res.data;
        } else {
            console.log(res?.message);
        }

        return options;
    }

    return (
        <>
            {props.column == true ? (
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="tinhThanhId">
                            <Form.Label>Tỉnh, Thành</Form.Label>
                            <Form.Select name="tinhThanhId" required={props.required} value={props.tinhThanhId || undefined} onChange={(e) => props.handleSelectOptionChange(e)} ref={tinhThanhIdRef}>
                                <option></option>
                                {tinhThanhOptions.map((item: IAdministrativeDivision) => <option key={item.id} value={item.id}>{item.ten}</option>)}
                            </Form.Select>
                            {props.required && <Form.Control.Feedback type="invalid">Chọn một Tỉnh, Thành.</Form.Control.Feedback>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="quanHuyenId">
                            <Form.Label>Huyện, Quận</Form.Label>
                            <Form.Select name="quanHuyenId" required={props.required} value={props.quanHuyenId || undefined} onChange={(e) => props.handleSelectOptionChange(e)} ref={quanHuyenIdRef}>
                                <option></option>
                                {quanHuyenOptions.map((item: IAdministrativeDivision) => <option key={item.id} value={item.id}>{item.ten}</option>)}
                            </Form.Select>
                            {props.required && <Form.Control.Feedback type="invalid">Chọn một Huyện, Quận.</Form.Control.Feedback>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="phuongXaId">
                            <Form.Label>Phường, Xã</Form.Label>
                            <Form.Select name="phuongXaId" required={props.required} value={props.phuongXaId || undefined} onChange={(e) => props.handleSelectOptionChange(e)} ref={phuongXaIdRef}>
                                <option></option>
                                {phuongXaOptions.map((item: IAdministrativeDivision) => <option key={item.id} value={item.id}>{item.ten}</option>)}
                            </Form.Select>
                            {props.required && <Form.Control.Feedback type="invalid">Chọn một Phường, Xã.</Form.Control.Feedback>}
                        </Form.Group>
                    </Col>
                </Row>
            ) : (
                <>
                    <Form.Group className="mb-3" controlId="tinhThanhId">
                        <Form.Label>Tỉnh, Thành</Form.Label>
                        <Form.Select name="tinhThanhId" required={props.required} value={props.tinhThanhId || undefined} onChange={(e) => props.handleSelectOptionChange(e)} ref={tinhThanhIdRef}>
                            <option></option>
                            {tinhThanhOptions.map((item: IAdministrativeDivision) => <option key={item.id} value={item.id}>{item.ten}</option>)}
                        </Form.Select>
                        {props.required && <Form.Control.Feedback type="invalid">Chọn một Tỉnh, Thành.</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="quanHuyenId">
                        <Form.Label>Huyện, Quận</Form.Label>
                        <Form.Select name="quanHuyenId" required={props.required} value={props.quanHuyenId || undefined} onChange={(e) => props.handleSelectOptionChange(e)} ref={quanHuyenIdRef}>
                            <option></option>
                            {quanHuyenOptions.map((item: IAdministrativeDivision) => <option key={item.id} value={item.id}>{item.ten}</option>)}
                        </Form.Select>
                        {props.required && <Form.Control.Feedback type="invalid">Chọn một Huyện, Quận.</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phuongXaId">
                        <Form.Label>Phường, Xã</Form.Label>
                        <Form.Select name="phuongXaId" required={props.required} value={props.phuongXaId || undefined} onChange={(e) => props.handleSelectOptionChange(e)} ref={phuongXaIdRef}>
                            <option></option>
                            {phuongXaOptions.map((item: IAdministrativeDivision) => <option key={item.id} value={item.id}>{item.ten}</option>)}
                        </Form.Select>
                        {props.required && <Form.Control.Feedback type="invalid">Chọn một Phường, Xã.</Form.Control.Feedback>}
                    </Form.Group>
                </>
            )}
        </>
    );
}

export default AdministrativeDivisionSelect;