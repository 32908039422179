import moment from 'moment';
import Configs from '../Configs';
import { ApprovalStatus } from '../constants/ApprovalStatus';
import { GenderTypes } from '../constants/GenderTypes';

class Utils {
    static getCurrentDate() {
        return moment().format("YYYY-MM-DDTHH:mm");
    }

    static getCurrentMonth() {
        return moment().format("YYYY-MM");
    }

    static dateToString(date?: Date | string | null, format?: string | null) {
        if (date) {
            return moment(date).format(format || Configs.DATE_FORMAT);
        } else {
            return "";
        }
    }

    static stringToDate(str?: string | null) {
        if (str && moment(str, "YYYY-MM-DD").isValid()) {
            return new Date(str);
        } else {
            return null;
        }
    }

    static combineDateAndTime(date?: Date | null, time?: string | null) {
        var timeParsed = moment(time || "00:00", "HH:mm");
        if (date && timeParsed.isValid()) {
            date.setHours(timeParsed.hour());
            date.setMinutes(timeParsed.minute());

            return date;
        } else {
            return null;
        }
    }

    static combineBirthday(ngaySinh?: number | null, thangSinh?: number | null, namSinh?: number | null) {
        return [ngaySinh, thangSinh, namSinh].filter(x => x != null).join("/");
    }

    static isValidDateRange(start?: string | null, end?: string | null) {
        if (start && end) {
            return moment(end) >= moment(start);
        } else {
            return true;
        }
    }

    static getGenderName(id: number | null) {
        return GenderTypes.find(x => x.id == id)?.name || "";
    }

    static getApprovalName(id: number | null, isApprover: boolean) {
        switch (id) {
            case ApprovalStatus.NONE:
                return isApprover ? "Chờ duyệt" : "Đã gửi";
            case ApprovalStatus.APPROVED:
                return "Đã duyệt";
            case ApprovalStatus.REJECTED:
                return "Từ chối";
            default:
                return "Chưa gửi";
        }
    }

    static formatNumber(num: number | null) {
        return num?.toLocaleString() || "";
    }

    static replaceNL2BR(str: string | null) {
        return str ? str.replace(/(?:\r\n|\r|\n)/g, '<br/>') : "";
    }

    static splitNL2Array(str: string | null) {
        return str ? str.split(/(?:\r\n|\r|\n)/) : [];
    }
}

export default Utils;