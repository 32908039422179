import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AdministrativeDivisionSelect from "../../components/AdministrativeDivisionSelect";
import CategorySelect from "../../components/CategorySelect";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import RoutePath from "../../constants/RoutePath";
import useAppContext from "../../hooks/useAppContext";
import TTLT_CoSoLuuTruService, { ITTLT_CoSoLuuTru } from "../../services/TTLT_CoSoLuuTruService";

function ThongTinCSLT() {
    const { state } = useAppContext();
    const navigate = useNavigate();

    const initFormData: ITTLT_CoSoLuuTru = {
        id: 0,
        ten: "",
        maSoThue: "",
        soLuongPhong: null,
        dienThoai: "",
        email: "",
        diaChi: "",
        tinhThanhId: null,
        quanHuyenId: null,
        phuongXaId: null,
        loaiHinhLuuTruId: null,
        hinhThucToChucId: null,
        loaiHinhLuuTru: null,
        createdOn: null,
        dangHoatDong: false,
        quanLyID: null,
        isLockedOut: false
    };

    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState(initFormData);
    const formRef = useRef<HTMLFormElement>(null);
    const [validatedForm, setValidatedForm] = useState(false);

    useEffect(() => {
        if (state.currentCSLTId) {
            loadData(state.currentCSLTId);
        }
    }, [state.currentCSLTId]);

    const loadData = async (id: number) => {
        const res = await TTLT_CoSoLuuTruService.getProfile(id);

        if (res?.isSuccess && res.data) {
            setFormData(res.data);
        } else {
            setFormData(initFormData);
            setMessage(res?.message);
            navigate(RoutePath.AUTH.UNAUTHORIZED, { replace: true });
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        var name = e.target.name;
        var value = e.target.value;

        if (name == "loaiHinhLuuTruId" || name == "hinhThucToChucId" || name == "soLuongPhong") {
            setFormData({ ...formData, [name]: value ? parseInt(value) : null });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (formRef.current?.checkValidity()) {
            try {
                const res = await TTLT_CoSoLuuTruService.updateProfile(formData.id, formData);
                setMessage(res?.message);
            } catch (error) {
                console.log(error);
            }
        } else {
            setValidatedForm(true);
        }
    }

    return (
        <>
            <ContentHeader title="Thông tin Cơ sở lưu trú" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                        <Row>
                                            <Col xs={12} md={5}>
                                                <Form.Group className="mb-3" controlId="ten">
                                                    <Form.Label>Tên cơ sở lưu trú</Form.Label>
                                                    <Form.Control type="text" name="ten" required value={formData.ten} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Tên cơ sở lưu trú.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3" controlId="maSoThue">
                                                    <Form.Label>Mã số thuế</Form.Label>
                                                    <Form.Control type="text" name="maSoThue" required value={formData.maSoThue} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Mã số thuế.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="soLuongPhong">
                                                    <Form.Label>Số lượng phòng</Form.Label>
                                                    <Form.Control type="number" min={0} name="soLuongPhong" required value={formData.soLuongPhong || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Số lượng phòng.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <CategorySelect dataType="LoaiHinhLuuTru" name="loaiHinhLuuTruId" required={true} value={formData.loaiHinhLuuTruId} onChange={handleFormControlChange}></CategorySelect>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <CategorySelect dataType="HinhThucToChuc" name="hinhThucToChucId" required={true} value={formData.hinhThucToChucId} onChange={handleFormControlChange}></CategorySelect>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="dienThoai">
                                                    <Form.Label>Điện thoại</Form.Label>
                                                    <Form.Control type="text" name="dienThoai" required value={formData.dienThoai} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Điện thoại.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" name="email" required value={formData.email} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Email.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <Form.Group className="mb-3" controlId="diaChi">
                                                    <Form.Label>Địa chỉ</Form.Label>
                                                    <Form.Control type="text" name="diaChi" required value={formData.diaChi} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Nhập Địa chỉ.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <AdministrativeDivisionSelect tinhThanhId={formData.tinhThanhId} quanHuyenId={formData.quanHuyenId} phuongXaId={formData.phuongXaId} handleSelectOptionChange={(e) => handleFormControlChange(e as any)} column={true} required={true}></AdministrativeDivisionSelect>
                                    </Form>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Lưu thông tin</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default ThongTinCSLT;