import { UserTypes } from "../constants/UserTypes";
import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ITTLT_CoSoLuuTru } from "./TTLT_CoSoLuuTruService";

export interface ITTLT_NoiNhan {
    coSoLuuTruId: number | null;
    coSoLuuTru: ITTLT_CoSoLuuTru | null;
    daDoc: boolean;
}

export interface ITTLT_ThongBao {
    id: number | null;
    tieuDe: string;
    noiDung: string;
    noiGui: string;
    thongBaoChung: boolean;
    createdOn: string | null;
    noiNhan: Array<ITTLT_NoiNhan>;
}

export default class TTLT_ThongBaoService {
    static async filterSentMessages(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ThongBao/ListSentMessages?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ThongBao/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ThongBao/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    // static async update(id: number, itemData: ITTLT_ThongBao) {
    //     try {
    //         const { data } = await axiosClient.put<ApiResponse>(`/api/ThongBao/${id}`, itemData);

    //         return data;
    //     } catch (error) {
    //         return axiosCustomError(error);
    //     }
    // }

    static async create(itemData: ITTLT_ThongBao) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/ThongBao/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filterReceivedMessages(coSoLuuTruId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ThongBao/ListReceivedMessages/${coSoLuuTruId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getReceivedMessageById(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ThongBao/GetReceivedMessageById/${coSoLuuTruId}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static getTenNoiGui(noiGui: string | null) {
        switch (noiGui) {
            case UserTypes.CA:
                return "Cơ quan công an";
            case UserTypes.THUE:
                return "Cơ quan thuế";
            case UserTypes.UBND:
                return "Ủy ban nhân dân";
            case UserTypes.ADMINISTRATOR:
                return "Quản trị";
                case UserTypes.CSLT:
                    return "Cơ sở lưu trú";
            default:
                return noiGui || "";
        }
    }
}