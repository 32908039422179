export const CategoryTypes = [
    { type: "QuocTich", descriptions: "Quốc tịch" },
    { type: "LoaiThiThuc", descriptions: "Loại thị thực" },
    { type: "LoaiKhach", descriptions: "Loại khách" },
    { type: "LoaiGiayTo", descriptions: "Loại giấy tờ" },
    { type: "LyDoLuuTru", descriptions: "Lý do lưu trú" },
    { type: "LoaiHinhLuuTru", descriptions: "Loại hình lưu trú" },
    { type: "HinhThucToChuc", descriptions: "Hình thức tổ chức" },
    { type: "HinhThucThanhToan", descriptions: "Hình thức thanh toán" },
    { type: "TienTe", descriptions: "Tiền tệ" }
];