import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ContentHeader({ title = "" }) {
    return (
        <section className="content-header">
            <Container fluid>
                <Row className="mb-2">
                    <Col sm={12}>
                        <h1>{title}</h1>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContentHeader;