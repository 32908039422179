import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AccountService from "../services/AccountService";
import RoutePath from "../constants/RoutePath";

function RecoverPassword() {
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [searchParams] = useSearchParams();
    const email = searchParams.get("email") || "";
    const token = searchParams.get("token") || "";

    const passwordRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        passwordRef.current?.focus();
    }, []);

    useEffect(() => {
        setMessage('You are only one step a way from your new password, recover your password now.');
    }, [password, confirmPassword]);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            const res = await AccountService.resetPassword(password, confirmPassword, email, token);

            if (res?.isSuccess) {
                setMessage(res.message);
            } else {
                setMessage(res?.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="login-box">
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">{message}</p>
                    <form className="mt-4" onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" autoComplete="off" placeholder="Password" ref={passwordRef} onChange={(e) => { setPassword(e.target.value) }} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" autoComplete="off" placeholder="Confirm Password" onChange={(e) => { setConfirmPassword(e.target.value) }} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block">Change password</button>
                            </div>
                        </div>
                    </form>
                    <p className="mt-3 mb-1">
                        <Link to={RoutePath.AUTH.LOGIN}>Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default RecoverPassword;