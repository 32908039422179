import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import RoutePath from "../constants/RoutePath";

type RequireAuthProps = {
    allowedPermissions: string[] | null
}

function RequireAuth({ allowedPermissions }: RequireAuthProps) {
    const { user } = useAuthContext();
    const location = useLocation();

    if (!user) {
        let redirectTo = [RoutePath.AUTH.LOGIN, "?redirectTo=", location.pathname, location.search].join("");

        return (
            <Navigate to={redirectTo} state={{ from: location }} replace />
        );
    }

    return (
        !allowedPermissions?.length || user.permissions?.find(permission => allowedPermissions?.includes(permission))
            ? <Outlet />
            : <Navigate to={RoutePath.AUTH.UNAUTHORIZED} state={{ from: location }} replace />
    );
}

export default RequireAuth;