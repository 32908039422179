import format from "date-fns/format";
import { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface IDateRangePickerProps {
    startDate?: Date;
    endDate?: Date;
    closeOnSelected?: boolean;
    onChange: (startDate?: Date, endDate?: Date) => void;
}

function DateRangePicker(props: IDateRangePickerProps) {
    const [open, setOpen] = useState(false);
    const refCalendar = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("mousedown", hideOnClickOutside, true);
    }, []);

    const hideOnEscape = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            setOpen(false);
        }
    }

    const hideOnClickOutside = (e: MouseEvent) => {
        if (refCalendar.current && e.target && !refCalendar.current.contains(e.target as Node)) {
            setOpen(false);
        }
    }

    return (
        <div className="calendarWrap">
            <InputGroup>
                <Form.Control
                    type="text"
                    readOnly
                    value={`${props.startDate ? format(props.startDate, "dd/MM/yyyy") : "dd/MM/yyyy"} - ${props.endDate ? format(props.endDate, "dd/MM/yyyy") : "dd/MM/yyyy"}`}
                    onClick={() => { setOpen(true) }}
                />
                <InputGroup.Text>
                    <i className="far fa-calendar-alt"></i>
                </InputGroup.Text>
            </InputGroup>
            {open && <div ref={refCalendar}>
                <DateRange
                    direction="horizontal"
                    className="calendarElement"
                    months={2}
                    ranges={[{
                        startDate: props.startDate || new Date(),
                        endDate: props.endDate || new Date(),
                        key: "selection"
                    }]}
                    moveRangeOnFirstSelection={false}
                    onChange={rangesByKey => {
                        props.onChange(rangesByKey.selection.startDate, rangesByKey.selection.endDate);

                        if (props.closeOnSelected && rangesByKey.selection.startDate != rangesByKey.selection.endDate) {
                            setOpen(false);
                        }
                    }}
                ></DateRange>
            </div>
            }
        </div >
    )
}

export default DateRangePicker;