import { IAdministrativeDivision } from "./AdministrativeDivisionService";
import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface IBorderGate {
    id: number;
    ten: string;
    cap: string;
    loai: string;
    tinhThanhId: number | null;
    quanHuyenId: number | null;
    phuongXaId: number | null;
    suDung: boolean;
    tinhThanh: IAdministrativeDivision | null;
    quanHuyen: IAdministrativeDivision | null;
    phuongXa: IAdministrativeDivision | null;
}

export default class BorderGateService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CuaKhau/All`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CuaKhau?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CuaKhau/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/CuaKhau/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, itemData: IBorderGate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/CuaKhau/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(itemData: IBorderGate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/CuaKhau/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeIsUsedItem(id: number, suDung: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/CuaKhau/ChangeIsUsedItem`, { id, suDung });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}