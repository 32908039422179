import { Button, Modal, Alert } from 'react-bootstrap';

interface DeleteConfirmationProps {
    id: number | string | null;
    name: string;
    showModal: boolean;
    hideModal: () => void;
    confirmModal: (id: number | string | null) => void;
}

function DeleteConfirmation(props: DeleteConfirmationProps) {
    return (
        <Modal show={props.showModal} onHide={props.hideModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Xác nhận xóa!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="danger">Bạn có chắc chắn muốn xóa mục: <b>{props.name}</b>?</Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.hideModal}>Không</Button>
                {props.id && <Button variant="danger" onClick={() => props.confirmModal(props.id)}>Có, Tôi muốn xóa</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmation;