import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ITTLT_LoaiPhong } from "./TTLT_LoaiPhongService";
import { ITTLT_Tang } from "./TTLT_TangService";

export interface ITTLT_Phong {
    id: number;
    ten: string;
    tangId: number | null;
    loaiPhongId: number | null;
    coSoLuuTruId: number;
    suDung: boolean;
    tang: ITTLT_Tang | null;
    loaiPhong: ITTLT_LoaiPhong | null;
    datPhongId: string | null;
    trangThai: number | null;
}

export default class TTLT_PhongService {
    static async listAll(coSoLuuTruId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Phong/All/${coSoLuuTruId}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async filter(coSoLuuTruId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Phong/${coSoLuuTruId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Phong/${coSoLuuTruId}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Phong/${coSoLuuTruId}/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, itemData: ITTLT_Phong) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Phong/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(itemData: ITTLT_Phong) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Phong/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeIsUsedItem(coSoLuuTruId: number, id: number, suDung: boolean) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Phong/ChangeIsUsedItem/${coSoLuuTruId}`, { id, suDung });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}