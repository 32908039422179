import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, Dropdown, DropdownButton, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { SelectInstance } from "react-select";
import AsyncSelect from "react-select/async";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import { IFormProps, IModalProps } from "../../constants/Common";
import RoutePath from "../../constants/RoutePath";
import { SelectOptionType } from "../../constants/SelectOptionType";
import useAppContext from "../../hooks/useAppContext";
import TTLT_DatPhongService, { CheckinStatus, ITTLT_DatPhong } from "../../services/TTLT_DatPhongService";
import TTLT_DichVuService, { ITTLT_DichVu } from "../../services/TTLT_DichVuService";
import { ITTLT_TienDichVu } from "../../services/TTLT_HoaDonService";
import TTLT_TienDichVuService, { ITTLT_HoaDonDichVu } from "../../services/TTLT_TienDichVuService";
import Utils from "../../utils/Utils";

function DanhSachDatPhong() {
    const { state } = useAppContext();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_DatPhong>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const [message, setMessage] = useState("");
    const [currentPageIndex, setCurrentPageIndex] = useState(1);

    const keywordRef = useRef<HTMLInputElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        if (state.currentCSLTId) {
            const res = await TTLT_DatPhongService.filter(state.currentCSLTId, pageIndex, pageSize, keyword);

            if (res?.isSuccess) {
                setTableData(res.data);
            } else {
                console.log(res?.message);
            }
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, [state.currentCSLTId]);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const handleAddNew = () => {
        navigate(RoutePath.CSLT.DAT_PHONG, { replace: true });
    }

    //Delete
    const [deletedItem, setDeletedItem] = useState({
        id: "",
        ten: ""
    });
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: "",
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: string, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        if (state.currentCSLTId) {
            const res = await TTLT_DatPhongService.delete(state.currentCSLTId, deletedItem.id);

            if (res?.isSuccess) {
                FilterFn(currentPageIndex);
            }

            hideConfirmationModal();
            setMessage(res.message);
        }
    }

    const CreateTrangThaiIcon = (trangThai: number | null) => {
        switch (trangThai) {
            case CheckinStatus.CheckedIn.value:
                return <>
                    <i className="fas fa-circle text-green"></i>
                    <small className="text-muted d-block">{CheckinStatus.CheckedIn.name}</small>
                </>
            case CheckinStatus.None.value:
                return <>
                    <i className="fas fa-circle text-warning"></i>
                    <small className="text-muted d-block">{CheckinStatus.None.name}</small>
                </>
            case CheckinStatus.CheckedOut.value:
                return <>
                    <i className="fas fa-circle text-secondary"></i>
                    <small className="text-muted d-block">{CheckinStatus.CheckedOut.name}</small>
                </>

        }
        return <></>
    }

    //Dich Vu
    const initFormData: ITTLT_HoaDonDichVu = {
        id: null,
        coSoLuuTruId: state.currentCSLTId,
        datPhongId: "",
        tienDichVu: []
    }

    const [formData, setFormData] = useState(initFormData);
    const [servicesModal, setServicesModal] = useState({
        title: "",
        show: false,
        onHide: () => {
            setServicesModal({ ...servicesModal, show: false });
        },
        onShow: async (datPhongId: string, tenPhong: string) => {
            if (state.currentCSLTId) {
                const res = await TTLT_TienDichVuService.GetByDatPhongId(state.currentCSLTId, datPhongId);

                formData.datPhongId = datPhongId;
                if (res?.isSuccess && res?.data) {
                    formData.id = res.data.id;
                    formData.coSoLuuTruId = res.data.coSoLuuTruId;
                    formData.datPhongId = res.data.datPhongId;

                    formData.tienDichVu = [];
                    res.data.tienDichVu.forEach((item: ITTLT_TienDichVu) => {
                        formData.tienDichVu.push({
                            dichVuId: item.dichVuId,
                            soLuong: item.soLuong,
                            donGia: item.donGia,
                            ghiChu: item.ghiChu,
                            dichVu: item.dichVu
                        } as ITTLT_TienDichVu);
                    });

                    setFormData(formData);
                }

                setServicesModal({ ...servicesModal, show: true, title: `Phòng ${tenPhong}` });
            }
        }
    } as IModalProps);

    const [servicesForm, setServicesForm] = useState({
        ref: useRef<HTMLFormElement>(null),
        validated: false,
        message: "",
        onHideValidated: () => {
            setServicesForm({ ...servicesForm, validated: false });
        },
        onShowValidated: () => {
            setServicesForm({ ...servicesForm, validated: true });
        },
        onHideMessage: () => {
            setServicesForm({ ...servicesForm, message: "" });
        },
        onShowMessage: (message: string) => {
            setServicesForm({ ...servicesForm, message: message });
        }
    } as IFormProps);

    //AsyncSelect
    const [selectedOption, setSelectedOption] = useState(null as SelectOptionType | null);
    const asyncRef = useRef<SelectInstance<SelectOptionType> | null>(null);

    const handleSelectChange = async (option: SelectOptionType | null) => {
        setSelectedOption(option);

        if (state.currentCSLTId && option?.value) {
            let value = option?.value as number;

            if (formData.tienDichVu.filter(x => x.dichVuId == value).length == 0) {
                var res = await TTLT_DichVuService.getByUsedId(state.currentCSLTId, value);

                if (res.isSuccess) {
                    let item: ITTLT_TienDichVu = {
                        dichVuId: res.data.id,
                        hoaDonId: null,
                        soLuong: 1,
                        donGia: res.data.donGia,
                        ghiChu: "",
                        dichVu: res.data
                    };

                    formData.tienDichVu.push(item);

                    setFormData(formData);

                    setSelectedOption(null);
                    asyncRef.current?.focus();
                }
            }
        }
    }

    const filterDichVu = async (inputValue: string) => {
        var options: SelectOptionType[] = [];

        if (state?.currentCSLTId) {
            var res = await TTLT_DichVuService.filter(state.currentCSLTId, inputValue);

            if (res.isSuccess && res.data) {
                options = res.data.map((item: ITTLT_DichVu) => {
                    return { value: item.id, label: item.ten }
                });
            }
        }

        return options;
    };

    const loadDichVuOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterDichVu(inputValue));
    });

    const handleTableDichVuFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, dichVuId: number | null) => {
        var name = e.target.name;
        var value = e.target.value;

        var item = formData.tienDichVu.find(x => x.dichVuId == dichVuId);
        if (item) {
            if (name == "soLuong" || name == "donGia") {
                setFormData({ ...formData, tienDichVu: formData.tienDichVu.map(x => x.dichVuId == dichVuId ? { ...x, [name]: value ? parseInt(value) : null } : x) });
            } else {
                setFormData({ ...formData, tienDichVu: formData.tienDichVu.map(x => x.dichVuId == dichVuId ? { ...x, [name]: value } : x) });
            }
        }
    }

    const handleRemoveDichVu = (dichVuId: number | null) => {
        setFormData({ ...formData, tienDichVu: formData.tienDichVu.filter(x => x.dichVuId != dichVuId) });
    }

    const handleSaveServices = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (servicesForm.ref.current?.checkValidity()) {
            if (formData.id) {
                const res = await TTLT_TienDichVuService.update(formData.id, formData);

                if (res?.isSuccess) {
                    servicesModal.onHide();
                } else {
                    servicesForm.onShowMessage(res?.message);
                }
            } else {
                const res = await TTLT_TienDichVuService.create(formData);

                if (res?.isSuccess) {
                    servicesModal.onHide();
                } else {
                    servicesForm.onShowMessage(res?.message);
                }
            }
        } else {
            servicesForm.onShowValidated();
        }
    }

    return (
        <>
            <ContentHeader title="Danh sách đặt phòng" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Button variant="info" size="sm" onClick={handleAddNew}>
                                        <i className="fas fa-plus"></i> Đặt phòng
                                    </Button>

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Tình trạng</th>
                                                <th>Phòng</th>
                                                <th>Thời gian nhận</th>
                                                <th>Số khách</th>
                                                <th>Khách đại diện</th>
                                                <th>Quốc tịch</th>
                                                <th>Mã đoàn</th>
                                                <th>Ghi chú</th>
                                                <th>Thời gian trả</th>
                                                <th style={{ textAlign: "center", width: "80px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td style={{ textAlign: "center" }}>{CreateTrangThaiIcon(item.trangThai)}</td>
                                                        <td>{item.phong?.ten}</td>
                                                        <td>
                                                            {Utils.dateToString(item.thoiGianNhan)}
                                                            <small className="text-muted d-block">{Utils.dateToString(item.thoiGianNhan, "HH:mm")}</small>
                                                        </td>
                                                        <td>
                                                            {(item.soKhachNguoiLon || 0) > 0 && <>
                                                                <i className="fas fa-user-tie text-muted ml-2"></i> {item.soKhachNguoiLon}
                                                            </>}
                                                            {(item.soKhachTreEm || 0) > 0 && <>
                                                                <i className="fas fa-child text-muted ml-2"></i> {item.soKhachTreEm}
                                                            </>}
                                                        </td>
                                                        <td>{item.khachNguoiLon?.length == 1 ? item.khachNguoiLon[0].hoVaTen : ""}</td>
                                                        <td>{item.khachNguoiLon?.length == 1 ? item.khachNguoiLon[0].quocTich?.ten : ""}</td>
                                                        <td>{item.maDoan}</td>
                                                        <td>{item.ghiChu}</td>
                                                        <td>
                                                            {Utils.dateToString(item.thoiGianTra)}
                                                            <small className="text-muted d-block">{Utils.dateToString(item.thoiGianTra, "HH:mm")}</small>
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <DropdownButton size="sm" align="end" variant="secondary" menuVariant="dark" title="Thao tác">
                                                                {item.trangThai == CheckinStatus.CheckedIn.value && <>
                                                                    <Dropdown.Item as={Button} className="btn-flat btn-secondary" onClick={() => servicesModal.onShow(item.id || "", item.phong?.ten || "")}>
                                                                        <i className="fas fa-list-alt text-muted"></i> Chi tiết dịch vụ
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item as={Link} to={`${RoutePath.CSLT.TRA_PHONG}/${item.id}`}>
                                                                        <i className="fas fa-sign-out-alt text-muted"></i> Trả phòng
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item as={Link} to={`${RoutePath.CSLT.CHUYEN_PHONG}/${item.id}`}>
                                                                        <i className="fas fa-exchange-alt text-muted"></i> Chuyển phòng
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                </>}
                                                                {item.trangThai != CheckinStatus.CheckedOut.value && <>
                                                                    <Dropdown.Item as={Link} to={`${RoutePath.CSLT.DAT_PHONG}/${item.id}`}>
                                                                        <i className="fas fa-edit text-muted"></i> <small>Sửa thông tin đặt phòng</small>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item as={Link} to={`${RoutePath.CSLT.DANG_KY_LUU_TRU}/${item.id}`}>
                                                                        <i className="fas fa-user-check text-muted"></i> Đăng ký lưu trú
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                </>}
                                                                <Dropdown.Item as={Button} className="btn-flat btn-danger" onClick={() => showDeleteConfirmation(item.id || "", item.phong?.ten || "")}>
                                                                    <i className="fas fa-trash-alt text-muted"></i> Xóa đặt phòng
                                                                </Dropdown.Item>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

            <Modal size="lg" show={servicesModal.show} onHide={servicesModal.onHide} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{servicesModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AsyncSelect className="mb-3" isClearable value={selectedOption} loadOptions={loadDichVuOptions} onChange={handleSelectChange} ref={asyncRef} placeholder="Nhập tên dịch vụ để tìm kiếm." noOptionsMessage={({ inputValue }) => !inputValue ? "Nhập tên dịch vụ để tìm kiếm." : "Không tìm thấy dịch vụ nào!"} />
                    <Form noValidate validated={servicesForm.validated} ref={servicesForm.ref}>
                        <Table hover bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Tên dịch vụ</th>
                                    <th style={{ width: "100px" }}>Số lượng</th>
                                    <th style={{ width: "120px" }}>Đơn giá</th>
                                    <th>Ghi chú</th>
                                    <th style={{ width: "40px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.tienDichVu.map(item =>
                                    <tr key={item.dichVuId}>
                                        <td>
                                            <b>{item.dichVu?.ten || ""}</b>
                                            <small className="text-muted d-block">{item.dichVu?.donVi || ""}</small>
                                        </td>
                                        <td>
                                            <Form.Control type="number" min={1} required name="soLuong" value={item.soLuong || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                        </td>
                                        <td>
                                            <Form.Control type="number" min={0} required name="donGia" value={item.donGia || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                        </td>
                                        <td>
                                            <Form.Control type="text" name="ghiChu" value={item.ghiChu || ""} onChange={(e) => handleTableDichVuFormControlChange(e as any, item.dichVuId)} />
                                        </td>
                                        <td>
                                            <Button variant="light" size="sm" onClick={() => handleRemoveDichVu(item.dichVuId)}><i className="fas fa-times"></i></Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Form>
                    {servicesForm.message != "" && <Alert variant="info" onClose={servicesForm.onHideMessage} dismissible>{servicesForm.message}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={servicesModal.onHide}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSaveServices}><i className="fas fa-save"></i> Lưu</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DanhSachDatPhong;