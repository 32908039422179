import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Badge, Button, Card, Col, Container, Form, InputGroup, Modal, Offcanvas, Row, Table } from "react-bootstrap";
import { SelectInstance } from "react-select";
import AsyncSelect from "react-select/async";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import TTLT_ThongBaoService, { ITTLT_NoiNhan, ITTLT_ThongBao } from "../../services/TTLT_ThongBaoService";
import Utils from "../../utils/Utils";
import { SelectOptionType } from "../../constants/SelectOptionType";
import TTLT_CoSoLuuTruService, { ITTLT_CoSoLuuTru } from "../../services/TTLT_CoSoLuuTruService";

function MessageOutbox() {
    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_ThongBao>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const initFormData = {
        id: null,
        tieuDe: "",
        noiDung: "",
        noiGui: "",
        thongBaoChung: false,
        createdOn: null,
        noiNhan: []
    } as ITTLT_ThongBao;

    const [formData, setFormData] = useState(initFormData);
    const [message, setMessage] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [validatedFormModal, setValidatedFormModal] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });

    const keywordRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        const res = await TTLT_ThongBaoService.filterSentMessages(pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, []);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    }

    const handleAddNew = () => {
        setFormData(initFormData);

        handleShowModal();
    }

    const handleSendMessage = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            const res = await TTLT_ThongBaoService.create(formData);

            if (res?.isSuccess) {
                FilterFn(1);
                handleCloseModal();
                setMessage(res.message);
            } else {
                setMessageFormModal(res?.message);
            }
        } else {
            setValidatedFormModal(true);
        }
    }

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        if (id) {
            setDeletedItem({
                id: id,
                ten: name
            });

            setDisplayConfirmationModal(true);
            setMessage("");
        }
    }

    const handleDelete = async () => {
        const res = await TTLT_ThongBaoService.delete(deletedItem.id);

        if (res?.isSuccess) {
            FilterFn(currentPageIndex);
        }

        hideConfirmationModal();
        setMessage(res.message);
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setValidatedFormModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const modalTitle = formData.id != null ? "Sửa thông báo" : "Gửi thông báo";

    //AsyncSelect
    const [selectedOption, setSelectedOption] = useState(null as SelectOptionType | null);
    const asyncRef = useRef<SelectInstance<SelectOptionType> | null>(null);

    const handleSelectChange = async (option: SelectOptionType | null) => {
        setSelectedOption(option);

        if (option?.value) {
            let value = option?.value as number;

            if (formData.noiNhan.filter(x => x.coSoLuuTruId == value).length == 0) {
                var res = await TTLT_CoSoLuuTruService.get(value);

                if (res.isSuccess) {
                    let item: ITTLT_NoiNhan = {
                        coSoLuuTruId: res.data.id,
                        coSoLuuTru: res.data,
                        daDoc: false
                    };

                    formData.noiNhan.push(item);

                    setFormData(formData);

                    setSelectedOption(null);
                    asyncRef.current?.focus();
                }
            }
        }
    }

    const filterCoSoLuuTru = async (inputValue: string) => {
        var options: SelectOptionType[] = [];

        var res = await TTLT_CoSoLuuTruService.search(inputValue);

        if (res.isSuccess && res.data) {
            options = res.data.map((item: ITTLT_CoSoLuuTru) => {
                return { value: item.id, label: item.ten }
            });
        }

        return options;
    };

    const loadDichVuOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterCoSoLuuTru(inputValue));
    });

    const handleRemoveCoSoLuuTru = (coSoLuuTruId: number | null) => {
        setFormData({ ...formData, noiNhan: formData.noiNhan.filter(x => x.coSoLuuTruId != coSoLuuTruId) });
    }

    // View Message
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    }
    const handleShowOffcanvas = () => {
        setShowOffcanvas(true);
    }

    const [thongBao, setThongBao] = useState({} as ITTLT_ThongBao);

    const handleView = async (id: number | null, e: SyntheticEvent) => {
        e.preventDefault();

        if (id) {
            var res = await TTLT_ThongBaoService.get(id);

            if (res.isSuccess) {
                setThongBao(res.data);

                handleShowOffcanvas();
            } else {
                console.log(res.message);
            }
        }
    }

    return (
        <>
            <ContentHeader title="Thông báo" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Button variant="info" size="sm" onClick={handleAddNew}>
                                        <i className="fas fa-plus"></i> Gửi thông báo
                                    </Button>

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Tiêu đề</th>
                                                <th style={{ textAlign: "center", width: "150px" }}>Loại thông báo</th>
                                                <th style={{ textAlign: "center", width: "140px" }}>Thời gian gửi</th>
                                                <th style={{ textAlign: "center", width: "90px" }}>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td onClick={(e) => handleView(item.id, e)}><a className="d-block">{item.tieuDe}</a></td>
                                                        <td>{item.thongBaoChung ? "Thông báo chung" : ""}</td>
                                                        <td>{Utils.dateToString(item.createdOn, Configs.DATE_TIME_FORMAT)}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <Button variant="default" size="sm" onClick={() => showDeleteConfirmation(item.id, item.tieuDe)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

            <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{thongBao.tieuDe}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p><i>{Utils.dateToString(thongBao.createdOn, Configs.DATE_TIME_FORMAT)}</i></p>
                    {Utils.splitNL2Array(thongBao.noiDung).map((str, index) => <p key={index}>{str}</p>)}
                    {!thongBao.thongBaoChung && <>
                        Nơi nhận: <br />
                        {thongBao?.noiNhan?.map(nn => <Badge bg="secondary" className="mr-2" key={nn.coSoLuuTruId}>{nn?.coSoLuuTru?.ten || ""}</Badge>)}
                    </>}
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="tieuDe">
                            <Form.Label>Tiêu đề</Form.Label>
                            <Form.Control type="text" name="tieuDe" required value={formData.tieuDe} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập tiêu đề.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="noiDung">
                            <Form.Label>Nội dung</Form.Label>
                            <Form.Control as="textarea" rows={5} name="noiDung" required value={formData.noiDung} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Nhập nội dung.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="thongBaoChung">
                            <Form.Check type="checkbox" label="Thông báo chung" name="thongBaoChung" checked={formData.thongBaoChung} onChange={(e) => handleCheckBoxChange(e as any)} />
                        </Form.Group>
                        {!formData.thongBaoChung &&
                            <Form.Group className="mb-3">
                                <Form.Label>Nơi nhận</Form.Label>
                                <AsyncSelect className="mb-3" isClearable value={selectedOption} loadOptions={loadDichVuOptions} onChange={handleSelectChange} ref={asyncRef} placeholder="Nhập tên cơ sở lưu trú để tìm kiếm." noOptionsMessage={({ inputValue }) => !inputValue ? "Nhập tên cơ sở lưu trú để tìm kiếm." : "Không tìm thấy cơ sở lưu trú nào!"} />
                                <Table hover bordered size="sm">
                                    <thead>
                                        <tr>
                                            <th>Tên cơ sở lưu trú</th>
                                            <th style={{ width: "40px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.noiNhan.map((item: ITTLT_NoiNhan) =>
                                            <tr key={item.coSoLuuTruId}>
                                                <td>
                                                    {item.coSoLuuTru?.ten || ""}
                                                </td>
                                                <td>
                                                    <Button variant="light" size="sm" onClick={() => handleRemoveCoSoLuuTru(item.coSoLuuTruId)}><i className="fas fa-times"></i></Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Form.Group>
                        }
                    </Form>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="info" onClick={handleSendMessage}><i className="fas fa-save"></i> Gửi thông báo</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MessageOutbox;