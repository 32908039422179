import format from "date-fns/format";
import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface ITTLT_CoSoLuuTruTheoLoaiHinh {
    loaiHinhLuuTruId: number | null;
    tenLoaiHinhLuuTru: string;
    soLuong: number;
}

export interface ITTLT_KhachDangKyTheoCoSoLuuTru {
    coSoLuuTruId: number | null;
    tenCoSoLuuTru: string;
    soLuong: number;
}

export interface ITTLT_PhanLoaiKhachTheoQuocTich {
    quocTichId: number | null;
    tenQuocTich: string;
    soLuong: number;
}

export interface ITTLT_KhachDangKyTheoNgay {
    ngay: string;
    soLuong: number;
}

export default class CA_ReportService {
    static async getCoSoLuuTruTheoLoaiHinh() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CAReport/CoSoLuuTruTheoLoaiHinh`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getKhachDangKyTheoCoSoLuuTru(startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CAReport/KhachDangKyTheoCoSoLuuTru?startDate=${format(startDate, "yyyy-MM-dd")}&endDate=${format(endDate, "yyyy-MM-dd")}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getPhanLoaiKhachTheoQuocTich(startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CAReport/PhanLoaiKhachTheoQuocTich?startDate=${format(startDate, "yyyy-MM-dd")}&endDate=${format(endDate, "yyyy-MM-dd")}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getKhachDangKyTheoNgay(startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CAReport/KhachDangKyTheoNgay?startDate=${format(startDate, "yyyy-MM-dd")}&endDate=${format(endDate, "yyyy-MM-dd")}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}