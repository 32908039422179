import { KeyboardEvent, RefObject, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import RoutePath from "../../constants/RoutePath";
import TTLT_CoSoLuuTruService, { ITTLT_CoSoLuuTru } from "../../services/TTLT_CoSoLuuTruService";
import Utils from "../../utils/Utils";

function DanhSachCoSoLuuTru() {
    const navigate = useNavigate();

    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_CoSoLuuTru>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const [message, setMessage] = useState("");
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    });

    const keywordRef = useRef<HTMLInputElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        const res = await TTLT_CoSoLuuTruService.filter(pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, []);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const handleDangHoatDongSwitch = async (id: number, dangHoatDong: boolean) => {
        const res = await TTLT_CoSoLuuTruService.changeIsActivedItem(id, dangHoatDong);

        if (res?.isSuccess) {
            FilterFn(currentPageIndex);
        } else {
            console.log(res?.message);
        }
    }

    const handleEdit = async (id: number) => {
        navigate(`${RoutePath.CA.CO_SO_LUU_TRU}/${id}`, { replace: true });
    }

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        });

        setDisplayConfirmationModal(false);
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        });

        setDisplayConfirmationModal(true);
        setMessage("");
    }

    const handleDelete = async () => {
        const res = await TTLT_CoSoLuuTruService.delete(deletedItem.id);

        if (res?.isSuccess) {
            FilterFn(currentPageIndex);
        }

        hideConfirmationModal();
        setMessage(res.message);
    }

    const handleLockoutManager = async (userId: number | null, isLockedOut: boolean) => {
        if (userId) {
            const res = await TTLT_CoSoLuuTruService.lockOrUnlockManager(userId, isLockedOut);

            if (res?.isSuccess) {
                //FilterFn(currentPageIndex);
            } else {
                console.log(res?.message);
            }
        }
    }

    return (
        <>
            <ContentHeader title="Danh sách cở sở lưu trú" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Tên cơ sở lưu trú</th>
                                                <th>Mã số thuế</th>
                                                <th>Điện thoại</th>
                                                <th>Địa chỉ</th>
                                                <th>Email</th>
                                                <th>Loại hình lưu trú</th>
                                                <th>Thời gian đăng ký</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Tình trạng hoạt động</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Tài khoản quản lý</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.ten}</td>
                                                        <td>{item.maSoThue}</td>
                                                        <td>{item.dienThoai}</td>
                                                        <td>{item.diaChi}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.loaiHinhLuuTru?.ten || ""}</td>
                                                        <td>{Utils.dateToString(item.createdOn, Configs.DATE_TIME_FORMAT)}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" checked={item.dangHoatDong} onChange={(e) => handleDangHoatDongSwitch(item.id, e.target.checked)} />
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" defaultChecked={!item.isLockedOut} onChange={(e) => handleLockoutManager(item.quanLyID, !e.target.checked)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <ButtonGroup>
                                                                <Button variant="warning" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.ten)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />
        </>
    );
}

export default DanhSachCoSoLuuTru;