import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import useAppContext from "../../hooks/useAppContext";
import CSLT_ReportService, { ITTLT_HoatDongLuuTru } from "../../services/CSLT_ReportService";
import Utils from "../../utils/Utils";

function ThongKeKhachLuuTru() {
    const { state } = useAppContext();
    const monthRef = useRef<HTMLInputElement>(null);

    const [hoatDongLuuTruData, setHoatDongLuuTruData] = useState([] as Array<ITTLT_HoatDongLuuTru>);

    const getHoatDongLuuTru = async () => {
        if (state.currentCSLTId && monthRef.current?.value) {
            let time = monthRef.current?.value.split("-");
            let year = parseInt(time[0].trim());
            let month = parseInt(time[1].trim());

            const res = await CSLT_ReportService.getHoatDongLuuTru(state.currentCSLTId, year, month);

            if (res?.isSuccess) {
                setHoatDongLuuTruData(res.data);
            } else {
                console.log(res?.message);
            }
        }
    }

    useEffect(() => {
        getHoatDongLuuTru();
    }, [state.currentCSLTId]);

    const handleReportKhachLuuTru = () => {
        getHoatDongLuuTru();
    }

    return (
        <>
            <ContentHeader title="Thống kê khách lưu trú" />
            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <Row className="d-flex align-items-end">
                                        <Col lg={4} md={6} sm={12}>
                                            <Form.Label>Chọn tháng</Form.Label>
                                            <Form.Control type="month" defaultValue={Utils.getCurrentMonth()} ref={monthRef} />
                                        </Col>
                                        <Col lg={8} md={6} sm={12}>
                                            <Button variant="info" className="mt-3" onClick={handleReportKhachLuuTru}><i className="fas fa-search"></i> Xem báo cáo</Button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Nội dung</th>
                                                <th>Đơn vị tính</th>
                                                <th style={{ textAlign: "right" }}>Số lượng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                hoatDongLuuTruData.map((item: ITTLT_HoatDongLuuTru, index) => {
                                                    return (
                                                        <tr key={index} className={`level-${item.cap}`}>
                                                            <td>{item.muc}. {item.noiDung}</td>
                                                            <td>{item.donViTinh}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.ketQua)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default ThongKeKhachLuuTru;