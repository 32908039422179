import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export default class PermissionService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Permissions/All");

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}