import axiosClient, { ApiResponse, axiosCustomError } from "./api";

export interface ITTLT_DoanhThuTheoPhong {
    tenPhong: string;
    tongTienPhong: number;
    tongTienDichVu: number;
}

export interface ITTLT_DoanhThuTheoDichVu {
    tenDichVu: string;
    donVi: string;
    donGia: number;
    soLuong: number;
}

export interface ITTLT_HoatDongLuuTru {
    muc: string;
    noiDung: string;
    donViTinh: string;
    ketQua: number | null;
    cap: number;
}

export default class CSLT_ReportService {
    static async getDoanhThuTheoPhong(coSoLuuTruId: number, year: number, month: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CSLTReport/DoanhThuTheoPhong/${coSoLuuTruId}/${year}/${month}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getDoanhThuTheoDichVu(coSoLuuTruId: number, year: number, month: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CSLTReport/DoanhThuTheoDichVu/${coSoLuuTruId}/${year}/${month}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getHoatDongLuuTru(coSoLuuTruId: number, year: number, month: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/CSLTReport/HoatDongLuuTru/${coSoLuuTruId}/${year}/${month}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}