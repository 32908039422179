export type AppStateType = {
    currentCSLTId: number | null;
    clientRequestsTotal: number;
};

class AppStateService {
    static save(state: AppStateType) {
        localStorage.setItem("appState", JSON.stringify(state));
    }

    static get() {
        const state = localStorage.getItem("appState");
        return state ? JSON.parse(state) as AppStateType : { 
            currentCSLTId: null ,
            clientRequestsTotal: 0
        } as AppStateType;
    }

    static remove() {
        localStorage.removeItem("appState");
    }
}

export default AppStateService;