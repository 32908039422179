import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import AdministrativeDivisionSelect from "../../components/AdministrativeDivisionSelect";
import CategorySelect from "../../components/CategorySelect";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import DateInput from "../../components/DateInput";
import DatePicker from "../../components/DatePicker";
import GenderSelect from "../../components/GenderSelect";
import RoutePath from "../../constants/RoutePath";
import useAppContext from "../../hooks/useAppContext";
import TTLT_DatPhongService, { CheckinStatus, ITTLT_DatPhong, ITTLT_KhachNguoiLon, ITTLT_KhachTreEm } from "../../services/TTLT_DatPhongService";
import TTLT_PhongService, { ITTLT_Phong } from "../../services/TTLT_PhongService";
import Utils from "../../utils/Utils";

function DatPhong() {
    const { state } = useAppContext();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const initKhachNguoiLon: ITTLT_KhachNguoiLon = {
        id: null,
        loaiKhachId: null,
        soGiayTo: "",
        loaiGiayToId: null,
        hoVaTen: "",
        ngaySinh: null,
        thangSinh: null,
        namSinh: null,
        gioiTinh: null,
        quocTichId: null,
        dienThoai: "",
        email: null,
        diaChi: "",
        tinhThanhId: null,
        quanHuyenId: null,
        phuongXaId: null,
        thoiGianDen: Utils.getCurrentDate(),
        thoiGianDi: null,
        lyDoLuuTruId: null,
        dangO: false,
        daiDien: false,
        nhanBaoLanh: false,
        quocTich: null,
        loaiGiayTo: null,
        lyDoLuuTru: null,
        chuyen: false
    }

    const initKhachTreEm: ITTLT_KhachTreEm = {
        id: null,
        loaiKhachId: null,
        hoVaTen: "",
        ngaySinh: null,
        gioiTinh: null,
        quocTichId: null,
        khachNguoiLonId: null,
        quocTich: null,
        chuyen: false
    }

    const initFormData: ITTLT_DatPhong = {
        id: null,
        phongId: null,
        thoiGianNhan: Utils.getCurrentDate(),
        thoiGianTra: null,
        giaThucTe: null,
        lyDoThayDoiGia: "",
        soGiuongThucTe: null,
        soKhachNguoiLon: null,
        soKhachTreEm: null,
        ghiChu: "",
        maDoan: "",
        thueTheoGio: false,
        coSoLuuTruId: state.currentCSLTId || 0,
        khachNguoiLon: [],
        khachTreEm: [],
        phong: null,
        trangThai: CheckinStatus.CheckedIn.value,
        huyPhongCu: false
    }

    const [khachNguoiLon, setKhachNguoiLon] = useState(initKhachNguoiLon);
    const [khachTreEm, setKhachTreEm] = useState(initKhachTreEm);
    const [formData, setFormData] = useState(initFormData);
    const [message, setMessage] = useState("");
    const [messageAdults, setMessageAdults] = useState("");
    const formRef = useRef<HTMLFormElement>(null);
    const formAdultsRef = useRef<HTMLFormElement>(null);
    const formChildrenRef = useRef<HTMLFormElement>(null);
    const [validatedForm, setValidatedForm] = useState(false);
    const [validatedAdultsForm, setValidatedAdultsForm] = useState(false);
    const [validatedChildrenForm, setValidatedChildrenForm] = useState(false);
    const [showAdultsForm, setShowAdultsForm] = useState(true);
    const [showChildrenForm, setShowChildrenForm] = useState(true);
    const [activeTabKey, setActiveTabKey] = useState("info" as string | undefined);
    const [phongData, setPhongData] = useState([] as ITTLT_Phong[]);

    const getPhong = async () => {
        if (state?.currentCSLTId) {
            const res = await TTLT_PhongService.listAll(state.currentCSLTId);

            if (res?.isSuccess && res.data) {
                setPhongData(res.data);

                const phongId = searchParams.get("phongId");
                if (phongId) {
                    var giaDangKy: number | null = null;

                    if (phongId) {
                        giaDangKy = res.data.find((x: ITTLT_Phong) => x.id == parseInt(phongId))?.loaiPhong?.giaDangKy || null;
                    }

                    setFormData({ ...formData, phongId: parseInt(phongId), giaThucTe: giaDangKy });
                }
            } else {
                setPhongData([]);
            }
        } else {
            setPhongData([]);
        }
    }

    const getDatPhong = async () => {
        if (state?.currentCSLTId && params.id) {
            const res = await TTLT_DatPhongService.get(state.currentCSLTId, params.id);

            if (res?.isSuccess) {
                setFormData(res.data);
            } else {
                setFormData(initFormData);
                navigate(RoutePath.CSLT.DAT_PHONG, { replace: true });
            }
        }
    }

    useEffect(() => {
        getPhong();

        getDatPhong();
    }, [state.currentCSLTId]);

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        var name = e.target.name;
        var value = e.target.value;

        if (name.startsWith("DP_")) {
            var key = name.replace("DP_", "");
            if (key == "phongId") {
                var phongId = value ? parseInt(value) : null;
                var giaDangKy: number | null = null;

                if (phongId) {
                    giaDangKy = phongData.find(x => x.id == parseInt(value))?.loaiPhong?.giaDangKy || null;
                }

                setFormData({ ...formData, phongId: phongId, giaThucTe: giaDangKy || null });
            } else if (key == "soGiuongThucTe") {
                setFormData({ ...formData, [key]: value ? parseInt(value) : null });
            } else if (key == "trangThai") {
                setFormData({ ...formData, [key]: value ? parseInt(value) : 1 });
            } else if (key == "giaThucTe") {
                setFormData({ ...formData, [key]: value ? parseFloat(value) : null });
            } else {
                setFormData({ ...formData, [key]: value });
            }
        }

        if (name.startsWith("NL_")) {
            var key = name.replace("NL_", "");

            if (key == "lyDoLuuTruId" || key == "loaiKhachId" || key == "loaiGiayToId" || key == "gioiTinh" || key == "ngaySinh" || key == "thangSinh" || key == "namSinh" || key == "khachNguoiLonId" || key == "quocTichId") {
                setKhachNguoiLon({ ...khachNguoiLon, [key]: value ? parseInt(value) : null });
            } else if (key == "email") {
                setKhachNguoiLon({ ...khachNguoiLon, [key]: value ? value : null });
            } else {
                setKhachNguoiLon({ ...khachNguoiLon, [key]: value });
            }
        }

        if (name == "tinhThanhId" || name == "quanHuyenId" || name == "phuongXaId") {
            setKhachNguoiLon({ ...khachNguoiLon, [name]: value ? parseInt(value) : null });
        }

        if (name.startsWith("TE_")) {
            var key = name.replace("TE_", "");

            if (key == "loaiKhachId" || key == "gioiTinh" || key == "quocTichId") {
                setKhachTreEm({ ...khachTreEm, [key]: value ? parseInt(value) : null });
            } else {
                setKhachTreEm({ ...khachTreEm, [key]: value });
            }
        }
    }

    const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        var name = e.target.name;
        var checked = e.target.checked;

        if (name.startsWith("DP_")) {
            var key = name.replace("DP_", "");
            setFormData({ ...formData, [key]: checked });
        }

        if (name.startsWith("NL_")) {
            var key = name.replace("NL_", "");
            setKhachNguoiLon({ ...khachNguoiLon, [key]: checked });
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (formRef.current?.checkValidity()) {
            if (Utils.isValidDateRange(formData.thoiGianNhan, formData.thoiGianTra)) {
                try {
                    if (formData.id) {
                        const res = await TTLT_DatPhongService.update(formData.id, formData);

                        if (res?.isSuccess) {
                            navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
                        } else {
                            setMessage(res?.message);
                        }
                    } else {
                        const res = await TTLT_DatPhongService.create({ ...formData, id: uuid() });

                        if (res?.isSuccess) {
                            navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true });
                        } else {
                            setMessage(res?.message);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                setMessage("Thời gian nhận và trả phòng không hợp lệ.");
                setActiveTabKey("info");
            }
        } else {
            setValidatedForm(true);
            setActiveTabKey("info");
        }
    }

    // Adults
    const handleDangOSwitch = (id: string | null, dangO: boolean) => {
        if (id) {
            var item = formData.khachNguoiLon.find(x => x.id == id);
            if (item) {
                setFormData({ ...formData, khachNguoiLon: formData.khachNguoiLon.map(x => x.id == id ? { ...x, dangO: dangO } : x) });
            }
        }
    }

    const handleSwitch = (id: string | null, name: string, value: boolean) => {
        if (id) {
            var item = formData.khachNguoiLon.find(x => x.id == id);
            if (item) {
                if (value) {
                    setFormData({ ...formData, khachNguoiLon: formData.khachNguoiLon.map(x => x.id == id ? { ...x, [name]: true } : { ...x, [name]: false }) });
                } else {
                    setFormData({ ...formData, khachNguoiLon: formData.khachNguoiLon.map(x => x.id == id ? { ...x, [name]: value } : x) });
                }
            }
        }
    }

    const handleAddAdults = (e: SyntheticEvent) => {
        e.preventDefault();

        setKhachNguoiLon(initKhachNguoiLon);
        setValidatedAdultsForm(false);
        setShowAdultsForm(true);
    }

    const handleEditAdults = (id: string | null) => {
        var item = formData.khachNguoiLon.find(x => x.id == id);

        if (item) {
            setKhachNguoiLon(item);
            setValidatedAdultsForm(false);
            setShowAdultsForm(true);
        }
    }

    const handleDeleteAdults = (id: string | null) => {
        var item = formData.khachNguoiLon.find(x => x.id == id);

        if (item) {
            formData.khachTreEm.forEach(te => {
                te.khachNguoiLonId = te.khachNguoiLonId == id ? null : te.khachNguoiLonId;
            });

            setFormData({ ...formData, khachNguoiLon: formData.khachNguoiLon.filter(x => x.id != id) });

            if (khachNguoiLon.id == id) {
                setKhachNguoiLon({ ...khachNguoiLon, id: null });
            }
        }
    }

    const handleCancelAdults = (e: SyntheticEvent) => {
        e.preventDefault();

        setKhachNguoiLon(initKhachNguoiLon);
        setValidatedAdultsForm(false);
        setShowAdultsForm(false);
    }

    const handleSaveAdults = (e: SyntheticEvent) => {
        e.preventDefault();
        if (formAdultsRef.current?.checkValidity()) {
            if (Utils.isValidDateRange(khachNguoiLon.thoiGianDen, khachNguoiLon.thoiGianDi)) {
                try {
                    formData.khachNguoiLon.forEach(item => {
                        item.daiDien = khachNguoiLon.daiDien ? false : item.daiDien;
                        item.nhanBaoLanh = khachNguoiLon.nhanBaoLanh ? false : item.nhanBaoLanh;
                    });

                    if (khachNguoiLon.id) {
                        setFormData({ ...formData, khachNguoiLon: formData.khachNguoiLon.map(x => x.id == khachNguoiLon.id ? { ...x, ...khachNguoiLon } : x) });
                    } else {
                        khachNguoiLon.id = uuid();
                        formData.khachNguoiLon.push(khachNguoiLon);
                        setFormData(formData);
                    }

                    setKhachNguoiLon(initKhachNguoiLon);
                    setValidatedAdultsForm(false);
                    setMessageAdults("");
                    setShowAdultsForm(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                setMessageAdults("Thời gian đến và đi không hợp lệ.");
            }
        } else {
            setValidatedAdultsForm(true);
        }
    }

    // Children
    const handleAddChildren = (e: SyntheticEvent) => {
        e.preventDefault();

        setKhachTreEm(initKhachTreEm);
        setValidatedChildrenForm(false);
        setShowChildrenForm(true);
    }

    const handleEditChildren = (id: string | null) => {
        var item = formData.khachTreEm.find(x => x.id == id);

        if (item) {
            setKhachTreEm(item);
            setValidatedChildrenForm(false);
            setShowChildrenForm(true);
        }
    }

    const handleDeleteChildren = (id: string | null) => {
        var item = formData.khachTreEm.find(x => x.id == id);

        if (item) {
            setFormData({ ...formData, khachTreEm: formData.khachTreEm.filter(x => x.id != id) });

            if (khachTreEm.id == id) {
                setKhachTreEm({ ...khachTreEm, id: null });
            }
        }
    }

    const handleCancelChildren = (e: SyntheticEvent) => {
        e.preventDefault();

        setKhachTreEm(initKhachTreEm);
        setValidatedChildrenForm(false);
        setShowChildrenForm(false);
    }

    const handleSaveChildren = (e: SyntheticEvent) => {
        e.preventDefault();
        if (formChildrenRef.current?.checkValidity()) {
            if (khachTreEm.id) {
                setFormData({ ...formData, khachTreEm: formData.khachTreEm.map(x => x.id == khachTreEm.id ? { ...x, ...khachTreEm } : x) });
            } else {
                khachTreEm.id = uuid();
                formData.khachTreEm.push(khachTreEm);
                setFormData(formData);
            }

            setKhachTreEm(initKhachTreEm);
            setValidatedChildrenForm(false);
            setShowChildrenForm(false);
        } else {
            setValidatedChildrenForm(true);
        }
    }

    return (
        <>
            <ContentHeader title="Đặt phòng" />
            
            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        activeKey={activeTabKey}
                                        onSelect={(k) => setActiveTabKey(k || undefined)}
                                    >
                                        <Tab eventKey="info" title={<><i className="fas fa-info-circle"></i> Thông tin đặt phòng</>} className="p-3">
                                            <Card>
                                                <Card.Body>
                                                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                                        <Row>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_phongId">
                                                                    <Form.Label>Phòng</Form.Label>
                                                                    <Form.Select name="DP_phongId" required value={formData.phongId || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                                                        <option value=""></option>
                                                                        {phongData.map(item => {
                                                                            return <option value={item.id} key={item.id}>{item.ten}</option>
                                                                        })}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">Chọn Phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_thoiGianNhan">
                                                                    <Form.Label>Thời gian nhận phòng</Form.Label>
                                                                    <Form.Control type="datetime-local" name="DP_thoiGianNhan" required value={Utils.dateToString(formData.thoiGianNhan, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Thời gian nhận phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_thoiGianTra">
                                                                    <Form.Label>Thời gian trả phòng</Form.Label>
                                                                    <Form.Control type="datetime-local" name="DP_thoiGianTra" required value={Utils.dateToString(formData.thoiGianTra, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Thời gian trả phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3} className="text-center">
                                                                <Form.Group className="mb-3" id="DP_thueTheoGio">
                                                                    <Form.Label>Thuê theo giờ</Form.Label>
                                                                    <Form.Switch className="py-2" name="DP_thueTheoGio" checked={formData.thueTheoGio} onChange={(e) => handleCheckBoxChange(e as any)}></Form.Switch>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={3}>
                                                                <Form.Group className="mb-3" controlId="DP_giaThucTe">
                                                                    <Form.Label>Giá phòng</Form.Label>
                                                                    <Form.Control type="number" min={0} name="DP_giaThucTe" required value={formData.giaThucTe || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Giá phòng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3" controlId="DP_lyDoThayDoiGia">
                                                                    <Form.Label>Lý do thay đổi giá</Form.Label>
                                                                    <Form.Control type="text" name="DP_lyDoThayDoiGia" value={formData.lyDoThayDoiGia} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={3}>
                                                                <Form.Group className="mb-3" controlId="DP_soGiuongThucTe">
                                                                    <Form.Label>Số giường thực tế</Form.Label>
                                                                    <Form.Control type="number" min={1} name="DP_soGiuongThucTe" value={formData.soGiuongThucTe || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={12} lg={6}>
                                                                <Form.Group className="mb-3" controlId="DP_ghiChu">
                                                                    <Form.Label>Ghi chú</Form.Label>
                                                                    <Form.Control type="text" as="textarea" name="DP_ghiChu" value={formData.ghiChu} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_maDoan">
                                                                    <Form.Label>Mã đoàn</Form.Label>
                                                                    <Form.Control type="text" name="DP_maDoan" value={formData.maDoan} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Text>Sử dụng để checkin/checkout theo đoàn.</Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={3}>
                                                                <Form.Group className="mb-3" controlId="DP_trangThai">
                                                                    <Form.Label>Tình trạng</Form.Label>
                                                                    <Form.Select name="DP_trangThai" required value={formData.trangThai || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                                                        {Object.entries(CheckinStatus).map(([key, value]) => <option value={value.value} key={key}>{value.name}</option>)}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">Chọn Tình trạng.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                        <Tab eventKey="adults" title={<><i className="fas fa-user-tie"></i> Khách người lớn ({formData.khachNguoiLon.length})</>} className="p-3">
                                            {showAdultsForm && <Card>
                                                <Card.Header>
                                                    <h5>{khachNguoiLon.id == null ? "Thêm thông tin khách" : "Sửa thông tin khách"}</h5>
                                                </Card.Header>
                                                <Card.Body>
                                                    {messageAdults != "" && <Alert variant="warning" onClose={() => setMessageAdults("")} dismissible>{messageAdults}</Alert>}
                                                    <Form noValidate validated={validatedAdultsForm} ref={formAdultsRef as RefObject<HTMLFormElement>}>
                                                        <Row>
                                                            <Col sm={12} md={4}>
                                                                <CategorySelect dataType="LoaiKhach" name="NL_loaiKhachId" required={true} value={khachNguoiLon.loaiKhachId} onChange={handleFormControlChange}></CategorySelect>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="NL_soGiayTo">
                                                                    <Form.Label>Số giấy tờ</Form.Label>
                                                                    <Form.Control type="text" name="NL_soGiayTo" required value={khachNguoiLon.soGiayTo} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Số giấy tờ.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <CategorySelect dataType="LoaiGiayTo" name="NL_loaiGiayToId" required={true} value={khachNguoiLon.loaiGiayToId} onChange={handleFormControlChange}></CategorySelect>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="NL_hoVaTen">
                                                                    <Form.Label>Tên khách</Form.Label>
                                                                    <Form.Control type="text" name="NL_hoVaTen" required value={khachNguoiLon.hoVaTen} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Tên khách.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={5}>
                                                                <DateInput nameOfDay="NL_ngaySinh" valueOfDay={khachNguoiLon.ngaySinh} nameOfMonth="NL_thangSinh" valueOfMonth={khachNguoiLon.thangSinh} nameOfYear="NL_namSinh" valueOfYear={khachNguoiLon.namSinh} required={true} onChange={handleFormControlChange}></DateInput>
                                                            </Col>
                                                            <Col sm={12} md={3}>
                                                                <GenderSelect name="NL_gioiTinh" value={khachNguoiLon.gioiTinh} required={true} onChange={handleFormControlChange}></GenderSelect>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={4}>
                                                                <CategorySelect dataType="QuocTich" name="NL_quocTichId" required={false} value={khachNguoiLon.quocTichId} onChange={handleFormControlChange}></CategorySelect>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="NL_dienThoai">
                                                                    <Form.Label>Điện thoại</Form.Label>
                                                                    <Form.Control type="text" name="NL_dienThoai" value={khachNguoiLon.dienThoai || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="NL_email">
                                                                    <Form.Label>Email</Form.Label>
                                                                    <Form.Control type="email" name="NL_email" value={khachNguoiLon.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Form.Group className="mb-3" controlId="NL_diaChi">
                                                            <Form.Label>Địa chỉ</Form.Label>
                                                            <Form.Control type="text" name="NL_diaChi" required value={khachNguoiLon.diaChi || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                            <Form.Control.Feedback type="invalid">Nhập Địa chỉ.</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <AdministrativeDivisionSelect tinhThanhId={khachNguoiLon.tinhThanhId} quanHuyenId={khachNguoiLon.quanHuyenId} phuongXaId={khachNguoiLon.phuongXaId} handleSelectOptionChange={(e) => handleFormControlChange(e as any)} column={true} required={true}></AdministrativeDivisionSelect>
                                                        <Row>
                                                            <Col sm={12} md={6} lg={4}>
                                                                <Form.Group className="mb-3" controlId="NL_thoiGianDen">
                                                                    <Form.Label>Thời gian đến</Form.Label>
                                                                    <Form.Control type="datetime-local" name="NL_thoiGianDen" required value={Utils.dateToString(khachNguoiLon.thoiGianDen, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Thời gian đến.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6} lg={4}>
                                                                <Form.Group className="mb-3" controlId="NL_thoiGianDi">
                                                                    <Form.Label>Thời gian đi</Form.Label>
                                                                    <Form.Control type="datetime-local" name="NL_thoiGianDi" value={Utils.dateToString(khachNguoiLon.thoiGianDi, "YYYY-MM-DDTHH:mm")} onChange={(e) => handleFormControlChange(e as any)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={12} lg={4}>
                                                                <CategorySelect dataType="LyDoLuuTru" name="NL_lyDoLuuTruId" required={false} value={khachNguoiLon.lyDoLuuTruId} onChange={handleFormControlChange}></CategorySelect>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Form.Switch inline id="NL_dangO" name="NL_dangO" label="Đang ở" checked={khachNguoiLon.dangO} onChange={(e) => handleCheckBoxChange(e as any)}></Form.Switch>
                                                                <Form.Switch inline id="NL_daiDien" name="NL_daiDien" label="Đại diện" checked={khachNguoiLon.daiDien} onChange={(e) => handleCheckBoxChange(e as any)}></Form.Switch>
                                                                <Form.Switch inline id="NL_nhanBaoLanh" name="NL_nhanBaoLanh" label="Nhận bảo lãnh" checked={khachNguoiLon.nhanBaoLanh} onChange={(e) => handleCheckBoxChange(e as any)}></Form.Switch>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                                <Card.Footer className="text-center">
                                                    <Button variant="outline-secondary" className="m-1" onClick={handleCancelAdults}><i className="fas fa-times"></i> Đóng</Button>
                                                    <Button variant="outline-info" className="m-1" onClick={handleSaveAdults}><i className="fas fa-save"></i> Cập nhật</Button>
                                                </Card.Footer>
                                            </Card>}

                                            {!showAdultsForm &&
                                                <Row>
                                                    <Col sm={12} className="text-center">
                                                        <Button variant="outline-info" className="m-1" onClick={handleAddAdults}><i className="fas fa-plus"></i> Thêm thông tin khách</Button>
                                                    </Col>
                                                </Row>
                                            }

                                            <Table bordered hover className="mt-3">
                                                <thead>
                                                    <tr>
                                                        <th>Tên khách</th>
                                                        <th>Ngày sinh</th>
                                                        <th>Giới tính</th>
                                                        <th>Số giấy tờ</th>
                                                        <th>Thời gian đến</th>
                                                        <th>Đang ở</th>
                                                        <th>Đại diện</th>
                                                        <th>Nhận bảo lãnh</th>
                                                        <th style={{ textAlign: "center", width: "80px" }}>Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        formData.khachNguoiLon.map((item: ITTLT_KhachNguoiLon) =>
                                                            <tr key={item.id}>
                                                                <td>{item.hoVaTen}</td>
                                                                <td>{Utils.combineBirthday(item.ngaySinh, item.thangSinh, item.namSinh)}</td>
                                                                <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                                <td>{item.soGiayTo}</td>
                                                                <td>{Utils.dateToString(item.thoiGianDen, "DD/MM/YYYY HH:mm")}</td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Check type="switch" name="dangO" checked={item.dangO} onChange={(e) => handleDangOSwitch(item.id, e.target.checked)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Check type="switch" name="daiDien" checked={item.daiDien} onChange={(e) => handleSwitch(item.id, e.target.name, e.target.checked)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Check type="switch" name="nhanBaoLanh" checked={item.nhanBaoLanh} onChange={(e) => handleSwitch(item.id, e.target.name, e.target.checked)} />
                                                                </td>
                                                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                                    <ButtonGroup>
                                                                        <Button variant="outline-warning" size="sm" onClick={() => handleEditAdults(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteAdults(item.id)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                                    </ButtonGroup>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </Tab>
                                        <Tab eventKey="children" title={<><i className="fas fa-child"></i> Khách trẻ em ({formData.khachTreEm.length})</>} className="p-3">
                                            {showChildrenForm && <Card>
                                                <Card.Header>
                                                    <h5>{khachTreEm.id == null ? "Thêm thông tin khách trẻ em" : "Sửa thông tin khách trẻ em"}</h5>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form noValidate validated={validatedChildrenForm} ref={formChildrenRef as RefObject<HTMLFormElement>}>
                                                        <Row>
                                                            <Col sm={12} md={4}>
                                                                <CategorySelect dataType="LoaiKhach" name="TE_loaiKhachId" required={true} value={khachTreEm.loaiKhachId} onChange={handleFormControlChange}></CategorySelect>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="TE_hoVaTen">
                                                                    <Form.Label>Tên trẻ em</Form.Label>
                                                                    <Form.Control type="text" name="TE_hoVaTen" required value={khachTreEm.hoVaTen} onChange={(e) => handleFormControlChange(e as any)} />
                                                                    <Form.Control.Feedback type="invalid">Nhập Tên trẻ em.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="TE_ngaySinh">
                                                                    <Form.Label>Ngày sinh</Form.Label>
                                                                    <DatePicker name="TE_ngaySinh" required={true} value={khachTreEm.ngaySinh} onChange={handleFormControlChange}></DatePicker>
                                                                    <Form.Control.Feedback type="invalid">Nhập Ngày sinh.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={4}>
                                                                <GenderSelect name="TE_gioiTinh" value={khachTreEm.gioiTinh} required={true} onChange={handleFormControlChange}></GenderSelect>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="TE_khachNguoiLonId">
                                                                    <Form.Label>Người lớn đi cùng</Form.Label>
                                                                    <Form.Select name="TE_khachNguoiLonId" value={khachTreEm.khachNguoiLonId || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                                                        <option value=""></option>
                                                                        {formData.khachNguoiLon.map(item => <option value={item.id || ""} key={item.id}>{item.hoVaTen}</option>)}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <CategorySelect dataType="QuocTich" name="TE_quocTichId" required={false} value={khachTreEm.quocTichId} onChange={handleFormControlChange}></CategorySelect>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                                <Card.Footer className="text-center">
                                                    <Button variant="outline-secondary" className="m-1" onClick={handleCancelChildren}><i className="fas fa-times"></i> Đóng</Button>
                                                    <Button variant="outline-info" className="m-1" onClick={handleSaveChildren}><i className="fas fa-save"></i> Cập nhật</Button>
                                                </Card.Footer>
                                            </Card>
                                            }

                                            {!showChildrenForm &&
                                                <Row>
                                                    <Col sm={12} className="text-center">
                                                        <Button variant="outline-info" className="m-1" onClick={handleAddChildren}><i className="fas fa-plus"></i> Thêm thông tin trẻ em</Button>
                                                    </Col>
                                                </Row>
                                            }

                                            <Table bordered hover className="mt-3">
                                                <thead>
                                                    <tr>
                                                        <th>Tên trẻ em</th>
                                                        <th>Ngày sinh</th>
                                                        <th>Giới tính</th>
                                                        <th>Người lớn đi cùng</th>
                                                        <th style={{ textAlign: "center", width: "80px" }}>Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        formData.khachTreEm.map((item: ITTLT_KhachTreEm) =>
                                                            <tr key={item.id}>
                                                                <td>{item.hoVaTen}</td>
                                                                <td>{Utils.dateToString(item.ngaySinh, "DD/MM/YYYY")}</td>
                                                                <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                                <td>{formData.khachNguoiLon.find(x => x.id == item.khachNguoiLonId)?.hoVaTen || ""}</td>
                                                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                                    <ButtonGroup>
                                                                        <Button variant="outline-warning" size="sm" onClick={() => handleEditChildren(item.id)}><i className="fas fa-pencil-alt"></i> Sửa</Button>
                                                                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteChildren(item.id)}><i className="fas fa-trash-alt"></i> Xóa</Button>
                                                                    </ButtonGroup>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </Tab>
                                    </Tabs>
                                    {message != "" && <Alert variant="warning" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="light" className="float-left" onClick={() => navigate(RoutePath.CSLT.DANH_SACH_DAT_PHONG, { replace: true })}><i className="fas fa-arrow-left"></i> Quay lại danh sách đặt phòng</Button>
                                    <Button variant="info" className="float-right" onClick={handleSave}><i className="fas fa-calendar-check"></i> Lưu đặt phòng</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    );
}

export default DatPhong;