import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BackgroundColors, BorderColors } from '../constants/ColorChart';
import { ChartProps } from './PieChart';

function BarChart(props: ChartProps) {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: props.datasets.length > 1 ? true : false,
                position: 'top' as const
            },
            title: {
                display: props.title ? true : false,
                text: props.title || ""
            }
        }
    };

    const data = {
        labels: props.labels,
        datasets: props.datasets.map((ds, index) => {
            const backgroundColor = BackgroundColors.slice(index, 1);
            const borderColor = BorderColors.slice(index, 1);

            return { ...ds, backgroundColor: backgroundColor, borderColor: borderColor, barThickness: 30 };
        }),
    };

    return (
        <Bar options={options} data={data} className="chartjs-box" />
    )
}

export default BarChart;