import Utils from "../utils/Utils";
import axiosClient, { ApiResponse, axiosCustomError } from "./api";
import { ICategory } from "./CategoryService";
import { ITTLT_DatPhong } from "./TTLT_DatPhongService";
import { ITTLT_DichVu } from "./TTLT_DichVuService";

export interface IPaymentDays {
    dateKey: string;
    dayName: string;
    dateName: string;
    timePeriod: string;
}

export interface ITTLT_TienPhong extends IPaymentDays {
    hoaDonId: number | null;
    soTien: number | null;
    ghiChu: string;
}

export interface ITTLT_TienDichVu {
    hoaDonId: number | null;
    dichVuId: number | null;
    soLuong: number | null;
    donGia: number | null;
    ghiChu: string;
    dichVu: ITTLT_DichVu | null;
}

export interface ITTLT_HoaDon {
    id: number | null;
    coSoLuuTruId: number | null;
    datPhongId: string;
    ngayHoaDon: string | null;
    tenKhachHang: string;
    tenDonVi: string;
    diaChi: string;
    soTaiKhoan: string;
    maSoThue: string;
    hinhThucThanhToanId: number | null;
    tienTeId: number | null;
    giamGiaPhanTram: number | null;
    giamGiaSoTien: number | null;
    ghiChu: string;
    tienTe: ICategory | null;
    tienPhong: ITTLT_TienPhong[];
    tienDichVu: ITTLT_TienDichVu[];
    tongTienPhong: number | null;
    tongTienDichVu: number | null;
    datPhong: ITTLT_DatPhong | null;
}

export default class TTLT_HoaDonService {

    static async filter(coSoLuuTruId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/HoaDon/${coSoLuuTruId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/HoaDon/${coSoLuuTruId}/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getByDatPhongId(coSoLuuTruId: number, datPhongId: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/HoaDon/GetByDatPhongId/${coSoLuuTruId}/${datPhongId}`);
            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(coSoLuuTruId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/HoaDon/${coSoLuuTruId}/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, itemData: ITTLT_HoaDon) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/HoaDon/${id}`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(itemData: ITTLT_HoaDon) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/HoaDon/Create`, itemData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static getPaymentDays(startDate?: string | null, endDate?: string | null) {
        const DaysOfWeek: string[] = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];
        const CheckInTime: string = "14:00";
        const CheckOutTime: string = "12:00";
        const CheckOutHours: number = parseInt(CheckOutTime.split(":")[0]);
        const CheckOutMinutes: number = parseInt(CheckOutTime.split(":")[1]);
        let paymentDays: IPaymentDays[] = [];

        if (startDate && endDate) {
            let start = new Date(startDate);
            let end = new Date(endDate);
            if (Utils.dateToString(end, "HH:mm") < CheckOutTime) {
                end.setHours(CheckOutHours, CheckOutMinutes, 0, 0);
            }

            if (Utils.dateToString(start, "HH:mm") < CheckInTime) {
                let priceDay: IPaymentDays = {
                    dateKey: "",
                    dayName: DaysOfWeek[start.getDay()],
                    dateName: Utils.dateToString(start, "DD/MM"),
                    timePeriod: Utils.dateToString(start, "HH:mm") + " - " + CheckInTime
                }

                start.setDate(start.getDate() - 1);

                priceDay.dateKey = Utils.dateToString(start, "YYYYMMDD");
                paymentDays.push(priceDay);

                start.setDate(start.getDate() + 1);
                start.setHours(CheckOutHours, CheckOutMinutes, 0, 0);
            }

            while (start < end) {
                let timePeriod = "";
                if (Utils.dateToString(start, "YYYY-MM-DD") == Utils.dateToString(end, "YYYY-MM-DD") && Utils.dateToString(end, "HH:mm") > CheckOutTime) {
                    timePeriod = CheckOutTime + " - " + Utils.dateToString(end, "HH:mm");
                }

                let priceDay: IPaymentDays = {
                    dateKey: Utils.dateToString(start, "YYYYMMDD"),
                    dayName: DaysOfWeek[start.getDay()],
                    dateName: Utils.dateToString(start, "DD/MM"),
                    timePeriod: timePeriod
                }

                paymentDays.push(priceDay);

                start.setDate(start.getDate() + 1);
            }
        }

        return paymentDays;
    }
}