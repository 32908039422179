import { ChangeEvent, KeyboardEvent, RefObject, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import ContentBody from "../../components/ContentBody";
import ContentHeader from "../../components/ContentHeader";
import Pagination from "../../components/Pagination";
import Configs from "../../Configs";
import { ApprovalStatus } from "../../constants/ApprovalStatus";
import TTLT_DangKyLuuTruService, { ITTLT_DangKyLuuTru, ITTLT_DuyetDangKyLuuTru } from "../../services/TTLT_DangKyLuuTruService";
import UIUtils from "../../utils/UIUtils";
import Utils from "../../utils/Utils";

function KhachLuuTru() {
    const [tableData, setTableData] = useState({
        data: new Array<ITTLT_DangKyLuuTru>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    });

    const [message, setMessage] = useState("");
    const [messageFormModal, setMessageFormModal] = useState("");
    const [currentPageIndex, setCurrentPageIndex] = useState(1);

    const keywordRef = useRef<HTMLInputElement>(null);

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE;
        let keyword = keywordRef.current?.value || "";

        const res = await TTLT_DangKyLuuTruService.filterForApproval(pageIndex, pageSize, keyword);

        if (res?.isSuccess) {
            setTableData(res.data);
        } else {
            console.log(res?.message);
        }

        setCurrentPageIndex(pageIndex);
    }

    useEffect(() => {
        keywordRef.current?.focus();

        FilterFn(1);
    }, []);

    const handleSearch = () => {
        FilterFn(1);
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            FilterFn(1);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber);
    }

    const [thongTinDangKy, setThongTinDangKy] = useState({} as ITTLT_DangKyLuuTru);
    const [formData, setFormData] = useState({} as ITTLT_DuyetDangKyLuuTru);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        setMessageFormModal("");
    }
    const handleShowModal = () => {
        setShowModal(true);
        setMessage("");
    }

    const handleView = async (id: number | null) => {
        if (id) {
            const res = await TTLT_DangKyLuuTruService.getForApproval(id);

            if (res?.isSuccess) {
                setFormData({
                    ...formData,
                    id: res.data.id,
                    trangThai: res.data.trangThai,
                    ghiChu: res.data.ghiChu
                });

                setThongTinDangKy(res.data);

                handleShowModal();
            } else {
                console.log(res?.message);
            }
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleApproval = async (trangThai: number) => {
        if (formData.id > 0) {
            const res = await TTLT_DangKyLuuTruService.approval(formData.id, {
                trangThai: trangThai,
                ghiChu: formData.ghiChu
            } as ITTLT_DuyetDangKyLuuTru);

            if (res?.isSuccess) {
                FilterFn(currentPageIndex);
                handleCloseModal();
                setMessage(res.message);
            } else {
                setMessageFormModal(res?.message);
            }
        }
    }

    return (
        <>
            <ContentHeader title="Đăng ký khách lưu trú" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Tìm kiếm"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message != "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "100px" }}>Tình trạng</th>
                                                <th>Họ và tên</th>
                                                <th>Ngày sinh</th>
                                                <th>Giới tính</th>
                                                <th>Quốc tịch</th>
                                                <th>Số giấy tờ</th>
                                                <th>Địa chỉ</th>
                                                <th>Cở sở lưu trú</th>
                                                <th>Thời gian/Lý do lưu trú</th>
                                                <th style={{ textAlign: "center", width: "80px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{UIUtils.getApprovalBadge(item.trangThai, true)}</td>
                                                        <td>{item.hoVaTen}</td>
                                                        <td>{Utils.combineBirthday(item.ngaySinh, item.thangSinh, item.namSinh)}</td>
                                                        <td>{Utils.getGenderName(item.gioiTinh)}</td>
                                                        <td>{item.quocTich?.ten || ""}</td>
                                                        <td>{item.loaiGiayTo?.ten || ""}: {item.soGiayTo}</td>
                                                        <td>{[item.diaChi, item.phuongXa?.ten, item.quanHuyen?.ten, item.tinhThanh?.ten].filter(x => x != null).join(", ")}</td>
                                                        <td>
                                                            {item.coSoLuuTru?.ten || ""} ({item.phong?.ten || ""})
                                                        </td>
                                                        <td>
                                                            {Utils.dateToString(item.thoiGianDen, Configs.DATE_TIME_FORMAT)} - {Utils.dateToString(item.thoiGianDi, Configs.DATE_TIME_FORMAT)}
                                                            {item.lyDoLuuTru?.ten && <p>{item.lyDoLuuTru.ten}</p>}
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <Button variant="warning" size="sm" onClick={() => handleView(item.id)}><i className="fas fa-check"></i> Xem và duyệt</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{thongTinDangKy.hoVaTen}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} className="mb-3">
                            <Form.Text>Tên khách</Form.Text>
                            <Form.Label>{thongTinDangKy.hoVaTen}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4} className="mb-3">
                            <Form.Text>Ngày sinh</Form.Text>
                            <Form.Label>{Utils.combineBirthday(thongTinDangKy.ngaySinh, thongTinDangKy.thangSinh, thongTinDangKy.namSinh)}</Form.Label>
                        </Col>
                        <Col sm={12} md={4} className="mb-3">
                            <Form.Text>Giới tính</Form.Text>
                            <Form.Label>{Utils.getGenderName(thongTinDangKy.gioiTinh)}</Form.Label>
                        </Col>
                        <Col sm={12} md={4} className="mb-3">
                            <Form.Text>Quốc tịch</Form.Text>
                            <Form.Label>{thongTinDangKy.quocTich?.ten || ""}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mb-3">
                            <Form.Text>Số giấy tờ</Form.Text>
                            <Form.Label>{thongTinDangKy.loaiGiayTo?.ten || ""}: {thongTinDangKy.soGiayTo}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mb-3">
                            <Form.Text>Địa chỉ</Form.Text>
                            <Form.Label>{[thongTinDangKy.diaChi, thongTinDangKy.phuongXa?.ten, thongTinDangKy.quanHuyen?.ten, thongTinDangKy.tinhThanh?.ten].filter(x => x != null).join(", ")}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mb-3">
                            <Form.Text>Cở sở lưu trú</Form.Text>
                            <Form.Label>{thongTinDangKy.coSoLuuTru?.ten || ""} ({thongTinDangKy.phong?.ten || ""})</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mb-3">
                            <Form.Text>Thời gian/Lý do lưu trú</Form.Text>
                            <Form.Label>{Utils.dateToString(thongTinDangKy.thoiGianDen, Configs.DATE_TIME_FORMAT)} - {Utils.dateToString(thongTinDangKy.thoiGianDi, Configs.DATE_TIME_FORMAT)}
                                {thongTinDangKy.lyDoLuuTru?.ten && <p>{thongTinDangKy.lyDoLuuTru.ten}</p>}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                        </Col>
                        <Col sm={12} md={6}>
                        </Col>
                    </Row>
                    <Form.Group controlId="ghiChu">
                        <Form.Text>Nội dung duyệt</Form.Text>
                        <Form.Control as="textarea" rows={3} name="ghiChu" value={formData.ghiChu} onChange={(e) => handleFormControlChange(e as any)} />
                    </Form.Group>
                    {messageFormModal != "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible className="mt-3">{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Đóng</Button>
                    <Button variant="danger" onClick={() => handleApproval(ApprovalStatus.REJECTED)}><i className="fas fa-times"></i> Từ chối duyệt</Button>
                    <Button variant="success" onClick={() => handleApproval(ApprovalStatus.APPROVED)}><i className="fas fa-check"></i> Đồng ý duyệt</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default KhachLuuTru;