import { Card, Container } from "react-bootstrap";

function DangKyThanhCong() {
    return (
        <Container>
            <Card bg="info" className="text-center">
                <Card.Header className="p-3">ĐĂNG KÝ THÀNH CÔNG</Card.Header>
                <Card.Body className="p-4">
                    <Card.Title className="mb-4" style={{ float: "initial" }}>Thông tin của bạn đã được đăng ký thành công!</Card.Title>
                    <Card.Text>
                        Lưu ý: tài khoản quản lý của bạn vẫn chưa được kích hoạt. Chúng tôi sẽ kiểm tra sự tin cậy của những thông tin mà bạn đã đăng ký.
                        <br/>Ngay sau khi tài khoản của bạn được kích hoạt, chúng tôi sẽ thông báo vào email bạn đã đăng ký.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default DangKyThanhCong;