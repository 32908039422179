import { Form } from "react-bootstrap";
import { GenderTypes } from "../constants/GenderTypes";

interface IGenderSelectProps {
    name: string;
    value: string | number | null;
    required?: boolean;
    onChange: (e: any) => void;
}

function GenderSelect(props: IGenderSelectProps) {

    return (
        <Form.Group className="mb-3" controlId={props.name}>
            <Form.Label>Giới tính</Form.Label>
            <Form.Select name={props.name} required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {GenderTypes.map(x => <option value={x.id} key={x.id}>{x.name}</option>)}
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Chọn giới tính.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default GenderSelect;