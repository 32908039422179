import { useState, useEffect, useRef, SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import AuthService from "../services/AuthService";
import RoutePath from "../constants/RoutePath";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const usernameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        usernameRef.current?.focus();
    }, []);

    useEffect(() => {
        setMessage('Sign in to start your session');
    }, [username, password]);

    const { onLogin } = useAuthContext();

    const handleLogin = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            const res = await AuthService.login(username, password);

            if (res?.isSuccess) {
                onLogin(res.data);
            } else {
                setMessage(res?.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="login-box">
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">{message}</p>

                    <form className="mb-4" onSubmit={handleLogin}>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Username" autoComplete="off" ref={usernameRef} value={username} onChange={(e) => setUsername(e.target.value)} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user"></span>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                            </div>
                        </div>
                    </form>
                    <p className="mb-1">
                        <Link to={RoutePath.AUTH.FORGOT_PASSWORD}>I forgot my password</Link>
                    </p>
                    <p className="mb-0">
                        <Link to={RoutePath.AUTH.REGISTER} className="text-center">Register a new membership</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Login;