import { Link, Outlet } from "react-router-dom";
import { Footer } from "../components/Footer";
import UserNavbar from "../components/UserNavbar";
import { useEffect } from "react";
import Logo from "../components/Logo";
import RoutePath from "../constants/RoutePath";

function TopNavLayout() {
    useEffect(() => {
        document.body.className = "hold-transition layout-top-nav";
    }, [])

    // const { user } = useAuthContext();

    // const TopLinks = () => {
    //     if (user?.userInfo.type === UserTypes.ADMINISTRATOR) {
    //         return (
    //             (
    //                 <li className="nav-item">
    //                     <Link to={RoutePath.ADMIN.HOME} className="nav-link">Admin</Link>
    //                 </li>
    //             )
    //         )
    //     }

    //     return <></>
    // }

    return (
        <div className="wrapper">
            <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
                <div className="container">

                    <Logo className="navbar-brand" />

                    <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to={RoutePath.CSLT.DANG_KY} className="nav-link"><i className="fas fa-edit"></i> Đăng ký thông tin cơ sở lưu trú</Link>
                            </li>
                            {/* {TopLinks()} */}
                        </ul>
                    </div>

                    <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                        <UserNavbar />
                    </div>
                </div>
            </nav>

            <div className="content-wrapper page-center">
                <Outlet />
            </div>

            <Footer />
        </div>
    );
}

export default TopNavLayout;