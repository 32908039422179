import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { BackgroundColors } from '../constants/ColorChart';

export interface DatasetChart {
    label: string;
    data: number[];
};

export type ChartProps = {
    title?: string;
    labels: string[];
    datasets: DatasetChart[]
};

function PieChart(props: ChartProps) {
    ChartJS.register(ArcElement, Tooltip, Legend);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top' as const
            },
            title: {
                display: props.title ? true : false,
                text: props.title || ""
            }
        }
    };

    const data = {
        labels: props.labels,
        datasets: props.datasets.map(ds => {
            const backgroundColor = BackgroundColors.slice(0, ds.data.length);

            return { ...ds, backgroundColor: backgroundColor, borderWidth: 1 };
        })
    };

    return (
        <Pie options={options} data={data} className="chartjs-box" />
    );
}

export default PieChart;