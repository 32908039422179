import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../context/AuthContext';
import TopNavLayout from '../layouts/TopNavLayout';
import SidebarLayout from '../layouts/SidebarLayout';
import ChangePassword from '../pages/ChangePassword';
import ForgotPassword from '../pages/ForgotPassword';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Profile from '../pages/Profile';
import RecoverPassword from '../pages/RecoverPassword';
import Register from '../pages/Register';
import RequireAuth from './RequireAuth';
import RoutePath from '../constants/RoutePath';
import Users from '../pages/admin/Users';
import Roles from '../pages/admin/Roles';
import Category from '../pages/admin/Category';
import AdministrativeDivisions from '../pages/admin/AdministrativeDivisions';
import Unauthorized from '../pages/Unauthorized';
import BorderGates from '../pages/admin/BorderGates';
import DangKy from '../pages/cslt/DangKy';
import DangKyThanhCong from '../pages/cslt/DangKyThanhCong';
import ThongTinDoanhNghiep from '../pages/cslt/ThongTinDoanhNghiep';
import ThongTinCSLT from '../pages/cslt/ThongTinCSLT';
import LoaiPhong from '../pages/cslt/LoaiPhong';
import Tang from '../pages/cslt/Tang';
import Phong from '../pages/cslt/Phong';
import DatPhong from '../pages/cslt/DatPhong';
import DanhSachDatPhong from '../pages/cslt/DanhSachDatPhong';
import TraPhong from '../pages/cslt/TraPhong';
import DichVu from '../pages/cslt/DichVu';
import ChuyenPhong from '../pages/cslt/ChuyenPhong';
import SoDoPhong from '../pages/cslt/SoDoPhong';
import MessageInbox from '../pages/cslt/MessageInbox';
import NguoiDung from '../pages/cslt/NguoiDung';
import TongQuan from '../pages/ca/TongQuan';
import KhachLuuTru from '../pages/ca/KhachLuuTru';
import CoSoLuuTru from '../pages/ca/CoSoLuuTru';
import DoiTuongNguyHiem from '../pages/ca/DoiTuongNguyHiem';
import MessageOutbox from '../pages/ca/MessageOutbox';
import BaoCao from '../pages/ca/BaoCao';
import ThongKeKhachLuuTru from '../pages/cslt/ThongKeKhachLuuTru';
import BaoCaoDoanhThu from '../pages/cslt/BaoCaoDoanhThu';
import DanhSachHoaDon from '../pages/cslt/DanhSachHoaDon';
import HoaDon from '../pages/cslt/HoaDon';
import DanhSachCoSoLuuTru from '../pages/ca/DanhSachCoSoLuuTru';
import DanhSachDoiTuongNguyHiem from '../pages/cslt/DanhSachDoiTuongNguyHiem';
import DangKyLuuTru from '../pages/cslt/DangKyLuuTru';
import DanhSachDangKyLuuTru from '../pages/cslt/DanhSachDangKyLuuTru';

function AppRoutes() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<TopNavLayout />}>
                        <Route path={RoutePath.AUTH.LOGIN} element={<Login />} />
                        <Route path={RoutePath.AUTH.REGISTER} element={<Register />} />
                        <Route path={RoutePath.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />
                        <Route path={RoutePath.AUTH.RECOVER_PASSWORD} element={<RecoverPassword />} />

                        <Route element={<RequireAuth allowedPermissions={[]} />}>
                            <Route path={RoutePath.HOME} element={<Home />} />
                            <Route path={RoutePath.AUTH.PROFILE} element={<Profile />} />
                            <Route path={RoutePath.AUTH.CHANGE_PASSWORD} element={<ChangePassword />} />
                        </Route>

                        <Route path={RoutePath.CSLT.DANG_KY} element={<DangKy />} />
                        <Route path={RoutePath.CSLT.DANG_KY_THANH_CONG} element={<DangKyThanhCong />} />

                        <Route path={RoutePath.AUTH.UNAUTHORIZED} element={<Unauthorized />} />
                        <Route path={RoutePath.PAGE404} element={<Page404 />} />
                        <Route path="*" element={<Page404 />} />
                    </Route>

                    <Route element={<SidebarLayout />}>
                        <Route element={<RequireAuth allowedPermissions={[]} />}>
                            <Route path={RoutePath.ADMIN.USERS} element={<Users />} />
                            <Route path={RoutePath.ADMIN.ROLES} element={<Roles />} />
                            <Route path={RoutePath.ADMIN.CATEGORY + ":type"} element={<Category />} />
                            <Route path={RoutePath.ADMIN.ADMINISTRATIVE_DIVISIONS} element={<AdministrativeDivisions />} />
                            <Route path={RoutePath.ADMIN.BORDER_GATES} element={<BorderGates />} />

                            <Route path={RoutePath.CSLT.THONG_TIN_DOANH_NGHIEP} element={<ThongTinDoanhNghiep />} />
                            <Route path={RoutePath.CSLT.THONG_TIN_CSLT} element={<ThongTinCSLT />} />
                            <Route path={RoutePath.CSLT.TANG} element={<Tang />} />
                            <Route path={RoutePath.CSLT.LOAI_PHONG} element={<LoaiPhong />} />
                            <Route path={RoutePath.CSLT.PHONG} element={<Phong />} />
                            <Route path={RoutePath.CSLT.DICH_VU} element={<DichVu />} />
                            <Route path={RoutePath.CSLT.DAT_PHONG} element={<DatPhong />} />
                            <Route path={RoutePath.CSLT.DAT_PHONG + "/:id"} element={<DatPhong />} />
                            <Route path={RoutePath.CSLT.TRA_PHONG + "/:id"} element={<TraPhong />} />
                            <Route path={RoutePath.CSLT.CHUYEN_PHONG + "/:id"} element={<ChuyenPhong />} />
                            <Route path={RoutePath.CSLT.DANH_SACH_DAT_PHONG} element={<DanhSachDatPhong />} />
                            <Route path={RoutePath.CSLT.SO_DO_PHONG} element={<SoDoPhong />} />
                            <Route path={RoutePath.CSLT.MESSAGE_INBOX} element={<MessageInbox />} />
                            <Route path={RoutePath.CSLT.NGUOI_DUNG} element={<NguoiDung />} />
                            <Route path={RoutePath.CSLT.BAO_CAO_DOANH_THU} element={<BaoCaoDoanhThu />} />
                            <Route path={RoutePath.CSLT.THONG_KE_KHACH_LUU_TRU} element={<ThongKeKhachLuuTru />} />
                            <Route path={RoutePath.CSLT.DANH_SACH_HOA_DON} element={<DanhSachHoaDon />} />
                            <Route path={RoutePath.CSLT.HOA_DON + "/:id"} element={<HoaDon />} />
                            <Route path={RoutePath.CSLT.DANH_SACH_DOI_TUONG_NGUY_HIEM} element={<DanhSachDoiTuongNguyHiem />} />
                            <Route path={RoutePath.CSLT.DANH_SACH_DANG_KY_LUU_TRU} element={<DanhSachDangKyLuuTru />} />
                            <Route path={RoutePath.CSLT.DANG_KY_LUU_TRU + "/:id"} element={<DangKyLuuTru />} />

                            <Route path={RoutePath.CA.TONG_QUAN} element={<TongQuan />} />
                            <Route path={RoutePath.CA.KHACH_LUU_TRU} element={<KhachLuuTru />} />
                            <Route path={RoutePath.CA.DANH_SACH_CO_SO_LUU_TRU} element={<DanhSachCoSoLuuTru />} />
                            <Route path={RoutePath.CA.CO_SO_LUU_TRU + "/:id"} element={<CoSoLuuTru />} />
                            <Route path={RoutePath.CA.DOI_TUONG_NGUY_HIEM} element={<DoiTuongNguyHiem />} />
                            <Route path={RoutePath.CA.MESSAGE_OUTBOX} element={<MessageOutbox />} />
                            <Route path={RoutePath.CA.BAO_CAO} element={<BaoCao />} />

                            <Route path={RoutePath.ADMIN.HOME} element={<Home />} />
                        </Route>
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default AppRoutes;